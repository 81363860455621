import { Dialog } from '@Component/Dialog';
import { Group, Label } from '@tlx/atlas';
import { useEffect, useRef, useState } from 'react';
import { REOPEN_TRAVEL_EXPENSE } from '@Page/TravelsAndExpenses/constants';
import './TravelsAndExpenses.css';

type selectedProps = {
    id: number;
    employeeId: number;
};

export type TravelsAndExpensesRejectedCommentProps = {
    open: boolean;
    selected: Array<selectedProps>;
    resetDialogs: () => void;
    updateTravelExpense?: (
        selected: number | number[],
        action: string,
        comment?: string
    ) => void;
    reopenExpense?: (id: number, comment?: string) => void;
};

export function TravelsAndExpensesRejectedCommentDialog(
    props: TravelsAndExpensesRejectedCommentProps
) {
    const [comment, setComment] = useState('');
    const divRef = useRef<HTMLDivElement>(null);

    //focus on the div when first opening the dialog
    useEffect(() => {
        if (props.open) {
            divRef?.current?.focus();
        }
    }, [props.open]);

    function handleClickOk() {
        const rejectComment = comment;
        if (props.reopenExpense) {
            setComment('');
            props.reopenExpense(props.selected[0].id, comment);
        } else if (props.updateTravelExpense) {
            const loginEmployeeExpenses = props.selected.filter(
                (item) => item.employeeId === loginEmployeeId
            );

            const othersExpenses = props.selected.filter(
                (item) => item.employeeId !== loginEmployeeId
            );
            if (othersExpenses.length) {
                setComment('');
                props.updateTravelExpense(
                    othersExpenses.map((item) => item.id),
                    REOPEN_TRAVEL_EXPENSE,
                    rejectComment
                );
            }
            if (loginEmployeeExpenses.length) {
                props.updateTravelExpense(
                    loginEmployeeExpenses.map((item) => item.id),
                    REOPEN_TRAVEL_EXPENSE
                );
            }
        }
        props.resetDialogs();
    }

    return (
        <Dialog
            title={getMessage('text_reject')}
            cancelButton={{
                text: getMessage('button_cancel'),
                onClick: props.resetDialogs,
            }}
            okButton={{
                text: getMessage('button_save'),
                primary: true,
                onClick: () => {
                    handleClickOk();
                },
            }}
            onClose={props.resetDialogs}
            open={props.open}
        >
            <Group>
                <Label htmlFor="rejected-comment">
                    {getMessage('text_add_comment')}
                </Label>
                {/* TRIP-27112 - TextArea and Input was not expanding so this contentEditable on a div was a way around it */}
                <div
                    ref={divRef}
                    id="rejected-comment"
                    data-testid="rejected-comment"
                    onInput={(e) => {
                        setComment(e.currentTarget.textContent ?? '');
                    }}
                    className="travels-and-expenses-reject-comment-dialog"
                    contentEditable
                ></div>
            </Group>
        </Dialog>
    );
}
