import { useEffect, useState } from 'react';
export function useDebouncedQuery(
    query: string,
    debounceDelay: number
): string {
    const [debounced, setDebounced] = useState(query);

    useEffect(() => {
        const timeout = setTimeout(() => setDebounced(query), debounceDelay);
        return () => clearTimeout(timeout);
    }, [query, debounceDelay]);

    return debounced;
}
