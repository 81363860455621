import { useCallback, useEffect, useState } from 'react';

import {
    CompanyVerificationType,
    SignatureStatus,
    Signer,
    SignerCombination,
} from '@Page/CompanyVerificationFlow/Types';

import { ResponseWrapper } from '../../hooks/fetch/types';
import useSWR, { Key } from 'swr';
import { defaultFetcher } from '../../hooks/fetch/defaultFetcher';
import {
    APIError,
    createAPIRequest,
    createAPIResponse,
    HTTPError,
    ListResponse,
} from '@tlx/astro-shared';

export function useCompanyVerificationStatus() {
    const url = new URL(
        `/v2/signatureCombination/companyVerificationStatus`,
        window.location.origin
    );

    const { data, mutate, error } = useSWR<
        ResponseWrapper<CompanyVerificationType>
    >(url.href, defaultFetcher);

    const mutateCompanyVerificationStatus = useCallback(
        () => mutate(),
        [mutate]
    );

    return {
        companyVerificationStatus: data?.value,
        mutateCompanyVerificationStatus,
        error: error as unknown,
    };
}

export function useCompanyOrgNumber() {
    const url = new URL(`/v2/company/${contextId}`, window.location.origin);
    url.searchParams.set('fields', 'organizationNumber');

    const { data, mutate, error } = useSWR<
        ResponseWrapper<{ organizationNumber: string }>
    >(url.href, defaultFetcher);

    // const mutateCompanyOrgNumber = useCallback(() => mutate(), [mutate]);

    return {
        companyOrgNumber: data?.value.organizationNumber,
        mutateCompanyOrgNumber: mutate,
        error: error as unknown,
    };
}

export function useCurrentUserSignatureStatus() {
    const url = new URL(
        `/v2/signatureCombination/currentUserSignatureStatus`,
        window.location.origin
    );

    const { data, mutate, error } = useSWR<ResponseWrapper<SignatureStatus>>(
        url.href,
        defaultFetcher
    );

    const mutateUserSignatureStatus = useCallback(() => mutate(), [mutate]);

    return {
        userSignatureStatus: data?.value ?? 'NOT_A_SIGNER',
        mutateUserSignatureStatus,
        error: error as unknown,
    };
}

export function useIsCompanyVerificationAdmin() {
    const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined);
    useEffect(() => {
        const url = new URL(
            `/v2/signatureCombination/isCompanyVerificationAdmin`,
            window.location.origin
        );

        fetch(
            createAPIRequest(url.toString(), {
                method: 'GET',
            })
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 500) {
                    setIsAdmin(false);
                } else {
                    setIsAdmin(data.value);
                }
            })
            .catch((error) => {
                console.error(error);
                setIsAdmin(false);
            });
    }, []);

    return isAdmin;
}

// Need this because our request is a put request due to having to save the signature combinations when fetching the first time
async function fetcherForSignerCombination<T = unknown>(
    url: string
): Promise<T> {
    const request = createAPIRequest(url, { method: 'PUT' });
    const response = await window.fetch(request);

    return createAPIResponse(request, response);
}

export function useFetchSignerCombinations() {
    const params = new URLSearchParams();
    params.set('fields', '*, companyRepresentativeDTOList(*)');

    const { data, error, isLoading } = useSWR<
        ListResponse<SignerCombination>,
        APIError | HTTPError
    >(
        `/v2/signatureCombination/getSignatures?${params.toString()}`,
        fetcherForSignerCombination
    );

    return {
        signerCombinations: data?.values,
        isLoading,
        error,
    };
}

export async function requestSignaturesViaEmail(
    signers: Signer[],
    thisIsMeSigner: Signer | undefined
) {
    const url = new URL(
        `/v2/companyRepresentative/requestSignatureViaEmail`,
        window.location.origin
    );

    if (thisIsMeSigner) {
        url.searchParams.set('thisIsMeSignerId', String(thisIsMeSigner.id));
    }

    const payload = (
        thisIsMeSigner ? [...signers, thisIsMeSigner] : signers
    ).map((signer) => {
        return {
            id: signer.id,
            name: signer.name,
            email: signer.email,
            description: signer.description,
            roleInCompany: signer.roleInCompany,
        };
    });

    const request = createAPIRequest(url.href, {
        headers: {
            'content-type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payload),
    });
    const response = await fetch(request);

    await createAPIResponse<void>(request, response);
}

export function useRequestEmailReminder(signerId: number) {
    const [error, setError] = useState<string>('');
    const [isExecuting, setIsExecuting] = useState(false);
    const [isReminderSent, setIsReminderSent] = useState(false);

    const triggerEmailReminder = useCallback(async () => {
        setIsExecuting(true);
        setError('');
        try {
            const result = await requestEmailReminder(signerId);
            setIsReminderSent(result);
        } catch (error) {
            if (error instanceof APIError) {
                const errorMessage = (
                    error as APIError
                ).details.validationMessages
                    ?.map((validationMessage) => validationMessage.message)
                    .join(', ');
                setError(errorMessage ?? '');
            }
        } finally {
            setIsExecuting(false);
        }
    }, [signerId]);

    return {
        requestEmailReminder: triggerEmailReminder,
        error,
        isExecuting,
        isReminderSent,
    };
}

export async function requestEmailReminder(signerId: number) {
    const request = createAPIRequest(
        `/v2/companyRepresentative/sendReminderEmail?signerId=${signerId}`,
        {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
        }
    );

    const response = await fetch(request);
    const data = await createAPIResponse<ResponseWrapper<boolean>>(
        request,
        response
    );

    return data.value;
}
