import * as React from 'react';
import classNames from 'classnames';

import './SimpleDialog.scss';
import { createPortal } from 'react-dom';

export interface SimpleDialogProps {
    children?: React.ReactNode;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    onClickOutside?: React.MouseEventHandler<HTMLDivElement>;
    open?: boolean;
    className?: string;
}

export class SimpleDialog extends React.PureComponent<SimpleDialogProps> {
    body: HTMLBodyElement | null;

    constructor(props: SimpleDialogProps) {
        super(props);
        this.body = document.querySelector<HTMLBodyElement>('body');
    }

    render() {
        if (this.body === null) {
            return null;
        }

        const { children, header, footer, className, open, onClickOutside } =
            this.props;

        const headerContent =
            header !== undefined ? (
                <div className="txr-dialog__container__header" key="header">
                    {header}
                </div>
            ) : null;

        const body = (
            <div className="txr-dialog__container__body" key="body">
                {children}
            </div>
        );

        const footerContent =
            footer !== undefined ? (
                <div className="txr-dialog__container__footer" key="footer">
                    {footer}
                </div>
            ) : null;

        const dialog = (
            <div
                className={classNames('txr-dialog', className, {
                    'txr-dialog--open': open,
                    'txr-dialog--closed': !open,
                })}
            >
                <div className="txr-dialog__container">
                    {headerContent}
                    {body}
                    {footerContent}
                </div>
                <div
                    className={classNames('txr-dialog__backdrop', {
                        'txr-dialog__backdrop--open': open,
                        'txr-dialog__backdrop--closed': !open,
                    })}
                    onClick={onClickOutside}
                />
            </div>
        );

        return createPortal(dialog, this.body);
    }
}
