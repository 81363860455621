import * as React from 'react';

import { Button } from '@Component/Button';

import { SimpleDialog, SimpleDialogProps } from '../SimpleDialog/SimpleDialog';

export type DialogButtonRef = {
    text: string;
    primary?: boolean;
    disabled?: boolean;
    onClick?: React.MouseEventHandler;
    testId?: string;
};

type BaseButtonMap<B> = { [key: string]: B };
type ButtonActionMap = BaseButtonMap<DialogButtonRef | undefined>;
type ButtonMap = BaseButtonMap<DialogButtonRef>;

export type DialogProps = Omit<
    SimpleDialogProps,
    'header' | 'footer' | 'onClickOutside'
> & {
    title: string;
    okButton?: DialogButtonRef;
    cancelButton?: DialogButtonRef;
    otherButtons?: ButtonMap;
    onClose?: React.MouseEventHandler;
};

export class Dialog extends React.PureComponent<DialogProps> {
    buttonActionMap: ButtonActionMap;

    constructor(props: DialogProps) {
        super(props);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);

        this.buttonActionMap = {
            ...props.otherButtons,
            ok: props.okButton,
            cancel: props.cancelButton,
        };
    }

    componentDidUpdate(
        prevProps: Readonly<DialogProps>,
        prevState: Readonly<{}>,
        snapshot?: any
    ): void {
        if (
            prevProps.okButton !== this.props.okButton ||
            prevProps.cancelButton !== this.props.cancelButton ||
            prevProps.otherButtons !== this.props.otherButtons
        ) {
            this.buttonActionMap = {
                ...this.props.otherButtons,
                ok: this.props.okButton,
                cancel: this.props.cancelButton,
            };
        }
    }

    handleClickOutside(event: React.MouseEvent) {
        this.handleButtonClick(event, 'cancel');
    }

    handleButtonClick(event: React.MouseEvent, actionKey?: string) {
        if (
            actionKey === undefined &&
            (event.target as HTMLButtonElement).name
        ) {
            actionKey = (event.target as HTMLButtonElement).name;
        }

        if (actionKey === undefined) {
            return;
        }

        const button: DialogButtonRef | undefined =
            this.buttonActionMap[actionKey];

        if (button && button.onClick && !button.disabled) {
            button.onClick(event);
        }

        if (!(button && button.onClick) && this.props.onClose) {
            this.props.onClose(event);
        }

        //Since we remove the dialog from the DOM abrutly, tooltips are not cleaned up. This hack fixes that.
        setTimeout(
            () =>
                document
                    .querySelectorAll('.tmdl-tooltip')
                    .forEach((node) => node.remove()),
            0
        );
    }

    render() {
        const {
            title,
            okButton,
            cancelButton,
            otherButtons,
            onClose,

            children,
            ...rest
        } = this.props;

        const header = title;

        const footer: React.ReactNode[] = [];

        if (otherButtons !== undefined) {
            Object.keys(otherButtons).forEach((key) =>
                footer.push(
                    <Button
                        key={key}
                        name={`${key}`}
                        onClick={this.handleButtonClick}
                        disabled={otherButtons[key].disabled}
                        raised={otherButtons[key].primary}
                        className="txr-dialog__container__footer__button"
                    >
                        {otherButtons[key].text}
                    </Button>
                )
            );
        }

        if (cancelButton) {
            footer.push(
                <Button
                    key="cancel"
                    name="cancel"
                    onClick={this.handleButtonClick}
                    disabled={cancelButton.disabled}
                    raised={cancelButton.primary}
                    className="txr-dialog__container__footer__button"
                    testId={cancelButton.testId}
                >
                    {cancelButton.text}
                </Button>
            );
        }

        if (okButton) {
            footer.push(
                <Button
                    key="ok"
                    name="ok"
                    onClick={this.handleButtonClick}
                    disabled={okButton.disabled}
                    raised={okButton.primary}
                    className="txr-dialog__container__footer__button"
                    testId={okButton.testId}
                >
                    {okButton.text}
                </Button>
            );
        }

        return (
            <SimpleDialog
                header={header}
                footer={footer}
                onClickOutside={this.handleClickOutside}
                {...rest}
            >
                {children}
            </SimpleDialog>
        );
    }
}
