import {
    getApiFlattenData,
    getApiHeaders,
    getApiParsedResponse,
    getApiURL,
    getApiURLSearchParams,
    getHasMoreApiPages,
} from '@Page/Goldshark/API/function';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { ListResponse } from '@tlx/astro-shared';
import { useMemo } from 'react';

export type SWRConfigType = {
    [name: string]: string | number | boolean;
};
export const FETCH_PAGINATED_COUNT = 50;
export const MethodType = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE',
} as const;
export const invokeAPI = async (
    url: string,
    methodType: 'GET' | 'POST' | 'PUT' | 'DELETE',
    payload?: string | FormData,
    isPaginated = false,
    additionalHeaders: Record<string, string> = {
        'Content-Type': 'application/json; charset=utf-8',
    }
) => {
    const options = {
        method: methodType,
        ...(payload && { body: payload }),
        headers: getApiHeaders(additionalHeaders),
    };
    const response = await fetch(url, options);
    if (!response.ok) {
        throw await getApiParsedResponse(response);
    }
    const data = await getApiParsedResponse(response);
    if (isPaginated) {
        return data;
    }
    return {
        data:
            typeof data === 'object'
                ? Array.isArray(data.values)
                    ? data.values
                    : data.value
                : data,
    };
};

export const useInvokeAPI = <T>(
    url: string | null,
    queryParams?: Record<string, string | number | boolean | undefined | null>,
    swrConfig?: SWRConfigType
) => {
    return useSWR<T | undefined>(
        getApiURL(url, getApiURLSearchParams(queryParams)),
        async (url: string) => {
            const { data, error } = await invokeAPI(url, MethodType.GET);
            if (data === undefined) {
                throw error;
            }
            return data;
        },
        swrConfig
    );
};

export function useInvokeAPIPaginated<T>(
    url: string | null,
    queryParams?: Record<string, string | number | boolean | undefined | null>,
    swrConfig: SWRConfigType = {
        revalidateOnFocus: false,
        revalidateAll: false,
        revalidateFirstPage: false,
        shouldRetryOnError: false,
    }
) {
    const buildUrl = getApiURL(url, getApiURLSearchParams(queryParams));
    const getKey = (pageIndex: number, previousPageData: T[]) => {
        if (previousPageData && !previousPageData.values.length) {
            return null;
        }
        if (buildUrl === null) {
            return null;
        }
        return `${buildUrl}&count=${FETCH_PAGINATED_COUNT}&from=${
            pageIndex * FETCH_PAGINATED_COUNT
        }`;
    };
    const response = useSWRInfinite<ListResponse<T>>(
        getKey,
        async (url: string) => {
            return await invokeAPI(url, MethodType.GET, undefined, true);
        },
        swrConfig
    );
    const data = useMemo(
        () => getApiFlattenData(response.data),
        [response.data]
    );
    const hasMore = getHasMoreApiPages(response.data);
    function loadMore() {
        if (hasMore && !response.isValidating) {
            response.setSize(response.size + 1);
        }
    }
    return {
        data,
        error: response.error,
        isLoading: response.isLoading,
        hasMore,
        loadMore,
        refresh: response.mutate,
    };
}
