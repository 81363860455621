import {
    Button,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Group,
    Input,
    Label,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalIllustration,
    ModalLayout,
    ModalTitle,
    ToggleGroup,
} from '@tlx/atlas';
import '../style.css';
import { ApiUrl, PageUrl } from '@Page/LogisticsV2/utils/enums';
import { AsyncDropdownOptions } from '@tlx/astro-shared';
import { getSvgIcon } from '@Page/LogisticsV2/utils/svgIcons';
import { Form } from '@Page/LogisticsV2/components/Form/Form';
import { doSubmitCreateStocktaking } from '@Page/LogisticsV2/pages/Stocktaking/create/action';
import { getCurrentDate } from '@Page/LogisticsV2/utils/functions';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import React, { useState } from 'react';
import { ModalLoadingSpinner } from '@Page/Goldshark/Components/LayoutComponents';

export const CreateStocktakingModal = ({
    inventoryId,
    onClose,
}: {
    inventoryId: number;
    onClose: () => void;
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleOnSubmit = async (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        if (isSubmitting) {
            return;
        }
        setIsLoading(true); // Show spinner
        setIsSubmitting(true);
        try {
            const { data } = await doSubmitCreateStocktaking(
                formElement,
                values,
                inventoryId
            );
            setIsSubmitting(false);
            window.location.href = addContextId(
                `${PageUrl.STOCKTAKING_DETAILS}?inventoryId=${data.id}`
            );
        } catch (error) {
            onClose();
            postValidationMessages(
                error?.validationMessages[0]?.message ??
                    getMessage('validation_cannot_create_stocktaking')
            );
            setIsSubmitting(false);
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    return (
        <Modal
            open={true}
            onClose={onClose}
            className={isLoading ? 'modal-loading' : ''}
        >
            <ModalLayout>
                <ModalCloseButton />
                <ModalIllustration>
                    {getSvgIcon('stocktakingCounted')}
                </ModalIllustration>
                <ModalTitle className="atl-my-24">
                    {getMessage('text_inventory_create')}
                </ModalTitle>
                <ModalContent>
                    <Form
                        formId="create-new-stocktaking"
                        onSubmit={handleOnSubmit}
                        className="atl-p-0"
                    >
                        <div
                            className="atl-flex atl-flex-col atl-gap-16"
                            style={{ width: '430px' }}
                        >
                            <div className="atl-flex atl-gap-16">
                                {moduleWarehouse && (
                                    <Group className="atl-flex-1">
                                        <Label htmlFor="new-stocktaking-warehouse-dropdown">
                                            {getMessage('text_warehouse')}
                                        </Label>
                                        <Dropdown
                                            data-testid="stocktaking-create-warehouse-dropdown"
                                            defaultValue={inventoryId}
                                            id="new-stocktaking-warehouse-dropdown"
                                            name="inventoryId"
                                        >
                                            <DropdownOpener className="atl-w-full" />
                                            <DropdownDrawer>
                                                <DropdownSearch />
                                                <DropdownScrollContainer>
                                                    <AsyncDropdownOptions
                                                        url={`${ApiUrl.INVENTORY}?isInactive=false`}
                                                    />
                                                </DropdownScrollContainer>
                                            </DropdownDrawer>
                                        </Dropdown>
                                    </Group>
                                )}
                                <Group className="atl-flex-1">
                                    <Label htmlFor="new-stocktaking-date-input">
                                        {getMessage('text_date')}
                                    </Label>
                                    <Input
                                        type="date"
                                        data-testid="stocktaking-create-date-input"
                                        className="atl-w-full"
                                        defaultValue={getCurrentDate()}
                                        name="date"
                                        id="new-stocktaking-date-input"
                                    />
                                </Group>
                            </div>
                            <Group>
                                <Label htmlFor="new-stocktaking-comment-input">
                                    {getMessage('text_comment')}
                                </Label>
                                <Input
                                    type="text"
                                    data-testid="stocktaking-create-comment-input"
                                    className="atl-w-full"
                                    name="comment"
                                    id="new-stocktaking-comment-input"
                                />
                            </Group>
                            <ToggleGroup className="atl-mt-16">
                                <Input
                                    data-testid="new-stocktaking-include-zero-amounts"
                                    type="toggle"
                                    name="typeOfStocktaking"
                                    id="new-stocktaking-include-zero-amounts"
                                />
                                <Label htmlFor="new-stocktaking-include-zero-amounts">
                                    {getMessage(
                                        'text_include_zero_amount_products'
                                    )}
                                </Label>
                            </ToggleGroup>
                        </div>
                    </Form>
                </ModalContent>
                <ModalFooter>
                    <StocktakingCreateActionButtons onClose={onClose} />
                </ModalFooter>
            </ModalLayout>
            {isLoading && <ModalLoadingSpinner />}
        </Modal>
    );
};

export const StocktakingCreateActionButtons = ({
    onClose,
}: {
    onClose: () => void;
}) => {
    return (
        <div className="atl-flex atl-mt-16 atl-gap-8">
            <Button
                data-testid="new-stocktaking-button-confirm"
                type="submit"
                form="create-new-stocktaking"
            >
                {getMessage('button_create')}
            </Button>
            <Button
                data-testid="new-stocktaking-button-cancel"
                variant="secondary"
                onClick={onClose}
            >
                {getMessage('button_cancel')}
            </Button>
        </div>
    );
};
