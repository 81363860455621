// All the legacy
import '../../legacy-import';

import '@General/style/index.scss';

/**
 * For the side effects.
 */
import './General/HistoryShim';

/** Expose chart.js to the jsp */
import './Components/Charts';

import { store } from '@General/createStore';
import { runGlobalInitializers } from '@General/saga';
import { tlxInitJsonrpc } from '../../js/c-common';

tlxInitJsonrpc();
runGlobalInitializers(store);

export { store } from '@General/createStore';
export { legacyAdapter } from '@General/LegacyAdapter';
export { formChanged } from '@General/formChanged';
export { commandCenter } from '@General/Command/GlobalCommand';
export * from '@General/Router';
export { runTour, fetchAndRunTour } from '../../js/modules/tourme';
export { renderVatReturnsInternalXmlFetcher } from './Pages/VatReturnsInternalXmlFetcher';
export {
    renderTravelsAndExpenses,
    renderTravelsAndExpensesFAB,
} from './Pages/TravelsAndExpenses';
export { renderBankReconciliation } from './Pages/BankReconciliation';
export { renderExpense } from './Pages/Expense';
export { renderIncomingInvoiceOrderLines } from './Pages/IncomingInvoice';
export { renderProjectDashboard } from './Pages/ProjectDashboard';
export { renderSecureAuthorization } from './Pages/SecureAuthorization';
export { renderCompanyVerificationFlow } from '@Page/CompanyVerificationFlow/renderCompanyVerificationFlow';
export { renderBetaProgramOverview } from './Pages/BetaProgram';
export { renderEntitlementConfirmation } from './Pages/EntitlementConfirmation';
export { renderSecureUserIdentification } from './Pages/SecureUserIdentification';
export { renderProductUnits } from './Pages/ProductUnits';
export { renderPurchaseOrder } from './Pages/Logistics/PurchaseOrder';
export { renderProductPage } from '@Page/LogisticsV2/pages/Product/Product.route';
export { renderProductDiscountModal } from '@Page/LogisticsV2/pages/Product discount/modals/ProductDiscountModal.route';
export { renderCopyDiscountModal } from '@Page/LogisticsV2/pages/Product discount/modals/CopyDiscountModal.route';
export { renderProductOverviewPage } from '@Page/LogisticsV2/pages/Product/overview/ProductOverview.route';
export { renderProductGroupsPage } from '@Page/LogisticsV2/pages/Settings/ProductGroups/ProductGroups.route';
export { renderProductSettingsPage } from '@Page/LogisticsV2/pages/Settings/ProductSettings/ProductSettings.route';
export { renderTransportIntegrationPage } from '@Page/Logistics/TransportIntegration/TransportIntegration.route';
export { renderMarketingConsent } from './Pages/MarketingConsent';
export { renderJSPDropdown } from './Components/DropDown/JSPDropdown/JSPDropdown';
export { renderProjectWizard } from './Pages/ProjectWizard';
export { renderProjectWizardModal } from './Pages/ProjectWizard/ProjectWizardModal.route';
export { renderNoProjectsLandingPage } from './Pages/NoProjectsLandingPage';
export { renderAccountantVideo } from './Pages/AccountantVideo';
export { renderProjectSettings } from './Pages/ProjectSettings';
export { renderProjectImport } from './Pages/ProjectImport';
export { renderUserOnboardWizard } from './Pages/UserOnboardWizard';
export { renderPaymentWidget } from './Pages/PaymentWidget';
export { renderIncomingStockModal } from './Pages/LogisticsV2/components/Modals/StockMovements/IncomingStockModal.route';
export { renderOutgoingStockModal } from './Pages/LogisticsV2/components/Modals/StockMovements/OutgoingStockModal.route';
export { renderInvoicingFormModal } from './Pages/InvoicingDialog/renderInvoicingModal';
export { renderProjectInvoicingFormModal } from './Pages/ProjectInvoicingDialog/renderInvoicingModal';
export { renderInvoicingModeSwitcher } from './Pages/InvoicingModeSwitcher/renderInvoicingModeSwitcher';
export { renderOrderTopSection } from './Pages/OrderTopSection/renderOrderTopSection';
export { renderProjectWageModal } from './Pages/ProjectWageModal/ProjectWageModal.route';
export { renderSupportReadAccessRequestDialog } from '@Page/SupportAccess/legacy';
export { renderZtlPaymentsLookupTable } from './General/PBC/SupportTools/ZtlPaymentsLookupTable/ZtlPaymentsLookupTable.route';
export * from './Pages/Error';
export { renderExportMenu } from './Pages/ExportMenu';
export * from '@Page/Logistics/WarehouseDetails';
export { renderCreateProduct } from '@Page/LogisticsV2/components/Modals/NewSupplierProductModal/NewSupplierProductModal.route';

export { renderSupplierProducts } from './Pages/Logistics';
export { renderPurchaseOrderOverview } from '@Page/LogisticsV2/pages/PurchaseOrder/overview/PurchaseOrderOverview.route';
export { renderDiscountPolicyOverview } from '@Page/LogisticsV2/pages/Product discount/overview/ProductDiscount.route';

export { renderProjectOnboarding } from './Pages/ProjectOnboardingOld/ProjectOnboarding.route';

export { renderProjectApprovedInvoiceExistsWarning } from './Pages/AtlasJSPAlerts/ProjectApprovedInvoiceExistsWarning/ProjectApprovedInvoiceExistsWarning.route';
export { renderProjectHourlyRateZeroWarning } from '@Page/AtlasJSPAlerts/ProjectHourlyRateZeroWarning/ProjectHourlyRateZeroWarning.route';
export { renderProjectEmployeesWithMissingHourlyCostsExistWarning } from './Pages/AtlasJSPAlerts/ProjectEmployeesWithMissingHourlyCostsExistWarning/ProjectEmployeesWithMissingHourlyCostsExistWarning.route';
export { renderFeedbackButton } from './Components/Sheetstorm/UserFeedback/UserFeedback';
export { renderPageNotFound } from './Pages/Error/PageNotFound/PageNotFound.page';
export { renderDynamicControlFormOldVersionChip } from '@Page/AtlasJSPAlerts/DynamicControlFormOldVersionChip/DynamicControlFormOldVersionChip.route';
export { renderDynamicControlFormOldFormsAlert } from '@Page/DynamicControlFormOverview/component/OldFormsAlert/OldFormsAlert.route';
export { renderDynamicControlFormOldFormsButton } from '@Page/DynamicControlFormOverview/component/OldFormsButton/OldFormsButton.route';
export { renderDynamicControlFormOverview } from '@Page/DynamicControlFormOverview/DynamicControlFormOverviewPage.route';
export { renderGetStartedBanner } from './Pages/Beehive/component/Banner';
export { renderUserLicensePage } from '@Page/UserLicense/UserLicense.route';
export { renderOptInSalaryBanner } from '@Page/Salary5/Components/SalaryOverview/Banner/renderOptInSalaryBanner';
export { renderSalaryOverviewGetStartedBanner } from '@Page/Salary5/Components/SalaryOverview/Banner/GetStartedBannerSalaryOverview';
export { renderKillSwitchPage } from '@Page/Beehive/KillSwitch';
export { renderRejectCommentDialog } from '@Page/TravelsAndExpenses/component/renderRejectCommentDialog';

// @ts-expect-error we don't explicitly type this as existing on window
window.setESMModulesAreLoaded();
