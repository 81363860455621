import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { renderComponent } from '@General/renderComponent';

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./SignedOrCompletedControlFormsExistAlert').then(
                (m) => m.page
            )
        }
        props={props}
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/signedOrCompletedControlFormsExistAlert',
    team: Team.SHEET_STORM,

    auth: () => true,
};

export function renderSignedOrCompletedControlFormsExistAlert(domId: string) {
    renderComponent(Page, domId);
}
