import * as React from 'react';

import { ScopeElement } from '@General/Router/Router.type';
import { isFilled } from '@General/Helpers';

export interface DynamicTitleProps {
    readonly scope: ScopeElement;
}

interface DynamicTitleState {
    readonly title: string;
    readonly showTitle: boolean;
}

/**
 * DynamicTitle
 */
export class DynamicTitle extends React.Component<
    DynamicTitleProps,
    DynamicTitleState
> {
    private readonly observer: MutationObserver;

    constructor(props: DynamicTitleProps) {
        super(props);

        this.state = {
            title: props.scope.dataset['tlxTitle'] ?? '',
            showTitle:
                (props.scope.dataset['tlxShowTitle'] ?? 'true') !== 'false',
        };

        this.mutationCallback = this.mutationCallback.bind(this);
        this.observer = new MutationObserver(this.mutationCallback);

        this.setTabTitle(this.state.title);
    }

    componentDidMount() {
        this.observer.observe(this.props.scope, {
            attributeFilter: ['data-tlx-title', 'data-tlx-show-title'],
        });
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    mutationCallback(mutations: MutationRecord[]) {
        const scope = mutations[0].target as ScopeElement;
        const title = scope.dataset['tlxTitle'] ?? '';
        const showTitle = (scope.dataset['tlxShowTitle'] ?? 'true') !== 'false';
        this.setState({ title, showTitle });
        this.setTabTitle(title);
    }

    setTabTitle(title: string) {
        app.setTitle(
            `${window.getCompanyName()} - ${title} - ${
                window.tripletexSystemName
            }`
        );
    }

    render() {
        if (!isFilled(this.state.title, '') || !this.state.showTitle) {
            return null;
        }

        return <h1 className="clip">{this.state.title}</h1>;
    }
}
