import { useState } from 'react';
import { CopyDiscountModal } from '@Page/LogisticsV2/pages/Product discount/modals/CopyDiscountsToCustomerModal';

export function CopyDiscountModalPage() {
    const [showModal, setShowModal] = useState(false);
    return (
        <form
            id="copyDiscountToCustomersForm"
            onSubmit={() => setShowModal(true)}
        >
            {showModal && (
                <CopyDiscountModal onClose={() => setShowModal(false)} />
            )}
        </form>
    );
}
