import { PageData, PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import * as React from 'react';
import { StocktakingOverview } from './StocktakingOverview';

const loader: React.FC = () => (
    <PageLoader<{}> loader={async () => page} adjustWrapperDiv />
);

export const route: RouteData = {
    component: loader,
    path: PageUrl.STOCKTAKING,
    team: Team.GOLDSHARK,
    documentationComponent: 363,
    auth: () =>
        hasProductAuthAdminRole &&
        (moduleLogistics || moduleLogisticsLight) &&
        !moduleRackbeat,
};

const PAGE_ID = 'StocktakingOverview';
const page: PageData<any> = {
    page: StocktakingOverview,
    modules: [
        {
            id: PAGE_ID,
        },
    ],
};
