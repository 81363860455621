import '../PaymentWidget.scss';
import { Scenario } from '@Page/PaymentWidget/types/paymentWidgetTypes';
import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalLayout,
    ModalTitle,
} from '@tlx/atlas';
import { AdvancedPaymentWidgetTemplate } from '@Page/PaymentWidget/component/AdvancedPaymentWidgetTemplate';
import React, { useState } from 'react';
import { UseAdvancedPaymentWidget } from '@Page/PaymentWidget/hooks/useAdvancedPaymentWidget';

export type AdvancedPaymentWidgetProps = UseAdvancedPaymentWidget & {
    atlasDesignSystem: boolean;
    label: string;
};

export const AdvancedPaymentWidget: React.FC<AdvancedPaymentWidgetProps> = ({
    atlasDesignSystem,
    paymentWidgetData,
    setPaymentWidgetData,
    registerPayment,
    label,
    scenario,
    validations,
    setValidations,
    oppositeAmount,
    setOppositeAmount,
    creditNotesDetails,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(true);

    const closeDialog = () => {
        setIsDialogOpen(false);
        window.setTimeout(() => {
            document.dispatchEvent(new Event('paymentWidgetClose'));
        }, 0);
    };

    return (
        <div>
            {scenario === Scenario.INCOMING_INVOICE ? (
                <section>
                    <AdvancedPaymentWidgetTemplate
                        paymentWidgetData={paymentWidgetData}
                        setPaymentWidgetData={setPaymentWidgetData}
                        atlasDesignSystem={atlasDesignSystem}
                        scenario={scenario}
                        closeDialog={closeDialog}
                        registerPayment={async () =>
                            await registerPayment().then(closeDialog)
                        }
                        oppositeAmount={oppositeAmount}
                        setOppositeAmount={setOppositeAmount}
                        creditNotesDetails={creditNotesDetails}
                    />
                </section>
            ) : (
                <Modal
                    open={isDialogOpen}
                    onClose={closeDialog}
                    className="pbc-payment__section-modal-advanced"
                >
                    <ModalLayout>
                        <ModalTitle className="atl-ml-4">
                            {getMessage(label)}
                        </ModalTitle>
                        <ModalCloseButton />
                        <ModalContent>
                            <AdvancedPaymentWidgetTemplate
                                paymentWidgetData={paymentWidgetData}
                                setPaymentWidgetData={setPaymentWidgetData}
                                atlasDesignSystem={atlasDesignSystem}
                                scenario={scenario}
                                closeDialog={closeDialog}
                                registerPayment={async () =>
                                    await registerPayment().then(closeDialog)
                                }
                                validations={validations}
                                setValidations={setValidations}
                                oppositeAmount={oppositeAmount}
                                setOppositeAmount={setOppositeAmount}
                                creditNotesDetails={creditNotesDetails}
                            />
                        </ModalContent>
                    </ModalLayout>
                </Modal>
            )}
        </div>
    );
};
