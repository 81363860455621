import React, { ReactNode, useState } from 'react';
import { DeviceType } from '@Page/LogisticsV2/utils/constants';
import { Button, DownloadIcon, RefreshIcon } from '@tlx/atlas';
import {
    doCreateLines,
    doExport,
} from '@Page/LogisticsV2/pages/Stocktaking/details2/StocktakingDetailsActions';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import { ProductLineStatus } from '@Page/LogisticsV2/pages/Stocktaking/utils';
import {
    AutoSaveStatusType,
    MethodType,
    SortingType,
} from '@Page/LogisticsV2/utils/enums';
import {
    ResaleProductDTO,
    StocktakingDTO,
} from '@Page/LogisticsV2/types/dtoTypes';
import { mapProductsToProductLines } from '@Page/LogisticsV2/utils/mappers';
import { getValidationMessagesFromResponse } from '@Page/LogisticsV2/utils/functions';
import { useStocktakingExport } from '@Page/LogisticsV2/pages/Stocktaking/details2/useStocktakingExport';
import { ProductSelectorModal } from '@Page/Goldshark/Components/Modal/ProductSelectorModal/ProductSelectorModal';
import { invokeAPI } from '@Page/Goldshark/API/api';
import { ApiURL } from '@Page/Goldshark/API/url';

export function StocktakingDetailsDesktopLayout({
    children,
}: {
    children: ReactNode;
}) {
    return <div>{children}</div>;
}

export function StocktakingDetailsMobileLayout({
    children,
}: {
    children: ReactNode;
}) {
    return <div className="atl-mx-8">{children}</div>;
}

export function StocktakingDetailsTableLayout({
    children,
}: {
    children: ReactNode;
}) {
    return <div className="atl-flex atl-flex-col atl-gap-8">{children}</div>;
}

export function OptionalComponent({
    children,
    id,
    deviceType,
}: {
    children: ReactNode;
    id: string;
    deviceType: DeviceType;
}) {
    return id === deviceType ? children : null;
}

export function StocktakingDetailsExportButton({
    stocktaking,
    columns,
}: {
    stocktaking: StocktakingDTO;
    columns: string[];
}) {
    const [isExporting, setIsExporting] = useState(false);

    const [statusFilter] = useSessionStorage(
        `stocktakingDetailsProductLineStatus-${window.loginEmployeeId}`,
        ProductLineStatus.ALL_PRODUCTS
    );
    const [sorting] = useSessionStorage(
        `stocktakingDetailsSortingBy-${window.loginEmployeeId}`,
        SortingType.NAME_ASCENDING
    );
    useStocktakingExport(
        stocktaking,
        statusFilter,
        sorting,
        columns,
        setIsExporting
    );
    const handleExport = async () => {
        await doExport(
            stocktaking,
            statusFilter,
            sorting,
            columns,
            setIsExporting
        );
    };

    return (
        <div className={'atl-flex atl-items-center'}>
            {isExporting ? (
                <Button
                    data-testid={'stocktaking-loading-export-button'}
                    variant="icon"
                    aria-label="stocktaking-loading-export-button"
                >
                    <RefreshIcon
                        className={
                            'goldshark-stocktaking-details__export-loading-icon'
                        }
                    />
                </Button>
            ) : (
                <Button
                    data-testid={'stocktaking-export-button'}
                    variant="icon"
                    title={getMessage('text_export')}
                    className={'atl-ml-4'}
                    onClick={handleExport}
                    aria-label="stocktaking-export-button"
                >
                    <DownloadIcon className="atl-items goldshark-stocktaking-details-export" />
                </Button>
            )}
        </div>
    );
}

export function StocktakingDetailsAddProductButton({
    stocktaking,
    modalProps,
    onClick,
    onChange,
}: {
    stocktaking: StocktakingDTO;
    modalProps: {
        open: boolean;
        onClose: () => void;
    };
    onClick: React.MouseEventHandler<HTMLElement>;
    onChange: (status: AutoSaveStatusType) => void;
}) {
    const handleProductSelectorConfirm = async (
        products: Record<string, string>
    ) => {
        const fields =
            'id,name,number,elNumber,nrfNumber,ean,costExcludingVatCurrency,stockOfGoods';
        const productIds = Object.keys(products).map((id) => {
            return { id: id };
        });
        const { data } = await invokeAPI(
            `${ApiURL.PRODUCT_GET_BY_ID}?fields=${fields}`,
            MethodType.POST,
            JSON.stringify(productIds)
        );
        data.map((product: ResaleProductDTO) => {
            if (products[product.id]) {
                product.count = products[product.id];
            }
        });
        await handleOnConfirm(data as ResaleProductDTO[]);
    };
    async function handleOnConfirm(selectedProducts: ResaleProductDTO[]) {
        onChange(AutoSaveStatusType.PENDING);
        const newProductLines = mapProductsToProductLines(
            selectedProducts,
            stocktaking
        );
        const { error } = await doCreateLines(newProductLines);
        if (error) {
            onChange(AutoSaveStatusType.ERROR);
            getValidationMessagesFromResponse(
                error,
                getMessage('validation_cannot_add_new_productLines')
            );
        } else {
            onChange(AutoSaveStatusType.SAVED);
        }
    }

    return (
        <>
            <Button
                data-testid="add-product"
                variant="secondary"
                onClick={onClick}
            >
                {getMessage('text_add_product')}
            </Button>
            <ProductSelectorModal
                modalProps={modalProps}
                isDirect={true}
                hideCount={true}
                onConfirm={handleProductSelectorConfirm}
            />
        </>
    );
}
