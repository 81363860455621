import {
    Button,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalIllustration,
    ModalLayout,
    ModalTitle,
} from '@tlx/atlas';
import React, { useEffect, useState } from 'react';
import { AsyncDropdownOptions } from '@tlx/astro-shared';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { doSubmit } from '@Page/LogisticsV2/pages/PurchaseOrder/create/action';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import { LogisticsSettingsDTO } from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { ApiURL } from '@Page/Goldshark/API/url';
import { invokeAPI, useFetchSingle } from '@Page/LogisticsV2/hooks/api';

interface Props {
    onClose: () => void;
}

export function CreatePurchaseOrderModal({ onClose }: Props) {
    const [supplierId, setSupplierId] = useState<string>('');
    const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

    useEffect(() => {
        setIsButtonDisabled(!supplierId);
    }, [supplierId]);

    const { data: logisticsSettings } = useFetchSingle<LogisticsSettingsDTO>(
        ApiURL.LOGISTICS_SETTINGS
    );

    const handleOnSubmit = async () => {
        const response = await invokeAPI(
            `/v2/supplier?id=${supplierId}&fields=currency(*)`,
            'GET'
        );
        const responseJson = await response.data?.json();
        const supplier = responseJson?.values;
        try {
            const { data } = await doSubmit(
                supplierId,
                supplier[0].currency?.id ?? 1,
                logisticsSettings?.purchaseOrderDefaultComment || ''
            );
            if (data?.id) {
                window.location.href = addContextId(
                    `${window.location.origin}/page/logistics/purchase-orders/${data.id}`
                );
            }
        } catch (error) {
            postValidationMessages(error?.validationMessages[0]?.message);
        }
    };

    const handleSupplierChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setSupplierId(event.target.value);
    };

    return (
        <Modal open={true} onClose={onClose}>
            <ModalLayout>
                <ModalCloseButton />
                <ModalIllustration>
                    <PurchaseOrderIcon />
                </ModalIllustration>
                <ModalTitle>{getMessage('text_new_purchase_order')}</ModalTitle>
                <ModalContent>
                    <div className={'atl-flex atl-flex-col atl-gap-8'}>
                        <div>{getMessage('text_supplier')}*</div>
                        <Dropdown
                            data-testid="supplier-dropdown"
                            defaultDisplayName={getMessage('option_not_chosen')}
                            onChange={handleSupplierChange}
                        >
                            <DropdownOpener className="atl-w-full" />
                            <DropdownDrawer>
                                <DropdownSearch />
                                <DropdownScrollContainer>
                                    <AsyncDropdownOptions
                                        url={`${ApiUrl.SUPPLIER_QUERY}?sorting=displayName`}
                                    />
                                </DropdownScrollContainer>
                            </DropdownDrawer>
                        </Dropdown>
                    </div>
                </ModalContent>
                <ModalFooter>
                    <div className={'atl-flex atl-gap-8'}>
                        <Button
                            data-testid="save-purchase-order-button"
                            onClick={handleOnSubmit}
                            disabled={isButtonDisabled}
                            aria-label="Save Purchase Order"
                        >
                            {getMessage('button_save')}
                        </Button>
                        <Button
                            data-testid="cancel-purchase-order-button"
                            variant="secondary"
                            onClick={onClose}
                            aria-label="Cancel Purchase Order"
                        >
                            {getMessage('button_cancel')}
                        </Button>
                    </div>
                </ModalFooter>
            </ModalLayout>
        </Modal>
    );
}

function PurchaseOrderIcon() {
    return (
        <svg
            width={100}
            height={100}
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_661_6270)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.655 19.11A2 2 0 0012 21v57.6a2 2 0 002 2h72a2 2 0 002-2V38.018 21a2 2 0 00-2-2H53.273c-.112 0-.221.01-.328.027a2.017 2.017 0 00-.327-.027H15.31c-.23 0-.45.039-.654.11zm38.29 3.863a2.017 2.017 0 01-.327.027H41v20.873h17.6V23h-5.328c-.11 0-.22-.01-.327-.027zM39.327 47.846A2 2 0 0137 45.873V23H16v53.6h68V38.055 23H62.6v22.873a2 2 0 01-2 2H39.655c-.112 0-.221-.01-.328-.027z"
                    fill="#0A41FA"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.786 60.548L1.73 52.876l5.524-5.824-7.73-6.548L7.593 32l16.786 14.221-13.592 14.327z"
                    fill="#FCE6AF"
                />
                <path
                    d="M98.468 37.259C92.61 29.384 82.552 26.956 76 31.836l10.605 14.26 11.863-8.837z"
                    fill="#5FDAC4"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M35.545 66.474l16.743 14.032-22.955 7.004 6.212-21.036z"
                    fill="#F6AED5"
                />
            </g>
            <defs>
                <clipPath id="clip0_661_6270">
                    <path fill="#fff" d="M0 0H100V100H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
