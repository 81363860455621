import {
    Button,
    Group,
    Input,
    Label,
    ModalContent,
    ModalFooter,
    ModalSubtitle,
    ModalTitle,
} from '@tlx/atlas';
import { useEffect, useState } from 'react';
import { CompanyVerificationSteps } from '@Page/CompanyVerificationFlow/CompanyVerificationFlow';
import { useCompanyOrgNumber } from '@Page/CompanyVerificationFlow/companyVerificationAPI';
import { validateOrganizationNumber } from '@Page/InvoicingDialog/ValidationUtil';

import { ResponseWrapper } from '../../hooks/fetch/types';
import { tlxForms } from '../../../../js/modules/forms';
import {
    APIError,
    createAPIRequest,
    createAPIResponse,
} from '@tlx/astro-shared';

async function saveCompanyOrgNumber(orgNumber: string) {
    const url = new URL(`/v2/company`, window.location.origin);
    url.searchParams.set('fields', 'organizationNumber,version');

    const request = createAPIRequest(url.href, {
        method: 'PUT',
        body: JSON.stringify({ organizationNumber: orgNumber }),
        headers: {
            'content-type': 'application/json',
        },
    });
    const response = await window.fetch(request);
    const data = await createAPIResponse<
        ResponseWrapper<{ organizationNumber: string; version: number }>
    >(request, response);

    return data?.value;
}

function updateJSPOrgNumber(response: {
    organizationNumber: string;
    version: number;
}) {
    tlxForms.change($(':prop("company.number")'), response.organizationNumber);
    tlxForms.change($(':prop("company.revision")'), response.version);
}

export function MissingOrgNumber({
    handleNextStep,
}: {
    handleNextStep: (value: CompanyVerificationSteps) => void;
}) {
    const [orgNumber, setOrgNumber] = useState<string>('');
    const { mutateCompanyOrgNumber } = useCompanyOrgNumber();
    const [validation, setValidation] = useState<string | undefined>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    async function handleSubmit() {
        if (validation || orgNumber === '') {
            return;
        }
        setIsSubmitting(true);
        try {
            await mutateCompanyOrgNumber(() => ({
                value: { organizationNumber: orgNumber },
            }));
            const response = await saveCompanyOrgNumber(orgNumber);
            if (response) {
                updateJSPOrgNumber(response);
                handleNextStep('SEND_SIGNING_REQUESTS');
            }
        } catch (error) {
            await mutateCompanyOrgNumber(() => ({
                value: { organizationNumber: '' },
            }));
            if (error instanceof APIError) {
                const err = error as APIError;
                setValidation(
                    err.details.validationMessages
                        ?.map((m) => m.message)
                        .join(', ')
                );
            }
        } finally {
            setIsSubmitting(false);
            mutateCompanyOrgNumber();
        }
    }

    return (
        <>
            <ModalTitle>
                {getMessage('validation_org_number_missing')}
            </ModalTitle>
            <ModalSubtitle>
                {getMessage('text_kyc_need_orgnumber')}
            </ModalSubtitle>
            <ModalContent>
                <Group>
                    <Label>{getMessage('text_org_number')}</Label>
                    <Input
                        value={orgNumber}
                        onClick={() => setValidation(undefined)}
                        onChange={(e) => setOrgNumber(e.target.value)}
                        type={'text'}
                        data-testid={'input-add-missing-org-number'}
                        onBlur={() =>
                            setValidation(validateOrganizationNumber(orgNumber))
                        }
                    />
                    <div className={'atl-mt-8 atl-text-red-100'}>
                        {validation}
                    </div>
                </Group>
            </ModalContent>
            <ModalFooter>
                <Button
                    data-testid={'next-step-button'}
                    onClick={handleSubmit}
                    disabled={
                        orgNumber === '' ||
                        validation !== undefined ||
                        isSubmitting
                    }
                >
                    {getMessage('text_next')}
                </Button>
            </ModalFooter>
        </>
    );
}
