import { ModalContent, ModalTitle } from '@tlx/atlas';
import { SecureAuthorization } from '@Page/SecureAuthorization/component/SecureAuthorization';
import React from 'react';
import { ResponseWrapper } from '../../../hooks/fetch/types';
import { ConnectedSecureCompanyRepresentativeIdentification } from '@Page/CompanyVerificationFlow/VerifyWithBankId/SecureCompanyRepresentativeIdentification';
import { createAPIRequest, createAPIResponse } from '@tlx/astro-shared';

const AUTH_LEVEL_BANKID = 4;

export function CompanyVerificationBankId({
    closeModal,
}: {
    closeModal: () => void;
}) {
    const isAuthorizedWithBankId =
        window.authorizationLevel === AUTH_LEVEL_BANKID;
    if (isAuthorizedWithBankId) {
        return (
            <ConnectedSecureCompanyRepresentativeIdentification
                closeModal={closeModal}
            />
        );
    } else {
        return (
            <>
                <ModalTitle>{getMessage('text_choose_auth')}</ModalTitle>
                <ModalContent>
                    <SecureAuthorization
                        onSelectMethod={handleSecurityMethod}
                    />
                    {/*<SecureCompanyVerificationAuthorization*/}
                    {/*    secureAuthUrl={handleSecurityMethod}*/}
                    {/*/>*/}
                </ModalContent>
            </>
        );
    }
}

const handleSecurityMethod = async (authMethod: string) => {
    const url = new URL(
        `/v2/signatureCombination/vismaConnectAuthUrl?authorizationMethod=${authMethod}`,
        window.location.origin
    );

    const request = createAPIRequest(url.href, {
        method: 'GET',
    });
    const response = await window.fetch(request);
    const redirectUrl = await createAPIResponse<ResponseWrapper<string>>(
        request,
        response
    );

    if (redirectUrl?.value) {
        return redirectUrl.value;
    }

    return;
};
