import { EntitlementLookup } from '@General/constants';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';

import { SupportDashboardPage } from './SupportDashboard.page';

export const route: RouteData = {
    component: SupportDashboardPage,
    title: () => getMessage('text_support_dashboard'),
    path: '/execute/support/home',
    team: Team.BEEHIVE,
    auth: (context) =>
        context.entitlementMap[
            (EntitlementLookup.AUTH_TRIPLETEX_SUPPORT_INVOICE_MANAGER,
            EntitlementLookup.AUTH_TRIPLETEX_SUPPORT)
        ] !== undefined,
};
