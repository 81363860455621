import { invokeAPI, MethodType } from '@Page/Goldshark/API/api';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';

export function doSubmit(
    supplierId: string,
    supplierCurrency: number,
    defaultComment: string
) {
    return invokeAPI(
        ApiUrl.PURCHASE_ORDER,
        MethodType.POST,
        JSON.stringify({
            supplier: {
                id: supplierId,
            },
            deliveryDate: new Date().toISOString(),
            ourContact: {
                id: window.loginEmployeeId,
            },
            comments: defaultComment,
            currency: { id: supplierCurrency },
        })
    );
}
