import * as React from 'react';
import { PageData, PageLoader } from '@Component/PageLoader';
import { renderComponent } from '@General/renderComponent';
import { LogisticsSettingsDataModule } from '@Page/Logistics/Module';
import { CopyDiscountModalPage } from '@Page/LogisticsV2/pages/Product discount/modals/CopyDiscountsToCustomersPage';

const loader: React.FC = () => (
    <PageLoader<{}> loader={async () => page} adjustWrapperDiv />
);

export function renderCopyDiscountModal(domId: string) {
    renderComponent(loader, domId);
}

export const page: PageData = {
    page: CopyDiscountModalPage,
    modules: [
        LogisticsSettingsDataModule,
        {
            id: 'CopyDiscountModalPage',
        },
    ],
};
