import * as React from 'react';
import { PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { Team } from '@General/Team';
import { EntitlementLookup } from '@General/constants';

const Page: React.FC = (props) => (
    <PageLoader<{}>
        loader={async () =>
            import('./ProjectOnboardingPage').then((m) => m.page)
        }
        adjustWrapperDiv={true}
        props={props}
    />
);

export const route: RouteData = {
    component: Page,
    path: '/execute/projectOnboarding',
    team: Team.SHEET_STORM,
    auth: (context) =>
        moduleProject &&
        context.entitlementMap[EntitlementLookup.ROLE_ADMINISTRATOR] !==
            undefined,
};
