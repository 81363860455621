import React, {
    CSSProperties,
    FormEvent,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import { getFormValues } from '@Page/Goldshark/Utils/function';
import {
    Button,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Group,
    Input,
    Label,
    MoneyInput,
    Option,
    Spinner,
    ToggleGroup,
} from '@tlx/atlas';
import { getCurrentLocale } from '../../../../../js/modules/getMessage';
import { AsyncDropdownOptions } from '@tlx/astro-shared';
import { useExclusiveOption } from '@Page/Goldshark/Hooks/useExclusiveOption';
import './style.css';
export function DivLayout({
    children,
    className,
    style,
    tabIndex,
    onClick,
}: {
    children?: ReactNode;
    className?: string;
    style?: CSSProperties;
    tabIndex?: number;
    onClick?: () => void;
}) {
    return (
        <div
            onClick={onClick}
            className={className ?? ''}
            style={style}
            tabIndex={tabIndex}
        >
            {children}
        </div>
    );
}
export function FormLayout({
    children,
    id,
    className,
    style,
    onSubmit,
}: {
    children: ReactNode;
    id?: string;
    className?: string;
    style?: CSSProperties;
    onSubmit?: (values: Record<string, FormDataEntryValue>) => void;
}) {
    const formRef = useRef<HTMLFormElement | null>(null);
    const handleFormSubmit = async (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();
        getFormValues(formRef, async (values) => {
            onSubmit?.(values);
        });
    };

    return (
        <form
            onSubmit={handleFormSubmit}
            className={className ?? ''}
            style={style}
            autoComplete={'off'}
            ref={formRef}
            id={id ?? ''}
        >
            {children}
        </form>
    );
}
export function SubmitButton({ formId }: { formId: string }) {
    return (
        <Button
            variant="primary"
            data-testid={`${formId}-create-button`}
            type={'submit'}
            form={formId}
            tabIndex={9999}
        >
            {getMessage('button_create')}
        </Button>
    );
}
export function ConfirmButton({
    dataTestId,
    isDisabled,
    onClick,
}: {
    dataTestId: string;
    isDisabled?: boolean;
    onClick: () => void;
}) {
    return (
        <Button
            variant="primary"
            data-testid={dataTestId}
            disabled={isDisabled}
            onClick={onClick}
            tabIndex={9999}
        >
            {getMessage('text_confirm')}
        </Button>
    );
}
export function CancelButton({
    dataTestId,
    onClick,
}: {
    dataTestId: string;
    onClick: () => void;
}) {
    return (
        <Button
            variant="secondary"
            data-testid={dataTestId}
            onClick={onClick}
            tabIndex={9999}
        >
            {getMessage('button_cancel')}
        </Button>
    );
}

type FormInputProps = {
    name: string;
    label: string;
    className?: string;
    defaultValue?: string | number | boolean;
    required?: boolean;
    error?: string;
    onChange?: (event: string) => void;
};

type DropdownInputProps = {
    url: string;
    defaultDisplayName?: string;
    customOption?: { displayName: string; value: string };
} & FormInputProps;
export function FormTextInput(props: FormInputProps) {
    return (
        <Group className={props.className}>
            <Label htmlFor={`form-input-${props.name}`}>
                {props.label}
                {props.required ? '*' : ''}
            </Label>
            <Input
                id={`form-input-${props.name}`}
                type="text"
                data-testid={`form-input-${props.name}`}
                className={'atl-w-full'}
                name={props.name}
                defaultValue={props.defaultValue as string}
                required={props.required}
            />
            <InputError message={props.error} />
        </Group>
    );
}
export function FormNumberInput(props: FormInputProps) {
    const locale = getCurrentLocale();
    const [value, setValue] = useState(props.defaultValue as string);
    useEffect(() => {
        setValue(props.defaultValue as string);
    }, [props.defaultValue]);

    return (
        <Group className={props.className}>
            <Label htmlFor={`form-input-${props.name}`}>{props.label}</Label>
            <MoneyInput
                className={'atl-w-full'}
                id={`form-input-${props.name}`}
                data-testid={`form-input-${props.name}`}
                onChange={(event) => {
                    setValue(event);
                    props.onChange?.(event);
                }}
                currency="NOK"
                locale={locale}
                name={props.name}
                value={props.defaultValue as string}
                formatOptions={{
                    style: 'decimal',
                    maximumFractionDigits: 2,
                }}
            />
            <InputError message={props.error} />
            <input type={'hidden'} name={props.name} value={value} />
        </Group>
    );
}
export function FormDropdownInput(props: DropdownInputProps) {
    return (
        <Group className={props.className}>
            <Label htmlFor={`form-dropdown-input-${props.name}`}>
                {props.label}
                {props.required ? '*' : ''}
            </Label>
            <Dropdown
                data-testid={`form-dropdown-input-${props.name}`}
                defaultValue={props.defaultValue as string}
                defaultDisplayName={props.defaultDisplayName}
                id={`form-dropdown-input-${props.name}`}
                name={props.name}
                onChange={(event) => props.onChange?.(event.target.value)}
            >
                <DropdownOpener className={'atl-w-full'} />
                <DropdownDrawer>
                    <DropdownSearch />
                    <DropdownScrollContainer>
                        <AsyncDropdownOptions url={props.url}>
                            {props.customOption ? (
                                <CustomExclusiveDropdownOption
                                    displayName={props.customOption.displayName}
                                    value={props.customOption.value}
                                />
                            ) : null}
                        </AsyncDropdownOptions>
                    </DropdownScrollContainer>
                </DropdownDrawer>
            </Dropdown>
            <InputError message={props.error} />
        </Group>
    );
}
export function FormToggleInput(props: FormInputProps) {
    return (
        <ToggleGroup className={props.className}>
            <Input
                id={`form-toggle-input-${props.name}`}
                type="toggle"
                data-testid={`form-toggle-input-${props.name}`}
                defaultChecked={props.defaultValue as boolean}
                name={props.name}
                onChange={(event) =>
                    props.onChange?.(event.target.checked ? 'true' : 'false')
                }
            />
            <Label htmlFor={`form-toggle-input-${props.name}`}>
                {props.label}
                {props.required ? '*' : ''}
            </Label>
        </ToggleGroup>
    );
}
export function InputError({ message }: { message?: string }) {
    return (
        <DivLayout className="atl-text-red-100 atl-flex atl-flex-wrap">
            {message}
        </DivLayout>
    );
}

export function CustomExclusiveDropdownOption({
    displayName,
    value,
}: {
    displayName: string;
    value: string;
}) {
    useExclusiveOption(value);
    return (
        <Option
            value={value}
            data-testid={`dropdown-custom-exclusive-${displayName}`}
        >
            {displayName}
        </Option>
    );
}

type ModalLoadingSpinnerProps = {
    text?: string;
    left?: string;
    top?: string;
};
export function ModalLoadingSpinner(props: ModalLoadingSpinnerProps) {
    return (
        <>
            <DivLayout
                className="modal-spinner-container atl-flex"
                style={{ left: props.left ?? '20%', top: props.top ?? '40%' }}
            >
                <Spinner size={'medium'} />
                <DivLayout className="atl-text-sm atl-text-grey-100 atl-ml-8 atl-flex atl-flex-col">
                    <DivLayout>
                        {props.text ?? getMessage('text_loading2')}
                    </DivLayout>
                    <DivLayout>{getMessage('text_loading_info')}</DivLayout>
                </DivLayout>
            </DivLayout>
            <DivLayout className="modal-overlay" />
        </>
    );
}
