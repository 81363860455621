import {
    useListSearchParam,
    useSearchParam,
    useTableColumnsSetting,
} from '@tlx/astro-shared';
import { useDebouncedQuery } from '@Page/Goldshark/Hooks/useDebouncedQuery';
import { TableSort } from '@tlx/atlas';
import { useCallback, useEffect, useState } from 'react';
import { useInvokeAPI } from '@Page/Goldshark/API/api';
import { tableColumns } from '@Page/Goldshark/Utils/mapData';
import { forEach } from 'lodash';
export function useTableFilters() {
    const query = useSearchParam('query');
    const debouncedQuery = useDebouncedQuery(query ?? '', 600);
    const productGroups = useListSearchParam('productGroups');
    return { debouncedQuery, productGroups };
}

export function useTableColumns(tableName: string) {
    const table = tableColumns[tableName];
    return useTableColumnsSetting(table.name, table.default);
}
export function useTableSelectionWithCount() {
    const [selectedRowsWithCount, setSelectedRowsWithCount] = useState<
        Record<string, string>
    >({});
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const [sort, setSort] = useState<TableSort>();

    const onClearSelection = useCallback(() => {
        setSelectedRows([]);
        setSelectedRowsWithCount({});
    }, []);

    const onChangeRowCount = useCallback(
        (value: string, rowId: string) => {
            setSelectedRowsWithCount((currentCounts) => {
                const newCounts = { ...currentCounts };
                if (!selectedRows?.includes(rowId) && value.length > 0) {
                    const updatedSelectedRows = [
                        ...(selectedRows ?? []),
                        rowId,
                    ];
                    setSelectedRows(updatedSelectedRows);
                    newCounts[rowId] = value;
                } else if (
                    selectedRows?.includes(rowId) &&
                    value.length === 0
                ) {
                    const updatedSelectedRows = selectedRows.filter(
                        (id) => id !== rowId
                    );
                    setSelectedRows(updatedSelectedRows);
                    delete newCounts[rowId];
                } else if (selectedRows?.includes(rowId) && value.length > 0) {
                    newCounts[rowId] = value;
                }
                return newCounts;
            });
        },
        [selectedRows, setSelectedRowsWithCount]
    );

    const getCount = useCallback(
        (rowId: string) => {
            const defaultCount = selectedRowsWithCount[rowId];
            return selectedRows?.includes(rowId) ? defaultCount || '1' : '';
        },
        [selectedRowsWithCount, selectedRows]
    );

    useEffect(() => {
        setSelectedRowsWithCount((currentCounts) => {
            const updatedCounts = { ...currentCounts };
            Object.keys(updatedCounts).forEach((rowId) => {
                if (!selectedRows?.includes(rowId.toString())) {
                    delete updatedCounts[rowId];
                }
            });
            forEach(selectedRows, (rowId) => {
                if (!updatedCounts[rowId]) {
                    updatedCounts[rowId] = '1';
                }
            });
            return updatedCounts;
        });
    }, [selectedRows]);

    return {
        selectedRows,
        setSelectedRows,
        sort,
        setSort,
        getCount,
        selectedRowsWithCount,
        onClearSelection,
        onChangeRowCount,
    };
}
export function useTotalLines<T>(url: string | null, queryParams: {}) {
    const { data, error, mutate } = useInvokeAPI<T[]>(url, {
        ...queryParams,
        fields: 'id',
        count: 10000,
    });
    return {
        data: data ?? [],
        error,
        mutate,
    };
}
