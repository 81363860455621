import { SendSigningRequestForm } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SendSigningRequestForm';
import React, { useEffect, useState } from 'react';
import { SendSigningRequestError } from '@Page/CompanyVerificationFlow/SendSigningRequestError';
import {
    requestSignaturesViaEmail,
    useCurrentUserSignatureStatus,
    useFetchSignerCombinations,
} from '@Page/CompanyVerificationFlow/companyVerificationAPI';
import { SignatureStatus, Signer } from '@Page/CompanyVerificationFlow/Types';
import { CompanyVerificationSteps } from '@Page/CompanyVerificationFlow/CompanyVerificationFlow';
import { SendSigningRequestFormSkeleton } from '@Page/CompanyVerificationFlow/SendSigningRequestForm/SendSigningRequestFormSkeleton';

export function ConnectedSendSigningRequestForm({
    handleNextStep,
    userSignatureStatus,
}: {
    handleNextStep: (value: CompanyVerificationSteps) => void;
    userSignatureStatus: SignatureStatus;
}) {
    const { mutateUserSignatureStatus } = useCurrentUserSignatureStatus();

    // re-fetches userSignatureStatus so if they are thisIsMe the submit button will still work after going back.
    useEffect(() => {
        mutateUserSignatureStatus();
    }, [mutateUserSignatureStatus]);

    const { signerCombinations, error, isLoading } =
        useFetchSignerCombinations();

    if (error !== undefined) {
        return <SendSigningRequestError error={error} />;
    }

    if (isLoading || signerCombinations === undefined) {
        return <SendSigningRequestFormSkeleton />;
    }

    return (
        <SendSigningRequestForm
            userSignatureStatus={userSignatureStatus}
            signerCombinations={signerCombinations}
            handleSignatureRequest={handleSignatureRequest}
            handleNextStep={handleNextStep}
        />
    );
}

async function handleSignatureRequest(
    signers: Signer[],
    thisIsMeSigner: Signer | undefined
) {
    await requestSignaturesViaEmail(signers, thisIsMeSigner);
}
