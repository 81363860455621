import React, { useState } from 'react';
import { RackbeatNewsRow } from '@Page/LogisticsV2/pages/rackbeatproductnews/components/RackbeatNewsRow';
import { Button, ContentSwitch, ContentSwitcher } from '@tlx/atlas';
import { useRackbeatProductNews } from '@Page/LogisticsV2/pages/rackbeatproductnews/useRackbeatProduct';
import { nav } from '../../../../../../../js/modules/navigation';
import {
    Banner,
    BannerButtons,
    BannerDescription,
    BannerTitle,
    InfoLabel,
    TipsLabel,
} from '@tlx/beehive-shared';

export const RackbeatSections = () => {
    const rackbeatNews = useRackbeatProductNews(true);
    const rackbeatUnderDev = useRackbeatProductNews(false);
    const [isReleased, setIsReleased] = useState(true);
    // const [idx, setIdx] = useState('released');
    const [idx, setIdx] = useState(0);

    return (
        <div className="atl-flex atl-justify-between atl-my-32 atl-py-8 atl-gap-24 rackbeat-news-page-sections ">
            <div className="atl-bg-white atl-p-32 atl-w-full rackbeat-product-news-section atl-border atl-border-grey-20 atl-rounded atl-flex-wrap">
                <div className="atl-flex atl-justify-between atl-flex-wrap atl-gap-16">
                    <div className="atl-font-bold atl-text-lg">
                        {getMessage('text_product_news')}
                    </div>

                    <ContentSwitcher data-testid="controlled-content-switch">
                        <ContentSwitch
                            data-testid="option-0"
                            checked={0 === idx}
                            onChange={() => {
                                setIdx(0);
                                setIsReleased(true);
                            }}
                        >
                            {getMessage('product_news_tab_launched')}
                        </ContentSwitch>
                        <ContentSwitch
                            data-testid="option-1"
                            checked={1 === idx}
                            onChange={() => {
                                setIdx(1);
                                setIsReleased(false);
                            }}
                        >
                            {getMessage('product_news_tab_development')}
                        </ContentSwitch>
                    </ContentSwitcher>
                </div>

                {(isReleased ? rackbeatNews.data : rackbeatUnderDev.data).map(
                    (value, index) => (
                        <RackbeatNewsRow key={index} newsObject={value} />
                    )
                )}
            </div>

            <div className="atl-bg-white atl-p-32 atl-w-full rackbeat-product-news-section atl-border atl-border-grey-20 atl-rounded">
                <div className={'atl-font-bold '}>
                    {getMessage('text_features_locked_in_tripletex')}
                </div>
                <div className={'atl-font-normal atl-my-24'}>
                    {getMessage('text_rackbeat_news_page_recommendation')}
                </div>
                <div className={'atl-font-bold atl-my-24'}>
                    {getMessage('text_rackbeat_instruction_use')}
                </div>

                <ul>
                    <li> {getMessage('text_warehouse')}</li>
                    <li> {getMessage('text_warehouse_locations')}</li>
                    <li> {getMessage('text_products')}</li>
                    <li> {getMessage('text_rackbeat_use_purchase_order')}</li>
                    <li> {getMessage('text_goods_receipt')}</li>
                    <li> {getMessage('text_rackbeat_use_order_creation')}</li>
                </ul>
            </div>
            <div className="atl-flex atl-flex-col atl-gap-24">
                <RackbeatInfoBanner
                    icon={<InfoLabel />}
                    title={getMessage(
                        'text_agreement_number',
                        rackbeatNews.agreementNumber
                    )}
                    description={getMessage(
                        'text_agreement_number_description'
                    )}
                />
                <RackbeatInfoBanner
                    icon={<TipsLabel />}
                    title={getMessage('text_rackbeat_guide_header')}
                    description={getMessage(
                        'text_rackbeat_guide_header_description'
                    )}
                >
                    <Button
                        data-testid="show-more-button-rackbeat"
                        onClick={() =>
                            nav.popup(
                                'https://hjelp.tripletex.no/hc/no/articles/18553125563665-Introduksjon-til-Logistikk-Komplett-Rackbeat'
                            )
                        }
                        variant="secondary"
                    >
                        {getMessage('text_read_more')}
                    </Button>
                </RackbeatInfoBanner>
            </div>
        </div>
    );
};

export function RackbeatInfoBanner({
    children,
    icon,
    title,
    description,
}: {
    children?: React.ReactNode;
    icon: React.ReactElement;
    title: string;
    description: string;
}) {
    return (
        <Banner className="atl-p-16" style={{ minWidth: '300px' }}>
            {icon}
            <BannerTitle>{title}</BannerTitle>
            <BannerDescription>{description}</BannerDescription>
            <BannerButtons>{children}</BannerButtons>
        </Banner>
    );
}
