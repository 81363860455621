// Needed so that TypeScript can properly resolve the type of import.meta.
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../../node_modules/vite/types/importMeta.d.ts" />

import * as React from 'react';
import { DeepReadonly } from 'ts-essentials';
import { renderComponent } from '@General/renderComponent';
import { isDefined, toMap } from '@General/Helpers';
import { isObject } from '@General/Merge';
import { store } from '@General/createStore';
import tlxFetch, {
    isValidApiResponse,
    StandardResponse,
} from '../../api/tlxFetch';
import { handlePossibleHashChange } from '../../../../js/modules/ClientHash';
import { AuthorizationError } from '@Component/AuthorizationError';
import {
    RouteAuthorizationContext,
    RouteData,
    RouteProps,
    ScopeElement,
} from './Router.type';
import { DynamicTitle } from './DynamicTitle';

function isRouteData(target: any): target is RouteData {
    return (
        isDefined(target) &&
        isObject<RouteData>(target) &&
        isDefined(target.component) &&
        isDefined(target.path) &&
        isDefined(target.team)
    );
}

/**
 * Processes the raw list of modules down to a map of routes.
 */
function processRouteData(
    modules: Record<string, ObjectIndex>
): Record<string, RouteData> {
    const routes = Object.values(modules)
        .map((module) => module.route)
        .filter(isRouteData);

    return toMap(routes, (route) => route.path);
}

/**
 * Define import and process all imported modules
 *
 * Vite glob import documentation can be found here https://vitejs.dev/guide/features.html#glob-import
 * Glob pattern syntax can be found here https://github.com/mrmlnc/fast-glob#pattern-syntax
 */
const routes: DeepReadonly<Record<string, RouteData>> = processRouteData(
    // Eager so that route definitions gets included directly instead of being loaded async
    import.meta.glob('/react-modules/src/Pages/**/*.route.{ts,tsx}', {
        eager: true,
    })
);

/**
 * Get the context needed to do authorization checks.
 *
 * NOTE: This is client side and must not be relied upon for security or secrecy.
 * This exists solely to prevent users from viewing pages that will lead
 * to SecurityExceptions on the server if visited without the proper authorization.
 *
 * @author tellef
 * @date 2020-07-23
 */
function getAuthContext(): RouteAuthorizationContext {
    const reduxState = store.getState();
    return {
        loginCompanyId: window.loginCompanyId,
        loginEmployeeId: window.loginEmployeeId,
        entitlements: reduxState.general.entitlements,
        entitlementMap: reduxState.general.entitlementLookup,
        betaPrograms: reduxState.betaProgram.programLookup,
        modules: [],
        developer: reduxState.developer.data ?? false,
    };
}

/**
 * Render a route based on a path.
 *
 * @param path        The path to render for.
 * @param containerId ID of the container Element
 * @param $scope      JQuery reference to scope
 * @param rootLevel   Is root level? (aka does it set the title, theme, etc ... ?)
 * @param props       Properties to pass to the rendered page.
 *
 * @author tellef
 * @date 2020-07-20
 */
export function renderRoute(
    path: string,
    containerId: string,
    $scope: JQuery,
    rootLevel: boolean,
    props: RouteProps
) {
    const pathname = getTransformedPathname(
        new URL(path, window.location.origin)
    );
    const route = routes[pathname];

    if (route === undefined) {
        throw new Error(
            `Attempted to render non existing route for path ${pathname}`
        );
    }

    const isAuth = route.auth?.(getAuthContext()) ?? true;
    const Component = isAuth ? route.component : () => <AuthorizationError />;

    if (rootLevel) {
        const scope: ScopeElement = $scope.get(0) ?? document.body;

        const title = isAuth
            ? route.title
            : () => getMessage('text_site_login_insufficient_access');

        scope.dataset['tlxTitle'] = title?.() ?? '';
        scope.dataset['tlxShowTitle'] = `${route.showTitle ?? true}`;
        scope.tlxGetOrientation = () => 'portrait';
        scope.tlxIdPrefix = props.prefix;
        scope.tlxShowTitle = false;

        if (route.supportsPDF !== true) {
            // Most routes will not support PDF exports given how our current system works.
            $scope.data('disablePdfExport', true);
        }

        // Most routes will not support CSV exports given how our current system works
        scope.csvPage = route.supportsCSV === true;

        if (route.supportsCSV !== true) {
            $scope.data('disableCsvExport', true);
        }

        // Most routes will not support XLS exports given how our current system works.
        scope.xlsPage = route.supportsXLS === true;

        if (route.supportsXLS !== true) {
            $scope.data('disableXlsExport', true);
        }

        window.Sentry.setTag('team', route.team);

        const wrapperComponent: React.FC = () => (
            <>
                {route.documentationComponent ? (
                    <input
                        type="hidden"
                        name="documentationComponent"
                        value={route.documentationComponent}
                    />
                ) : null}
                <Component {...props} />
            </>
        );

        const titleComponent: React.FC = () => <DynamicTitle scope={scope} />;

        renderComponent(titleComponent, 'menuHeader');
        renderComponent(wrapperComponent, containerId);

        void fetchAndCheckClientHash();
    } else {
        renderComponent(Component, containerId, props);
    }
}

/**
 * Checks if the given path has routes for it.
 *
 * @param path The path to check.
 *
 * @return True if the given path has routes for it; False otherwise.
 *
 * @author tellef
 * @date 2020-07-20
 */
export function hasRoute(path: string): boolean {
    const url = new URL(path, window.location.origin);
    return routes[getTransformedPathname(url)] !== undefined;
}

function getTransformedPathname(url: URL): string {
    return url.pathname.startsWith('/execute')
        ? url.pathname
        : `/execute${url.pathname}`;
}

// Returns true if the hash has changed (or we couldn't check it) - in that case,
// handlePossibleHashChange will reload the page.
export async function fetchAndCheckClientHash(): Promise<boolean> {
    const { response, err }: StandardResponse<string> = await tlxFetch({
        method: 'GET',
        url: '/v2/web/client/hash',
    });

    if (!err && response && isValidApiResponse(response)) {
        return handlePossibleHashChange(response);
    } else {
        return true;
    }
}
