import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { useInvokeAPIPaginated } from '@Page/Goldshark/API/api';
import { ProductAPIModel } from '@Page/Goldshark/API/interface';
import { ALL_PRODUCT_GROUPS } from '@Page/Goldshark/Utils/constant';
import { useLoadMoreDataRef } from '@Page/Goldshark/API/useLoadMore';
import { useMemo } from 'react';
import {
    useTableColumns,
    useTableFilters,
    useTableSelectionWithCount,
} from '@Page/Goldshark/Hooks/useTableHooks';
import { DisplayOptions } from '@tlx/astro-shared';
import { getProductSelectorModalTableName } from '@Page/Goldshark/Components/Modal/ProductSelectorModal/ProductSelectorModalActions';
import { tableSortBy } from '@Page/Goldshark/Utils/mapData';
import useLocalStorage from '../../../../../util/storage';

export function useProductSelectorModalAPIData(
    supplierId?: number,
    isWholesaler?: boolean,
    organizationNumber?: string,
    isDirect?: boolean,
    sortDirection?: { column: string; direction: string }
) {
    const sortingBy =
        sortDirection !== undefined
            ? tableSortBy[sortDirection?.column](sortDirection?.direction)
            : '';
    const { data, isLoading, hasMore, loadMore, refresh } =
        useInvokeAPIPaginated<ProductAPIModel>(
            getProductSelectorModalURL(supplierId, isWholesaler),
            {
                ...useProductSelectorModalQueryParams(
                    supplierId,
                    organizationNumber,
                    isDirect
                ),
                sorting: sortingBy,
                fields: getProductSelectorModalFields(supplierId, isWholesaler),
            },
            { revalidateOnFocus: true }
        );
    const loadMoreRef = useLoadMoreDataRef<HTMLDivElement>(loadMore);

    return {
        data,
        isLoading,
        hasMore,
        loadMoreRef,
        refresh,
    };
}
export function useProductSelectorModalQueryParams(
    supplierId?: number,
    organizationNumber?: string,
    isDirect?: boolean
) {
    const filters = useTableFilters();
    return useMemo(
        () => ({
            isInactive: 'false',
            vendorId: supplierId,
            query: filters.debouncedQuery,
            productGroupId:
                filters.productGroups.includes(ALL_PRODUCT_GROUPS) &&
                filters.productGroups.length === 1
                    ? null
                    : filters.productGroups.join(','),
            organizationNumber: organizationNumber,
            enabledForWarehouse:
                supplierId === undefined
                    ? isDirect
                        ? 'true'
                        : undefined
                    : undefined,
            isSupplierProduct:
                supplierId === undefined
                    ? isDirect
                        ? 'false'
                        : 'true'
                    : undefined,
        }),
        [
            filters.debouncedQuery,
            filters.productGroups,
            organizationNumber,
            supplierId,
        ]
    );
}
export function useProductSelectorModalTableData(
    supplierId?: number,
    isWholesaler?: boolean,
    hideCount?: boolean
) {
    const table = useTableSelectionWithCount();
    const display = useLocalStorage(
        `astro-shared-settings-supplier-connection-selector-display-${window.loginEmployeeId}`,
        DisplayOptions.DETAILED
    )[0];
    const columns = useTableColumns(
        getProductSelectorModalTableName(
            display,
            supplierId,
            isWholesaler,
            hideCount
        )
    );
    return { ...table, display, columns };
}
export function getProductSelectorModalFields(
    supplierId?: number,
    isWholesaler?: boolean
) {
    return supplierId !== undefined
        ? `id,name,costExcludingVatCurrency,priceExcludingVatCurrency,priceIncludingVatCurrency,productUnit(id,displayName,displayNameShort),currency,${
              isWholesaler
                  ? 'elNumber,nrfNumber'
                  : 'number,cost,resaleProduct(name,number,isStockItem,stockOfGoods)'
          }`
        : 'id,name,number,elNumber,nrfNumber,displayName';
}

export function getProductSelectorModalURL(
    supplierId?: number,
    isWholesaler?: boolean
) {
    return supplierId === undefined
        ? ApiUrl.PRODUCT_QUERY_OVERVIEW
        : isWholesaler
        ? ApiUrl.PRODUCT_EXTERNAL_QUERY
        : ApiUrl.SUPPLIER_CONNECTION;
}
