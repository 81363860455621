import React from 'react';
import '../RackbeatProductNews.css';
import { RackbeatSections } from '@Page/LogisticsV2/pages/rackbeatproductnews/components/RackbeatSections';

export function RackbeatProductNewsPage() {
    return (
        <div className="atl-px-16">
            <div
                data-testid={'purchase-order-title'}
                className="atl-flex atl-justify-between atl-items-center atl-flex-wrap"
            >
                <div className={'atl-font-normal atl-text-2xl atl-mt-0'}>
                    {getMessage('text_rackbeat_dashboard')}
                </div>
            </div>
            <RackbeatSections />
        </div>
    );
}
