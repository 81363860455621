import {
    Alert,
    AlertCloseButton,
    AlertContent,
    CheckboxGroup,
    Group,
    InfoIcon,
    Input,
    Label,
    Textarea,
    Tooltip,
    TooltipButton,
    TooltipContent,
    TooltipTrigger,
} from '@tlx/atlas';
import { useState } from 'react';
import { ProductSettingsModel } from '@Page/LogisticsV2/pages/Settings/utils';
import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';

export function ProductSettingsFunctionality({
    productSettings,
}: {
    productSettings: ProductSettingsModel;
}) {
    const [showInfoModal, setShowInfoModal] = useState(false);

    return (
        <>
            <div className="atl-flex atl-gap-24 atl-my-16 atl-flex-wrap">
                {(moduleLogistics || moduleLogisticsLight) && (
                    <CheckboxGroup className="atl-items-center">
                        <Input
                            type="checkbox"
                            id="settings-multiple-warehouse"
                            data-testid="settings-multiple-warehouse"
                            {...productSettings.register('moduleWarehouse')}
                            onChange={() => {
                                const isChecked =
                                    productSettings.getValues(
                                        'moduleWarehouse'
                                    );
                                if (isChecked) {
                                    productSettings.setValue(
                                        'hasWarehouseLocation',
                                        false
                                    );
                                }
                            }}
                        />
                        <Label
                            htmlFor="settings-multiple-warehouse"
                            data-testid="label-settings-multiple-warehouse"
                        >
                            {getMessage('text_warehouse_create_multiple')}
                        </Label>
                    </CheckboxGroup>
                )}
                {productSettings.hasLogisticsModule && (
                    <div className={'atl-flex atl-gap-4'}>
                        <CheckboxGroup className="atl-items-center">
                            <Input
                                type="checkbox"
                                id="settings-warehouse-location"
                                data-testid="settings-warehouse-location"
                                {...productSettings.register(
                                    'hasWarehouseLocation'
                                )}
                                onChange={() => {
                                    setShowInfoModal(!showInfoModal);
                                    const isChecked = productSettings.getValues(
                                        'hasWarehouseLocation'
                                    );
                                    if (!isChecked) {
                                        productSettings.setValue(
                                            'moduleWarehouse',
                                            true
                                        );
                                    }
                                }}
                                disabled={productSettings.hasOutgoingStocks}
                            />
                            <Label
                                htmlFor="settings-warehouse-location"
                                data-testid="label-settings-warehouse-location"
                            >
                                {getMessage('text_warehouse_location')}
                            </Label>
                        </CheckboxGroup>
                        {productSettings.hasOutgoingStocks && (
                            <Tooltip>
                                <TooltipTrigger>
                                    <TooltipButton
                                        data-testid="tooltip-more-info"
                                        aria-label={getMessage(
                                            'text_product_settings_location_stocktaking'
                                        )}
                                        style={{ padding: '0px' }}
                                    >
                                        <InfoIcon />
                                    </TooltipButton>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {getMessage(
                                        'text_product_settings_location_stocktaking'
                                    )}
                                </TooltipContent>
                            </Tooltip>
                        )}
                    </div>
                )}
                {productSettings.hasLogisticsModule && (
                    <CheckboxGroup className="atl-items-center">
                        <Input
                            type="checkbox"
                            id="settings-onboarding-helper"
                            data-testid="settings-onboarding-helper"
                            {...productSettings.register(
                                'showOnboardingWizard'
                            )}
                        />
                        <Label
                            htmlFor="settings-onboarding-helper"
                            data-testid="label-settings-onboarding-helper"
                        >
                            {getMessage('text_show_logistics_wizard')}
                        </Label>
                    </CheckboxGroup>
                )}
                {showInfoModal && (
                    <LogisticsModal
                        onClose={() => setShowInfoModal(false)}
                        svgIcon="infoIcon"
                        title={getMessage('text_information')}
                        className="goldshark-product-settings-modal"
                    >
                        {productSettings.getValues('hasWarehouseLocation')
                            ? getMessage('text_create_stocktaking_deselect')
                            : getMessage('text_create_stocktaking')}
                    </LogisticsModal>
                )}
                {!productSettings.hasLogisticsModule ? (
                    productSettings.isNewCustomer ? (
                        <NewLogisticsCustomerFunctionality />
                    ) : (
                        <CheckboxGroup>
                            <Input
                                type="checkbox"
                                data-testid="settings-purchase-order"
                                id="settings-purchase-order"
                                {...productSettings.register(
                                    'purchaseOrderModule'
                                )}
                            />
                            <Label
                                htmlFor="settings-purchase-order"
                                data-testid="label-settings-purchase-order"
                            >
                                {getMessage('text_purchase_order')}
                            </Label>
                        </CheckboxGroup>
                    )
                ) : null}
            </div>
            {productSettings.hasLogisticsModule && (
                <div className="atl-flex atl-gap-24 atl-flex-wrap">
                    <div className="atl-flex atl-gap-8 atl-flex-col atl-flex-1">
                        <CheckboxGroup>
                            <Input
                                type="checkbox"
                                data-testid="settings-suggested-product-checkbox"
                                id="settings-suggested-product-checkbox"
                                {...productSettings.register(
                                    'moduleSuggestedProductNumber'
                                )}
                            />
                            <Label
                                htmlFor="settings-suggested-product-checkbox"
                                data-testid="label-settings-suggested-product-checkbox"
                                className="atl-whitespace-nowrap"
                            >
                                {getMessage('text_auto_gen_product_number')}
                            </Label>
                        </CheckboxGroup>
                        <Input
                            type="text"
                            data-testid="settings-suggested-product"
                            {...productSettings.register(
                                'suggestedProductNumber',
                                {
                                    pattern: {
                                        value: /^[0-9]*$/i,
                                        message: `*${getMessage(
                                            'text_only_numbers_allowed'
                                        )}`,
                                    },
                                }
                            )}
                            disabled={
                                !productSettings.watch(
                                    'moduleSuggestedProductNumber'
                                )
                            }
                            className="goldshark-product-settings-max-w"
                        />
                        <div className="atl-text-red-120">
                            {
                                productSettings.errors?.suggestedProductNumber
                                    ?.message
                            }
                        </div>
                    </div>
                    <div className="atl-flex atl-gap-8 atl-flex-col atl-flex-1">
                        <CheckboxGroup>
                            <Input
                                type="checkbox"
                                data-testid="settings-purchase-order-suggested-checkbox"
                                id="settings-purchase-order-suggested-checkbox"
                                {...productSettings.register(
                                    'modulePurchaseOrderNumberSeries'
                                )}
                            />
                            <Label
                                htmlFor="settings-purchase-order-suggested-checkbox"
                                data-testid="label-settings-purchase-order-suggested-checkbox"
                                className="atl-whitespace-nowrap"
                            >
                                {getMessage(
                                    'text_purchase_order_number_series'
                                )}
                            </Label>
                        </CheckboxGroup>
                        <Input
                            type="text"
                            data-testid="settings-purchase-order-suggested"
                            {...productSettings.register(
                                'purchaseOrderNumberSeries',
                                {
                                    pattern: {
                                        value: /^[0-9]*$/i,
                                        message: '*Only numbers allow.',
                                    },
                                }
                            )}
                            className="goldshark-product-settings-max-w"
                            disabled={
                                !productSettings.watch(
                                    'modulePurchaseOrderNumberSeries'
                                )
                            }
                        />
                        <div className="atl-text-red-120">
                            {
                                productSettings.errors
                                    ?.purchaseOrderNumberSeries?.message
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export function ProductSettingsPreferences({
    productSettings,
}: {
    productSettings: ProductSettingsModel;
}) {
    return (
        <div className="atl-flex atl-gap-16 atl-flex-col">
            {!moduleRackbeat && (
                <div className={'atl-flex atl-gap-4 atl-items-center'}>
                    <CheckboxGroup className=" atl-items-center">
                        <Input
                            type="checkbox"
                            data-testid="settings-productSetCostFromOrderLine"
                            {...productSettings.register(
                                'productSetCostFromOrderLine'
                            )}
                            id="settings-productSetCostFromOrderLine"
                        />
                        <Label
                            htmlFor="settings-productSetCostFromOrderLine"
                            data-testid="label-settings-productSetCostFromOrderLine"
                        >
                            {getMessage(
                                'text_refresh_product_cost_from_order_lines'
                            )}
                        </Label>
                    </CheckboxGroup>
                    {productSettings.hasLogisticsModule && (
                        <Tooltip>
                            <TooltipTrigger>
                                <TooltipButton
                                    data-testid="tooltip-more-info"
                                    aria-label={getMessage(
                                        'text_refresh_product_cost_from_order_lines_info'
                                    )}
                                    style={{ padding: '0px' }}
                                >
                                    <InfoIcon />
                                </TooltipButton>
                            </TooltipTrigger>
                            <TooltipContent>
                                {getMessage(
                                    'text_refresh_product_cost_from_order_lines_info'
                                )}
                            </TooltipContent>
                        </Tooltip>
                    )}
                </div>
            )}
            {(productSettings.hasLogisticsModule ||
                productSettings.hasPurchaseOrderModule) && (
                <Group>
                    <Label>{getMessage('text_orderout_comment')}</Label>
                    <Textarea
                        style={{ minHeight: '104px', width: '49%' }}
                        data-testid="settings-purchase-order-default-comment"
                        id="settings-purchase-order-default-comment"
                        {...productSettings.register(
                            'purchaseOrderDefaultComment'
                        )}
                    />
                </Group>
            )}
        </div>
    );
}

const NewLogisticsCustomerFunctionality = () => {
    return (
        <>
            <CheckboxGroup>
                <Input
                    type="checkbox"
                    data-testid="settings-purchase-order-disabled"
                    id="settings-purchase-order-disabled"
                    disabled={true}
                />
                <Label htmlFor="settings-purchase-order-disabled">
                    {getMessage('text_purchase_order')}
                </Label>
            </CheckboxGroup>
            <Alert variant="info" className={'atl-w-full'}>
                <AlertContent>
                    {getMessage('text_features_you_get_with_logistics_info')}{' '}
                    {''}
                    <a
                        data-testid="settings-logistics-subscription-button"
                        href={addContextId('/execute/mySubscription')}
                        target="_blank"
                    >
                        {getMessage(
                            'my_subscription_text_read_more_and_subscribe'
                        )}
                    </a>
                </AlertContent>
                <AlertCloseButton
                    data-testid="close"
                    onClick={() => console.log('Close')}
                />
            </Alert>
        </>
    );
};
