// Actions
export const REQUEST_TRAVEL_EXPENSE = 'REQUEST_TRAVEL_EXPENSE';
export const RECEIVE_TRAVEL_EXPENSE = 'RECEIVE_TRAVEL_EXPENSE';

export const FILTER_UPDATE = 'FILTER_UPDATE';

export const OPEN_CLOSE_FILTER = 'OPEN_CLOSE_FILTER';

export const SUBMIT_TRAVEL_EXPENSE = 'SUBMIT_TRAVEL_EXPENSE';
export const REOPEN_TRAVEL_EXPENSE = 'REOPEN_TRAVEL_EXPENSE';
export const APPROVE_TRAVEL_EXPENSE = 'APPROVE_TRAVEL_EXPENSE';
export const UNAPPROVE_TRAVEL_EXPENSE = 'UNAPPROVE_TRAVEL_EXPENSE';
export const COPY_TRAVEL_EXPENSE = 'COPY_TRAVEL_EXPENSE';
export const DELETE_TRAVEL_EXPENSE = 'DELETE_TRAVEL_EXPENSE';
export const CREATE_VOUCHER = 'CREATE_VOUCHER';

export const UPDATE_LIST = 'UPDATE_LIST';
export const UPDATE_LIST_ELEMENT = 'UPDATE_LIST_ELEMENT';
export const UPDATE_TRAVEL_EXPENSE = 'UPDATE_TRAVEL_EXPENSE';

export const CHECK_ALL = 'CHECK_ALL';
export const UNCHECK_ALL = 'UNCHECK_ALL';
export const CHECK_CHECKBOX = 'CHECK_CHECKBOX';

export const EXPENSE_STATE_OPEN: 'OPEN' = 'OPEN' as const;
export const EXPENSE_STATE_DELIVERED: 'DELIVERED' = 'DELIVERED' as const;
export const EXPENSE_STATE_APPROVED: 'APPROVED' = 'APPROVED' as const;
export const EXPENSE_STATE_SALARY_PAID: 'SALARY_PAID' = 'SALARY_PAID' as const;
export const EXPENSE_STATE_REJECTED: 'REJECTED' = 'REJECTED' as const;
export const ABORT_REQUEST_NO_OP: 'ABORT_REQUEST_NO_OP' =
    'ABORT_REQUEST_NO_OP' as const;

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const RESET_DIALOG_STATE = 'RESET_DIALOG_STATE';

// Constants

export const SHOW_DELETE_DIALOG = 'SHOW_DELETE_DIALOG';
export const SHOW_VOUCHER_DIALOG = 'SHOW_VOUCHER_DIALOG';
export const SHOW_REJECTED_COMMENT_DIALOG = 'SHOW_REJECTED_COMMENT_DIALOG';

export const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
export const DEFAULT_PAGINATION = 5;
export const MAX_LENGTH_COST_TYPE = 20;
export const LAST_TWELVE_MONTHS = -1;
export const ALL_YEARS = 0;
