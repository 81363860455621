import { ApiUrl, MethodType } from '@Page/LogisticsV2/utils/enums';
import { invokeAPI } from '@Page/Goldshark/API/api';

export async function doSubmitCreateStocktaking(
    formElement: HTMLFormElement,
    values: { [k: string]: FormDataEntryValue },
    defaultInventoryId: number
) {
    const payload = {
        date: values.date,
        comment: values.comment,
        inventory: {
            id:
                values.inventoryId === undefined
                    ? defaultInventoryId
                    : values.inventoryId,
        },
        typeOfStocktaking:
            values.typeOfStocktaking === 'on'
                ? 'INCLUDE_PRODUCTS'
                : 'ALL_PRODUCTS_WITH_INVENTORIES',
    };
    return invokeAPI(
        ApiUrl.INVENTORY_STOCKTAKING,
        MethodType.POST,
        JSON.stringify(payload)
    );
}
