import { SmartScanPredictionObject } from '@Page/IncomingInvoice/type/IncomingInvoiceTypes';

/**
 * Types used in the payment widget.
 *
 * @author Melisa Damian
 * @date 11. Oct 2021
 */

export const enum Scenario {
    VAT_RETURNS = 'VAT_RETURNS',
    VAT_RETURNS_2022 = 'VAT_RETURNS_2022',
    PAYROLL_TAX = 'PAYROLL_TAX',
    TAX_DEDUCTION = 'TAX_DEDUCTION',
    FINANCE_TAX = 'FINANCE_TAX',
    MANDATORY_TAX = 'MANDATORY_TAX',
    INCOMING_INVOICE = 'INCOMING_INVOICE',
    INCOMING_INVOICE_DIALOG = 'INCOMING_INVOICE_DIALOG',
    INVOICE_REIMBURSEMENT_DIALOG = 'INVOICE_REIMBURSEMENT_DIALOG',
}

export const enum PaymentTypeEnum {
    AUTOPAY = 'AUTOPAY',
    POSTING_RULE = 'POSTING_RULE',
    ZTL = 'ZTL',
    NOT_PAID = 'NOT_PAID',
}
export const PAYMENT_TYPE_NOT_PAID = 0;
export const enum CurrencyTypeCodeEnum {
    NOK = 'NOK',
}
export const enum CreditorBankIdentificator {
    CREDITOR_BANK_IDENTIFICATOR_SWIFT = 0,
    CREDITOR_BANK_IDENTIFICATOR_CLEARING_CODE = 1,
}

export type PaymentType = {
    paymentTypeId: number;
    paymentTypeValue: string;
    paymentIntegration?: PaymentTypeEnum;
    currencyId?: number;
    currencyCode?: string;
    isDefault?: boolean;
};

export type RegulatoryReportingCode = {
    id: number;
    name: string;
};

export type PaymentWidgetData = {
    paymentTypes: PaymentType[];
    selectedPaymentType: PaymentType;
    date: string;
    amount: number;
    bankAccount: string;
    kid: string;
    readOnlyBankAccount: boolean;
    readOnlyKid: boolean;
};
export type SmartScanMap = {
    [key in keyof SmartScanPredictionObject]?: boolean;
};
export type PaymentWidgetAdvancedData = {
    paymentTypes: PaymentType[];
    selectedPaymentType: PaymentType;
    date: string;
    isForeignPayment: boolean;
    creditorBankIdentificator: number;
    amount: number;
    prepaidAmount?: number;
    creditorName: string;
    creditorAddress: string;
    creditorBankPostalCode: string;
    creditorBankPostalCity: string;
    comments: string;
    creditorBic: string;
    kid: string;
    accountNumber: string;
    smartScanMapping: SmartScanMap;
    customerVendorIbanOrBban: string[];
    creditorBankAddress: string;
    creditorBankName: string;
    creditorClearingCode: string;
    oppositeAmount: number;
    isAutoPay: boolean;
    isZtl: boolean;
    isCreditorAddressOnly: boolean;
    creditorCountryId: number;
    creditorBankCountryId: number;
    creditorPostalCode: string;
    creditorPostalCity: string;
    regulatoryReportingCode: string;
    regulatoryReportingInfo: string;
    currencyId: number;
    currencyCode: string;
};

export enum PaymentIntegration {
    AUTOPAY_RANGE_START = -3,
    AUTOPAY_RANGE_END = -1000,
    ZTL_RANGE_START = -1001,
}

export type PaymentWidgetValidations = {
    selectedPaymentType: string;
    date: string;
    amount: string;
    oppositeAmount?: string;
    bankAccount: string;
    kid: string;
    creditorName?: string;
    creditorAddress?: string;
    creditorPostalCode?: string;
    creditorPostalCity?: string;
    creditorCountryId?: string;
    creditorBic?: string;
    creditorBankCountryId?: string;
    regulatoryReportingInfo?: string;
    creditorClearingCode?: string;
};
