import { useEffect, useState } from 'react';
import {
    PaymentWidgetData,
    PaymentWidgetValidations,
    Scenario,
} from '@Page/PaymentWidget/types/paymentWidgetTypes';
import { putNotification } from '@Page/Expense/util/helper.functions';
import tlxFetch from '../../../api/tlxFetch';
import { getValidationMessage } from '@Page/PaymentWidget/hooks/utils';

export type UseSimplePaymentWidget = {
    paymentWidgetData: PaymentWidgetData;
    setPaymentWidgetData: (paymentWidgetData: PaymentWidgetData) => void;
    registerPayment: () => void;
    paymentWidgetValidations: PaymentWidgetValidations;
    setPaymentWidgetValidations: (
        paymentWidgetValidations: PaymentWidgetValidations
    ) => void;
};

const TSK_scenarios = [
    Scenario.FINANCE_TAX,
    Scenario.MANDATORY_TAX,
    Scenario.PAYROLL_TAX,
    Scenario.TAX_DEDUCTION,
];

export function useSimplePaymentWidget(
    scenario: Scenario,
    scenarioId: number
): UseSimplePaymentWidget {
    const [paymentWidgetData, setPaymentWidgetData] =
        useState<PaymentWidgetData>({
            paymentTypes: [],
            selectedPaymentType: {
                paymentTypeId: 0,
                paymentTypeValue: '',
            },
            date: '',
            amount: 0,
            bankAccount: '',
            kid: '',
            readOnlyBankAccount: false,
            readOnlyKid: false,
        });

    const [paymentWidgetValidations, setPaymentWidgetValidations] =
        useState<PaymentWidgetValidations>({
            selectedPaymentType: '',
            date: '',
            amount: '',
            bankAccount: '',
            kid: '',
        });

    async function fetchData() {
        const { response } = await tlxFetch({
            url: `/v2/bank/paymentWidget/?scenario=${scenario}&scenarioId=${scenarioId}`,
        });
        if (!response) {
            return;
        }
        setPaymentWidgetData(response);
    }

    useEffect(() => {
        fetchData();
    }, []);

    async function registerPayment() {
        const { response } = await tlxFetch({
            url: `/v2/bank/paymentWidget?scenario=${scenario}&scenarioId=${scenarioId}`,
            method: 'POST',
            body: {
                selectedPaymentType: paymentWidgetData.selectedPaymentType,
                date: paymentWidgetData.date,
                amount: paymentWidgetData.amount,
                bankAccount: paymentWidgetData.bankAccount || '',
                kid: paymentWidgetData.kid || '',
            },
        });
        if (response?.status === 422) {
            setPaymentWidgetValidations({
                selectedPaymentType:
                    getValidationMessage(response, 'selectedPaymentType') ||
                    getValidationMessage(response, 'currencyId'),
                date:
                    getValidationMessage(response, 'paymentDate') ||
                    getValidationMessage(response, 'date'),
                amount:
                    getValidationMessage(response, 'amount') ||
                    getValidationMessage(response, 'amountBasisCurrency'),
                bankAccount:
                    getValidationMessage(response, 'receiverBankAccount') ||
                    getValidationMessage(response, 'creditorIbanOrBban') ||
                    getValidationMessage(response, 'creditorBban'),
                kid: getValidationMessage(response, 'kid'),
            });
            return;
        }

        putNotification(
            [
                getMessage('text_action_completed'),
                getMessage('text_register_payment'),
            ].join(' ')
        );

        if (TSK_scenarios.includes(scenario)) {
            window.location.reload();
        }
    }

    return {
        paymentWidgetData,
        setPaymentWidgetData,
        registerPayment,
        paymentWidgetValidations,
        setPaymentWidgetValidations,
    };
}
