/**
 * General rules:
 * Only PBC team members can access the page.
 *
 * Only Admins can have access in Production to tools that alter data.
 * Tools to test ongoing tasks/epics (e.g: platform agnostic) are only accessible to team members involved in these tasks.
 */

/**
 * Defines access groups for specific tools in production.
 */
export enum PBC_TRIPLETEX_TEAM_ACCESS_GROUP {
    LOGGING_TOOLS = 'LOGGING_TOOLS',
    PLATFORM_AGNOSTIC = 'PLATFORM_AGNOSTIC',
    BANK_RECONCILIATION = 'BANK_RECONCILIATION',
}

/**
 * Admin = Full access in Production
 * Light = Full access in Local & Staging only
 * [PBC_TRIPLETEX_TEAM_ACCESS_GROUP] = Access to specific tools in production
 */
export const PBC_TRIPLETEX_TEAM: { [key: string]: number[] } = {
    ADMIN: [
        1978282, // Milena Dunic
        6087463, // Lars Vanderheydt
    ],
    LIGHT: [
        6412597, // Misa Minic
        6646111, // Elizabeth Miron
        3012571, // Melisa Damian
        2208722, // Andrei Gavra
        1232288, // Martin Brenden
        877061, // Tomas Finnerud,
        524268, // Pia Erichsen
    ],
    // access to logging tools in production
    [PBC_TRIPLETEX_TEAM_ACCESS_GROUP.LOGGING_TOOLS]: [
        6412597, // Misa Minic
        6646111, // Elizabeth Miron
        3012571, // Melisa Damian
        2208722, // Andrei Gavra
    ],
    [PBC_TRIPLETEX_TEAM_ACCESS_GROUP.PLATFORM_AGNOSTIC]: [
        3012571, // Melisa Damian
        2208722, // Andrei Gavra
    ],
    [PBC_TRIPLETEX_TEAM_ACCESS_GROUP.BANK_RECONCILIATION]: [
        6646111, // Elizabeth Miron
        6412597, // Misa Minic
    ],
};

export const TRIPLETEX_ID = 3001;
