import {
    Modal,
    ModalCloseButton,
    ModalLayout,
    ModalTitle,
    ModalSubtitle,
    Button,
    ModalFooter,
} from '@tlx/atlas';
import { renderComponent } from '@General/renderComponent';
import React, { useState } from 'react';
import './CanApproveOwnExpensesModal.css';

export function CanApproveOwnExpensesModal() {
    const [open, setOpen] = useState(true);
    return (
        <Modal
            onClose={() => setOpen(false)}
            open={open}
            className="canApproveOwnExpensesModal"
        >
            <ModalLayout>
                <ModalCloseButton />
                <ModalTitle>
                    {getMessage(
                        'text_expense_settings_can_approve_own_expenses'
                    )}
                </ModalTitle>
                <ModalSubtitle>
                    {`${getMessage(
                        'text_expense_settings_can_approve_own_expenses_description'
                    )} ${getMessage(
                        'text_expense_settings_one_approver_only_warning'
                    )}`}
                </ModalSubtitle>
                <ModalFooter>
                    <div className="atl-flex atl-gap-8">
                        <a
                            href="https://hjelp.tripletex.no/hc/no/articles/16549603662609-Hva-slags-tilganger-kan-jeg-gi-innenfor-reiseregning-og-utlegg"
                            className="atl-button atl-button--primary"
                            data-testid="canApproveOwnExpensesModal-read-more"
                            target="_blank"
                            onClick={() => setOpen(false)}
                        >
                            <div>{getMessage('text_read_more')}</div>
                        </a>
                        <Button
                            variant="secondary"
                            data-testid="canApproveOwnExpensesModal-close"
                            onClick={() => setOpen(false)}
                        >
                            {getMessage('button_close')}
                        </Button>
                    </div>
                </ModalFooter>
            </ModalLayout>
        </Modal>
    );
}

export function renderCanApproveOwnExpensesModal(domId: string) {
    renderComponent(CanApproveOwnExpensesModal, domId);
}
