import jQuery from 'jquery';
import { tlxInitJsonrpc } from '../c-common';
import { getTooltip } from '../o-common';
import { isChanged } from '../modules/change';
import { JSONRpcClient } from './jsonrpc';
import { nav } from '../modules/navigation';
import { showActionLogMessage } from '../modules/notification';
import { format } from '../modules/format';
import { dateUtil } from '../modules/date';
import { utils } from '../modules/utils';
import { tlxForms } from '../modules/forms';
import { tlxUrl } from '../modules/url';
import { dev } from '../modules/dev-util';
import { browserInfo } from '../modules/d-browserInfo';
import { extraFrame } from '../modules/extraFrame';
import { tlxAlert } from '../modules/alert';
import { renderAccountantVideo } from '@Page/AccountantVideo';
import { renderUserOnboardWizard } from '@Page/UserOnboardWizard';
import { createAPIRequest } from '../../react-modules/src/hooks/fetch/createAPIRequest';

const $ = jQuery;

tlxInitJsonrpc();

/// Initialization of the "app shell"

// TRIP-2433: Make sure jQuery doesn't bypass our caching system by appending a random query
// parameter to every URI loaded within $.html(doc), e.g. causing <script src="myfile.js"> to be treated as
// <script src="myfile.js?_=14876127836">, and thus avoiding the built-in browser cache for the URI "myfile.js".
//
// See https://api.jquery.com/jquery.ajaxsetup/ for documentation.
// Note: Usage of ajaxSetup is heavily discouraged, but this seems to be the cleanest way of ensuring caching.
$.ajaxSetup({
    cache: true,
});

// Configuration
JSONRpcClient.max_req_active = 2;

function noop() {
    /* noop */
}

// Temporary canary bird here: just to check that the ZendeskChatAPI will not return 403 any longer.
// The code doesn't actually do anything with the response.
window
    .fetch(createAPIRequest('/v2/internal/zendesk-chat/meta'))
    .catch((error) => {
        console.error('Could not load Zendesk chat meta', error);
    });

// Fix buggy chrome... (http://stackoverflow.com/a/18126524 | https://code.google.com/p/chromium/issues/detail?id=63040#c50)
// Should happen early (the idea is to do it before the popstate is triggered at least)
(function fixBuggyChromeHistoryApi() {
    // There's nothing to do for older browsers ;)
    if (!window.addEventListener) {
        return;
    }
    let blockPopstateEvent = document.readyState != 'complete';
    window.addEventListener(
        'load',
        function () {
            // The timeout ensures that popstate-events will be unblocked right
            // after the load event occured, but not in the same event-loop cycle.
            setTimeout(function () {
                blockPopstateEvent = false;
            }, 0);
        },
        false
    );
    window.addEventListener(
        'popstate',
        function (evt) {
            if (blockPopstateEvent && document.readyState == 'complete') {
                evt.preventDefault();
                evt.stopImmediatePropagation();
            }
        },
        false
    );
})();

/**
 *  Datepicker initialization:
 *  - lazily initialize datepickers for performance
 *  - code to prevent user from setting end date before start date (datefields with classes tlxEnd/StartDate)
 */
function initDatepicker() {
    const $body = $('body');

    function syncWithMinDate(dateInput, instance) {
        const $endDates = $body.find('.tlxDateField.tlxEndDate');
        const $startDates = $body.find('.tlxDateField.tlxStartDate');
        const $startDate = $startDates.eq($endDates.index(this));
        if ($startDate.length == 0) {
            if (!window.productionMode) {
                alert('tlxStartDate without matching tlxEndDate');
            }
            return;
        }
        const selectedDate = $startDate.val();
        const date = $.datepicker.parseDate(
            instance.settings.dateFormat || $.datepicker._defaults.dateFormat,
            selectedDate,
            instance.settings
        );
        $(dateInput).datepicker('option', 'minDate', date);
    }

    const headerHtml = $(
        '<div class="ui-datepicker-tmdl-header">' +
            '<div class="ui-datepicker-tmdl-year"></div>' +
            '<div class="ui-datepicker-tmdl-date"></div>' +
            '</div>'
    );

    const updateTmdlHeader = function (header, date) {
        const dateObject = new Date(date);
        const dateString = dateUtil.formatDate(dateObject, 'EEEE d. MMMM');
        $('.ui-datepicker-tmdl-year', header).text(dateObject.getFullYear());
        $('.ui-datepicker-tmdl-date', header).text(dateString);
    };

    //Copied from Jquery UI (ish)
    const calculatePosition = function ($elem) {
        const widget = $elem.datepicker('widget');
        const dpHeight = widget.outerHeight();
        const viewHeight = window.innerHeight;
        const inputHeight = $elem.outerHeight();
        let top = $elem.offset().top + $elem.outerHeight();

        top -= Math.min(
            top,
            top + dpHeight > viewHeight && viewHeight > dpHeight
                ? Math.abs(dpHeight + inputHeight)
                : 0
        );
        widget.css('top', top);
    };

    $body.on('click', 'span.tmdl-date-icon', function (e) {
        const $elem = $(e.target).prevAll('input');
        if ($elem.is(':disabled')) {
            return;
        }

        if (!$elem.is('.hasDatepicker')) {
            $elem.datepicker({
                yearRange: 'c-100:c+10',
                defaultDate: $elem.data('defaultDate'),
                showButtonPanel: true,
                changeYear: false,
                changeMonth: false,
                minDate: $elem.data('mindate'),
                maxDate: $elem.data('maxdate'),
                weekHeader: '',
                autoSize: false,
                beforeShow: $elem.hasClass('tlxEndDate')
                    ? syncWithMinDate
                    : null,
                onClose: function () {
                    $('.mdl-layout').removeClass('datepickerVisible');
                    $elem.removeAttr('readonly');
                },
                onSelect: () => {
                    $elem
                        .get(0)
                        .dispatchEvent(new Event('change', { bubbles: true }));
                },
            });

            if (
                $elem.datepicker('getDate') === null &&
                $elem.data('defaultDate') != null
            ) {
                // Set default date on datepicker.
                $elem.datepicker('setDate', $elem.data('defaultDate'));
            }

            $elem.attr('readonly', 'readonly');
        }

        //Need to update the header the first time it is displayed with either the preselected date or today
        updateTmdlHeader(headerHtml, $elem.datepicker('getDate') || new Date());

        //We can not use the datepicker's onSelect method because it seems to override the date
        //actually being set which causes the rate field not being updated.
        $elem.on('change', function () {
            updateTmdlHeader(headerHtml, $elem.datepicker('getDate'));
        });

        $elem.datepicker('show');
        $('.mdl-layout').addClass('datepickerVisible');

        //Prepending the "mdl header" after the datepicker is added to the DOM
        $('.ui-datepicker').prepend(headerHtml);

        //This event must be triggered after the "mdl header" is added
        $elem.datepicker('widget').trigger('tlxDatepickerVisible');

        //We need to recalculate the position of the datepicker after
        //the "mdl header" has been added
        calculatePosition($elem);

        window.setTimeout(function () {
            $elem.datepicker('widget').scrollIntoView();
        }, 50);

        //Prevent keyboard from popping up
        $elem.blur();

        //It appears that the datepicker is "redrawn" every time you click on
        //one of the prev or next buttons. We must therefore add the header
        //each time one of these are clicked.

        function clickHandler() {
            const $header = $elem
                .datepicker('widget')
                .find('.ui-datepicker-tmdl-header');
            if ($header.length === 0) {
                $elem.datepicker('widget').prepend(headerHtml);
            } else {
                updateTmdlHeader(
                    $header,
                    $elem.datepicker('getDate') || new Date()
                );
            }

            //Prevent keyboard from popping up
            $elem.blur();
        }

        // We repurpose the datepicker for the different date fields, so we need to turn the click handler off
        // before we turn it on again.
        $($elem.datepicker).off('click.datedialog');
        $($elem.datepicker).on(
            'click.datedialog',
            '.ui-datepicker-next, .ui-datepicker-prev ',
            clickHandler
        );
    });

    $(window).on('tlxNavigateAjax', function cleanUpDatePicker() {
        $.datepicker._disabledInputs = []; // Plug memory leak (best seen when using periodselector i hourlist) (bug reported http://bugs.jqueryui.com/ticket/10629)

        // When navigating away from a page with the datepicker dialog up, the datepicker dialog stays up...
        // Since jquery ui datepicker repurposes this div with ID for datepicker dialog, just hiding this on navigation should be harmless?
        $('#ui-datepicker-div').hide();
    });
}

/**
 * Set up global event handlers, keybindings, etc.
 */
function initialize() {
    initDatepicker();

    function prettyPrint(e) {
        const $target = $(e.target);
        if (
            !$target.is('input') ||
            $target.data('formatkey') === 'hoursTimeRange'
        ) {
            return;
        }

        // Make sure computation of maths inside input item is done before pretty printing.
        if ($target.is('input.number')) {
            format.computeInput($target);
        }

        // Don't pretty print unless input item has a vformat and doesn't contain any unresolved math operations
        if (!$target.is('input[type=text][data-vformat]')) {
            return;
        }
        // Don't try to format the number if there is no value in the input
        if (
            ($target.data('vformat').charAt(0) === '#' ||
                $target.data('vformat') === 'HH:mm') &&
            $target.val() !== ''
        ) {
            $target.val(format.withKey($target.val(), $target.data('vformat')));
        }
    }

    /**
     * Function used on global keydown event. Useful for shortcuts
     * @param e
     */
    function inputOnKeyDown(e) {
        const target = e.target;
        if (!$(target).is('input')) {
            return;
        }
        const vformatdata = $(target).data('vformat');

        switch (vformatdata) {
            case 'yyyy-MM-dd':
                tlxForms.dateOnKeyDown(target, e);
                return false;
            default:
                return;
        }
    }

    /**
     * Function used on global input event. Useful for input validation
     * @param e
     */
    function inputOnInput(e) {
        const $target = $(e.target);
        const vformatdata = $target.data('vformat');
        // selectionStart is only supported for inputs of type text, search and password
        // See https://html.spec.whatwg.org/multipage/input.html#do-not-apply
        const caretPosition =
            ['text', 'search', 'password'].indexOf($target[0].type) === -1
                ? -1
                : $target[0].selectionStart;
        switch (vformatdata) {
            case 'yyyy-MM-dd':
                tlxForms.dateOnInput(e);
                break;
            case 'HH:mm':
                tlxForms.timeOnInput(e);
                break;
            default:
                return;
        }
        if (caretPosition !== -1) {
            $target[0].setSelectionRange(caretPosition, caretPosition);
        }
    }

    // Capture event as it climbs down the DOM tree.
    // This is to make sure that the changes in the input element is computed before site specific
    // change/focusout event handlers.
    document.body.addEventListener('change', prettyPrint, true);
    document.body.addEventListener('focusout', prettyPrint, true);
    document.body.addEventListener('keydown', inputOnKeyDown, true);
    document.body.addEventListener('input', inputOnInput, true);

    $('body')
        .on(
            'keydown',
            ":tlx-inputOpener:not('.ui-state-active')",
            function (event) {
                //TODO check for read-only?
                if (event.ctrlKey || event.metaKey) {
                    return;
                }

                if (event.which == 40) {
                    //down arrow
                    $(this).triggerHandler('click');
                    event.preventDefault();
                    event.stopPropagation();
                } else if (
                    (event.which == 43 || event.which == 107) &&
                    $(this).siblings('.ui-icon-plus').length == 1
                ) {
                    // 43 = + (plus-sign), 107 = "add" (which is triggered depends on browser)
                    $(this).siblings('.ui-icon-plus').triggerHandler('click');
                    //Set focus to make sure Opera does not trigger zoom.
                    $(this).focus();
                }
            }
        )
        .click(function (e) {
            // NAVGATION HANDLING!
            // This global click event listener is to handle navigation.
            // The application should work without this code: It will just turn off "smart" ajax navigation.

            let $target = $(e.target);

            if ($target.is('a[id]')) {
                // Remove tooltip for link, if a link is clicked
                $('.mdl-tooltip[for="' + $target.attr('id') + '"]').remove();
            }

            // Allow ctrl/cmd clicking on links to open link in new tab and shift-click for open in new window
            if (e.ctrlKey || e.metaKey || e.shiftKey) {
                return true;
            }

            // We are only interested in clicks within a link element, but that is not a click on any clickable
            // element within the link!
            if ($target.closest('button, [role="checkbox"]').length > 0) {
                return true;
            }

            // Mimicking normal click event handling for when events propagate to anchors.
            if (e.isDefaultPrevented()) {
                return true;
            }

            /* Ajax-load the resources in a.navigate links. */
            if ($target.closest('a.navigate').length > 0) {
                $target = $target.closest('a.navigate');
                const href = $target.attr('href');
                const target = $target.attr('target');
                const fragmentIdentifier = $target.attr('fragmentIdentifier');

                nav.nav(href, {
                    target: target,
                    fragmentIdentifier: fragmentIdentifier,
                });
                e.preventDefault();
                return false;
            }

            const $navigateMenu = $target.closest('a.navigateMenu');
            if ($navigateMenu.length === 1) {
                const href = $navigateMenu.attr('href');
                if (!href) {
                    return;
                }
                const fragmentIdentifier =
                    $navigateMenu.data('fragmentIdentifier');
                const target = $navigateMenu.attr('target');

                const args = {
                    target: target,
                    fragmentIdentifier: fragmentIdentifier,
                };

                if ($navigateMenu.is('.favorite')) {
                    args.filter = {};
                }
                nav.menu(href, args);
                e.preventDefault();
                return false;
            }
        });

    let staleSpacesuitDeployment = false;

    window.addEventListener('spacesuit.stale', () => {
        staleSpacesuitDeployment = true;
    });

    // Hijack navigation events from the sidebar - instead of default behavior (hard navigation)
    // we delegate work to frameless, which has its own routing mechanism.
    //
    // If Spacesuit deployment is stale, force a hard navigation.
    window.addEventListener('tlx:navigate', (ev) => {
        if (!staleSpacesuitDeployment) {
            ev.preventDefault();
            if (ev.detail.shouldRestoreFilter) {
                nav.menu(ev.detail.href);
            } else {
                nav.nav(ev.detail.href);
            }
        }
    });

    let $closedPostsDialog;
    /// Close group dialog
    $('body').click(function (e) {
        const $target = $(e.target);
        if ($target.is('.jsCloseGroupOpener')) {
            if (
                $closedPostsDialog &&
                $closedPostsDialog.dialog('instance') !== undefined
            ) {
                // close existing close group dialog
                $closedPostsDialog.dialog('destroy');
                $closedPostsDialog = null;
            }
            $target.blur(); // remove focus from link
            let tooltipId = $target.attr('data-component-id');
            if (tooltipId) {
                tooltipId = parseInt(tooltipId);
                if (tooltipId != 0) {
                    const xhtml =
                        "<div id='alertDialog'>" +
                        getTooltip('CloseGroup', tooltipId) +
                        '</div>';
                    const buttons = [
                        {
                            text: getMessage('button_ok'),
                            autofocus: true,
                            click: function () {
                                $(this).dialog('destroy');
                                $closedPostsDialog = null;
                            },
                        },
                    ];
                    $closedPostsDialog = $(xhtml)
                        .dialog({
                            title: getMessage('text_closed_postings'),
                            buttons: buttons,
                            minWidth: 640,
                            modal: true,
                            hide: 'fade',
                            open: function () {
                                $('a', this).attr('title', null);
                            },
                        })
                        .click(function (ee) {
                            if (window.narrowScreen) {
                                // On narrow screen, close the dialog if the user clicks a link
                                const $trg = $(ee.target);
                                if ($trg.is('a')) {
                                    const $this = $(this);
                                    if (
                                        $this.dialog('instance') !== undefined
                                    ) {
                                        $this.dialog('close');
                                        $this.dialog('destroy');
                                    }
                                    $closedPostsDialog = null;
                                }
                            }
                        });
                }
            }
            return false;
        }
    });

    const $scrollContainer = $('body').frameless('getScrollContainer');
    const scrollContainer = $scrollContainer[0];

    function scrollStateHandler() {
        /**
         * Replace history.state with new scroll positions on scroll,
         * but only after user is finished scrolling.
         */
        history.replaceState(
            $.extend(history.state, {
                scrollTop: $scrollContainer.scrollTop(),
                scrollLeft: $scrollContainer.scrollLeft(),
            }),
            '',
            location.href
        );
    }

    utils.createStartStopScrollEvent(scrollContainer);

    /**
     * No need to update history more than every two seconds on scrollStop. This is just a minor convenience for the
     * browser to remember where scroll is on navigation. In safari, not throttling this caused a massive log messages
     * on our part: Scroll with arrow keys send a LOT of scrollStart and scollStop events.
     *
     * Only updating history on scrollStop, and only every two seconds, seems reasonable.
     */
    scrollContainer.addEventListener(
        'scrollStop',
        $.throttle(scrollStateHandler, 2000)
    );

    (function collapsibleSections() {
        const collapsibleSection = '.section.collapsible';

        // Use form name + component id to create a unique id for the form that remains the same across
        function formId($form) {
            return (
                window.loginEmployeeId +
                '_' +
                window.loginCompanyId +
                ($form.attr('id') || '') +
                $form.attr('name') +
                $form.find('[name=documentationComponent]').val()
            );
        }

        $('body').click(function (e) {
            const $target = $(e.target);

            if (
                !$target.is('.section.collapsible > .tlxSection-head') &&
                !$target.is('.section.collapsible > .tlxSection-head > h3') &&
                !$target.is(
                    '.section.collapsible > .tlxSection-head > .section-summary'
                ) &&
                !$target.is(
                    '.section.collapsible > .tlxSection-head > .section-summary > span'
                )
            ) {
                return;
            }

            // Toggle class for collapsed section
            const $section = $target.closest('.section');
            const $content = $section.children().filter('.sectionContent');

            const method = $content.is(':visible') ? 'addClass' : 'removeClass';

            $section.find('h3')[method]('collapsed');

            $content.slideToggle({
                start: function () {
                    $section[method]('collapsed');
                },
                done: function () {
                    // Store what sections are open or closed
                    const $form = $target.closest('form');
                    const sectionIsCollapsed = $.map(
                        $form.find(collapsibleSection),
                        function (a) {
                            return $(a).is('.collapsed') ? 1 : 0;
                        }
                    );
                    try {
                        localStorage.setItem(
                            formId($form),
                            JSON.stringify(sectionIsCollapsed)
                        );
                    } catch (e) {
                        // Do nothing
                    }

                    $section.trigger('tlx-sectionExpandCollapse', {
                        collapsed: method === 'addClass',
                    });
                },
                step: function (now, tween) {
                    tween.elem.style.display = '';
                },
                duration: 200,
            });

            $target.trigger('tlxPossibleWidthChange');

            return;
        });

        $(window).on(
            'tlxLoadPageContentDone tlxtabsload tlxLazyLoadDone txrPageDialogLoadDone',
            function (event, obj) {
                const $panel =
                    obj.$container || obj.panel || $('.lazyLoad--done');
                $panel.find('form').each(function () {
                    // Use hash "parameter" to force a specific section to open (by id)

                    const section = (/section=([a-z]+)/.exec(location.hash) ||
                        [])[1];
                    const $form = $(this);
                    const id = formId($form);
                    let map;
                    const $sections = $form.find(collapsibleSection);

                    if (section) {
                        $sections.each(function () {
                            const $this = $(this);
                            const method =
                                $this.attr('id') !== section
                                    ? 'addClass'
                                    : 'removeClass';
                            $this.add('h3', this)[method]('collapsed');
                        });
                        return;
                    }

                    try {
                        map = localStorage.getItem(id);
                    } catch (e) {
                        // Do nothing
                    }

                    if (!map) {
                        return;
                    }

                    map = JSON.parse(map);

                    if (map.length !== $sections.length) {
                        return;
                    }
                    $sections.each(function (i) {
                        const method = map[i] ? 'addClass' : 'removeClass';
                        $(this).add('h3', this)[method]('collapsed');
                    });

                    $form.trigger('tlxPossibleWidthChange');
                });
            }
        );

        $(window).on('validationError', function (e) {
            $(e.target)
                .closest('.section.collapsed')
                .add('.section h3.collapsed')
                .trigger('click')
                .removeClass('collapsed');
        });
    })();

    $(document).bind('keydown', documentOnkeydown);

    window.onpopstate = function () {
        nav.ajax(location.href);
    };

    document.onchange = onchange;

    /**
     * ROA-238
     */
    (function infoInputItems() {
        $(window).on(
            'tlxtabsload tlxLoadPageContentDone tlxLazyLoadDone',
            function () {
                $('.inputItem[data-info], td[data-info]').each(function () {
                    const $this = $(this);
                    const text = $this.data('info').trim();
                    if (text.length === 0) {
                        return;
                    }
                    const $icon = $(
                        '<span class="ui-icon ui-icon-info tlx-text-icon"></span>'
                    );

                    $this.find('.ui-icon-info').remove();
                    if ($this.is('.inputItem')) {
                        $this.find('.inputItemContent').append($icon);
                    } else {
                        $this.append($icon);
                    }

                    $icon.on('click', false);
                });
            }
        );
    })();

    /**
     * ROA-194: Remember scroll position when using history API.
     *
     * @author bruce
     */
    (function () {
        const $scrollContainer = $('body').frameless('getScrollContainer');

        // Scroll to original scroll position on tlxLazyLoad done, unless
        // user-interaction has occured between loading filter and loading
        // lazy content.
        let originalScrollTopPosition;
        let originalScrollLeftPosition;

        $(window)
            .on('tlxLoadPageContentDone tlxRestoreScroll', function () {
                // Make sure to reset these before we decide to skip scrolling because
                // frameless.ignoreScroll is set to true.
                originalScrollTopPosition = originalScrollLeftPosition = 0;

                /**
                 * SUP-586. Sometimes we want to NOT scroll to "correct" position when doing history.back().
                 */
                if ($('body').frameless('ignoreScroll')) {
                    return;
                }

                const state = history.state;
                originalScrollTopPosition = originalScrollLeftPosition = 0;
                if (state && state.scrollTop) {
                    originalScrollTopPosition = state.scrollTop;
                    originalScrollLeftPosition = state.scrollLeft;
                    $scrollContainer
                        .scrollTop(state.scrollTop)
                        .scrollLeft(state.scrollLeft);
                }

                // A simple way to check for user interaction.
                $scrollContainer.one('click keydown', function () {
                    originalScrollTopPosition = originalScrollLeftPosition = 0;
                });

                // Load to fragmentId if available
                const fragmentId = $.sessionStorage.getItem('fragmentId');

                if (fragmentId) {
                    $.sessionStorage.removeItem('fragmentId');

                    try {
                        const $scrollTo = $(
                            '[name=' + fragmentId + '], #' + fragmentId
                        );

                        if ($scrollTo.length > 0) {
                            $('body').frameless(
                                'scrollIntoView',
                                $scrollTo.get(0)
                            );
                        }
                    } catch (error) {
                        /**
                         * Sometimes fragmentId becomes "_=_" which results in a syntax error when trying to select the element.
                         *
                         * @see https://tripletex.sentry.io/share/issue/de366f0d587b445d94cec35a017c7c59/
                         */
                        /*
                        window.Sentry.captureException(error, {
                            extra: {
                                fragmentId,
                            },
                        });
                        */
                        console.error(error);
                    }
                }
            })
            .on('tlxLazyLoadDone tlxtabsload', function () {
                // If no user interaction has occured, scroll to original position.
                if (originalScrollTopPosition || originalScrollLeftPosition) {
                    $scrollContainer
                        .scrollTop(originalScrollTopPosition)
                        .scrollLeft(originalScrollLeftPosition);
                }
            });
    })();

    $(document).on('tlxNewRowAdded', function (event) {
        const $newRow = $(event.target);
        const $firstInput = $newRow
            .find(':input:visible')
            .not("[type='button']")
            .first();

        // Do not focus first element if narrowScreen and element is datepicker.
        // Datepicker does not trigger if element has focus and user clicks on it.
        if (!(window.narrowScreen && $firstInput.is('.tlxDateField'))) {
            // (this should happen for all rows as opposed to the scrolling below)
            $firstInput.focus();
            // WARNING: there might be a race condition for dropdowns here. There is no guarantee that focus will be set before dropdown is created,
            // and setting focus after creating dropdown will not work. TRIP-17195
        }

        /* Note about focus and scrolling: [ojb - 25. nov. 2014]
         * - There don't seem to be a good way to disable the native scroll-on-focus.
         * - IE, for example, scrolls minimally (which is mostly why we need to interfere at all)
         * - Chrome scrolls alot, and this can cause a visual glitch if the focus in initiated after our own scroll-into-view.
         *
         * Therefore it's important that we either
         * 1. focus before initiating our own scroll-into-view, or
         * 2. focus after our scroll-into-view are done.
         * I ended up with option 1, as the only drawback seems to be inconsistent behavior. (our scroll-into-view is animated)
         */

        if ($newRow.closest('#scrollContainer').length > 0) {
            // We don't know how to scroll outside #scrollContainer atm...

            // FRAMEWORK: Should make a utility to get the height of the top-floating-stuff...
            const topStuff =
                ($('.tlx-toolbar-clone:visible').outerHeight() || 0) +
                ($('.tlxFloatingHeader:visible').outerHeight() || 0);
            // Leave some space for the "new-row" row + some air (some rows could have some initially hidden content too)
            $('body').frameless('scrollIntoView', $newRow.get(0), {
                offsetBottom: 70,
                offsetTop: topStuff + 10,
                duration: 300,
            });
        }
    });
}

function documentOnkeydown(event) {
    if (!event) {
        event = window.event;
    }
    const keyCode = event.keyCode ? event.keyCode : event.charCode;

    const stopEvent = utils.stopEvent;

    // Backspace, prevent history back if there is changes in the form
    if (keyCode === 8 && isChanged()) {
        if (!$(event.target).is('input, textarea, [contentEditable="true"]')) {
            event.stopPropagation();
            event.preventDefault();
            return false;
        }
    }

    // Ctrl+enter or meta+enter refresh the filter.
    if (event.keyCode === 13 && (event.metaKey || event.ctrlKey)) {
        const $filter = $('.filter:visible');

        if ($filter.length) {
            try {
                $filter.filterComponent('refresh');
            } catch (e) {
                // Filter component not initialized / wrong class name on DOM element. Never mind.
            }
        }

        return false;
    }

    // CTRL-S
    if ((event.ctrlKey || event.metaKey) && keyCode == 83) {
        let $savebutton;

        const $openedDialogs = $(
            '.ui-dialog:visible, .txr-page-dialog:visible'
        );
        if ($openedDialogs.length > 0) {
            // Assume that top dialogs occurs last in DOM (used z-index before, but seems like jquery ui no longer sets this)
            $openedDialogs
                .last()
                .find('.ui-dialog-buttonpane button:not(:disabled)')
                .each(function () {
                    if ($(this).hasClass('storeAction')) {
                        $savebutton = $(this);
                        return false;
                    }
                });

            // Try to find save button in React dialog as well
            if (!$savebutton) {
                $savebutton = $openedDialogs
                    .last()
                    .find('.txr-page-dialog__footer .txr-button--raised')
                    .last();
            }

            if ($savebutton) {
                // Firefox triggers native ctrl+s / save page, if not set with delay
                // IE needs delay after focus - otherwise onblur will not be executed before the click.
                $savebutton.focus();
                setTimeout(function () {
                    $savebutton.trigger('click');
                }, 1);
            }
            stopEvent(event);
            return false;
        }
        // dialog (without savebutton, typically toolbar dialogs) open -> block save
        // (better to add this to the dialog itself? I assume it will get the event first?)
        if ($('.ui-dialog:visible').length > 0) {
            stopEvent(event);
            return false;
        }

        //if overylay is present, an ajaxsave is already running.
        if ($('.tlx-overlay:visible').length > 0) {
            dev.debugLine('cancelled ctrl+s');
            stopEvent(event);
            return false;
        }

        // Try use save action in action bar
        $savebutton = $('.tmdl-layout__action-bar button.storeAction:visible');
        // If no action bar, try to find other save buttons on page (i.e. table buttons)
        if ($savebutton.length === 0) {
            $savebutton = $('.storeAction:visible:first');
        }

        //// Trigger the save ... harder than expected :)
        // updateHourlistNew need the active element to be blurred (at least if the focus is in a description textarea. Blur handler moves the text to the correct hidden form input)
        // was: saveButton.focus();
        // try with:
        $(document.activeElement).trigger('blur').focus();
        // This prevents page to scroll to top. Unwanted on the new ajaxy sites. Focus again so that the active element stays focused
        // Ideally we wouldn't need focus/blur, but it's not 100% certain which cases/places need it

        // Firefox triggers native ctrl+s / save page, if not set with delay
        // IE needs delay after focus/blur - otherwise onblur will not be executed before the click.
        setTimeout(function () {
            $savebutton.trigger('click');
        }, 1);

        stopEvent(event);
        return false;
    }

    return true;
}

window.createInfoMessagePopup = function createInfoMessagePopup(messages) {
    for (const message of messages) {
        const pageName = getMessage('text_login2');
        showActionLogMessage({
            pageName: pageName,
            date: new Date(),
            message,
        });
    }
};

/* Further "app shell" initializations that must wait for DOM ready. */
$(function () {
    extraFrame.setExtraWindow($('iframe[name=extra]')[0].contentWindow);

    const $framelessContent = $('#framelessContent');
    const $extraFrameContainer = $('#extraFrameContainer');
    const $scrollContainer = $('#scrollContainer');

    function initializeSplitView() {
        $extraFrameContainer.resizable({
            handles: 'w, n',
            minHeight: 100,
            minWidth: 530,
            stop: function () {
                const axis = $extraFrameContainer.data('ui-resizable').axis;

                $('.iframeCover').remove();

                if (axis === 'w') {
                    // resizable bug: sets height even when only resized horizontally. Set it back
                    $extraFrameContainer.css('bottom', '0').css('top', '0');
                    // Change properties back to percent based so window resizing works
                    const right = $extraFrameContainer;
                    const left = $scrollContainer;
                    const leftPercent =
                        (left.width() / $framelessContent.width()) * 100;

                    left.css('width', leftPercent + '%');
                    right
                        .css('width', 100 - leftPercent + '%')
                        .css('left', leftPercent + '%');
                }
            },
            resize: function (ev, ui) {
                const axis = $extraFrameContainer.data('ui-resizable').axis;
                // hack to make resizing work in our case (with a fixed container)

                if (axis === 'w') {
                    $scrollContainer.width(
                        $framelessContent.width() - ui.size.width
                    );
                } else {
                    $scrollContainer.height(
                        window.innerHeight - ui.size.height - 30
                    );
                }
                window.dispatchEvent(new CustomEvent('resize'));
            },
        });

        $('.ui-resizable-n').hide();

        // Scroll fix for iframe rendering in iPad.
        // On iPad, iframe and all it's content gets flattened. The element containing
        // the iframe gets responsible for "scrolling" the content of the iframe.
        // We want this to be the parent DIV of the iframe, not the #extraFrameContainer.
        if (browserInfo.isIpad()) {
            $('#extraFrameContainer').css('overflow', 'hidden');
            $('iframe[name=extra]').parent().css({
                overflow: 'scroll',
                '-webkit-overflow-scrolling': 'touch',
            });
        }

        // resizables have some issues with iframes. Fix it by overlaying a cover when resizing. (need opacity/background for ie..)
        // "resizable.start" isn't called before first mousemove. That's too late since the mouse might be above the iframe at that time
        $extraFrameContainer.find('.ui-resizable-w').mousedown(function () {
            const d = $(
                '<div class="iframeCover" style="z-index:900;position:absolute;width:90%;top:0;left:10px;height:100%;opacity:0.001;background:#fff">' +
                    '</div>'
            );
            $extraFrameContainer.append(d);
        });

        $extraFrameContainer.find('.ui-resizable-n').mousedown(function () {
            const d = $(
                '<div class="iframeCover" style="z-index:89;position:absolute;width:100%;top:0;height:100%;opacity:0.001;background:#fff">' +
                    '</div>'
            );
            $extraFrameContainer.append(d);
        });
    }

    if (!browserInfo.isMobileReg()) {
        initializeSplitView();
    }

    (function () {
        // Based on https://developer.mozilla.org/en-US/docs/Web/API/Storage
        const dummy = {
            length: 0,
            getItem: function () {
                return null;
            },
            setItem: noop,
            removeItem: noop,
            key: function () {
                return null;
            },
            clear: function () {
                return undefined;
            },
        };

        try {
            window.localStorage.setItem('tull', 'tull');
            window.localStorage.removeItem('tull');
            $.localStorage = window.localStorage;
        } catch (e) {
            $.localStorage = dummy;
        }

        try {
            window.sessionStorage.setItem('tull', 'tull');
            window.sessionStorage.removeItem('tull');
            $.sessionStorage = window.sessionStorage;
        } catch (e) {
            $.sessionStorage = dummy;
        }
    })();

    /// From wrapper.jsp (javascript init)
    $.support.touch = !!('ontouchstart' in window);
    $.support.placeholder = false;
    const test = document.createElement('input');
    if ('placeholder' in test) {
        $.support.placeholder = true;
    }

    /// End

    // Hack to get different style (markup generated by the main menu tag tlx:mlt)
    // Must happen before initialization of 'frameless' widget. That's why it's here, and not together with the rest of the menu initialization
    // Done as a hack since we are hours away from release :) Ideally we wouldn't have to do this style post-processing, and it probably whould
    // be better if the menu load code ran in the same .ready as the rest of the initialization
    $('#loginInfo')
        .find('.subMenuItemL')
        .removeClass('ui-state-default')
        .find('.topMenuItem')
        .filter('.subMenuItemL')
        .removeClass('subMenuItemL')
        .addClass('noSubmenu');

    $('body').frameless({ content: '#framelessContent' }); // http://codepen.io/anon/pen/mwvnk for scrollcontainer testbed

    /// Handle not-logged-in ajax errors: (Must be bound on document)
    $(document).ajaxError(function (ev, xhr) {
        if (xhr.status == 401) {
            if (/^\/v2\//.test(xhr.url)) {
                return; // [julian @ 2017-12-12] do not redirect to 401 for API errors as they may not be caused by a user
            }

            if (!isChanged()) {
                // no changes - just redirect to login page
                let site;
                if (window.locale.startsWith('en')) {
                    site = 'en';
                } else {
                    site = 'no';
                }
                const messageCode =
                    xhr.getResponseHeader(
                        'Private-Tlx-LoginFailedMessageCode'
                    ) || '2';
                location.href = tlxUrl.addUrlParameters(
                    '/execute/login',
                    'contentUrl',
                    location.pathname + location.search,
                    'messageCode',
                    messageCode,
                    'site',
                    site
                );
            }
        }
    });

    function loadActualPage() {
        /* WEAKNESS: We don't turn on filter saving since we don't know if the link is a "menu link" at this point.
         * This means that the first page loaded after login won't save it's filter.
         * In the future we could pass some information from login action to signal that we want filter state saving,
         * but this quirk was considered acceptable for now. also see/update if fixed confluence:Navigation [ojb - 17. sep. 2014]
         */
        const url = document.location.pathname + document.location.search; // This url contains contextId
        const hash = document.location.hash;
        const fragmentIdentifier =
            hash.length > 1 ? hash.substring(1) : undefined; // .hash = "#<the-hash>"
        nav.nav(tlxUrl.removeUrlParameter(url, 'contextId'), {
            replaceState: true,
            fragmentIdentifier: fragmentIdentifier,
        });

        initialize();
    }

    /**
     * The onboard wizard is a fullscreen onboarding screen. If this is shown, instead of loading the actual page we are
     * on, show this onboarding instead. When onboarding closes, we can start loading this page instead.
     */
    if (window.onboardWizardSettings.showUserOnboardWizard) {
        // Hide everything in body
        const $body = $('body').addClass('fullscreen-dialog-up');
        $body.children().addClass('fullscreen-dialog-up');
        // Run the wizard
        $body.append('<div id="onboardingwizard"></div>');

        renderUserOnboardWizard('onboardingwizard');

        // Show everything in body and load page when wizard is closed
        $body[0].addEventListener('onboardingWizardClose', function () {
            window.onboardWizardSettings.showUserOnboardWizard = false;
            loadActualPage();
            $('.fullscreen-dialog-up').removeClass('fullscreen-dialog-up');
        });
    } else {
        loadActualPage();
    }

    /**
     * This ensures when clicking the "Duplicate (browser) tab" button, the duplicated browser tab also opens the
     * currently open Tripletex tab.
     *
     * A different approach would be to ensure to update this link every time a navigation occurs. I think this
     * "useCapture click event handler" solution is more robust and "technology agnostic", though.
     */
    document.getElementById('duplicateTab')?.addEventListener(
        'click',
        function () {
            this.href = location.hash;
        },
        true
    );
});

(function () {
    // We do not want to show any Tripletex specific texts when Tripletex is used as and integration (e.g Agro)
    if (window.integration) {
        return;
    }

    function validatePassword() {
        const passwordRequirements = [
            {
                pattern: /......../,
                className: '.p-hint-length',
            },
            {
                pattern: /[a-z]/,
                className: '.p-hint-small',
            },
            {
                pattern: /[A-Z]/,
                className: '.p-hint-capital',
            },
            {
                pattern: /[^A-Za-z]+/,
                className: '.p-hint-special',
            },
        ];

        const password = $('#newPasswordDialog [name="password"]').val();
        let nCorrect = 0;

        passwordRequirements.forEach(function (requirement) {
            const $em = $(requirement.className, '#newPasswordDialog');
            if (requirement.pattern.exec(password)) {
                $em.toggleClass('p-hint-missing', false);
                nCorrect++;
            } else {
                $em.toggleClass('p-hint-missing', true);
            }
        });

        return nCorrect === passwordRequirements.length;
    }

    if (window.showPasswordDialog) {
        $(window).one('tlxLoadPageContentDone ', function () {
            function showNewPasswordDialog() {
                let passwordSet = false;
                const $passwordDialog = $('#newPasswordDialog');
                const $newPasswordErrorMessage = $(
                    '.js-password-dialog__error-message',
                    $passwordDialog
                );

                $('#newPasswordDialog').dialog({
                    modal: true,
                    title: getMessage('text_new_password'),
                    buttons: [
                        {
                            text: getMessage('text_change_password'),
                            click: function () {
                                if (!validatePassword()) {
                                    $newPasswordErrorMessage.text(
                                        getMessage(
                                            'text_password_failed_validation'
                                        )
                                    );
                                }

                                const password = $(
                                    '#newPasswordDialog [name="password"]'
                                ).val();
                                passwordSet =
                                    window.jsonrpc.Employee.setPassword(
                                        password
                                    );

                                if (passwordSet) {
                                    // This effectively closes the dialog and make sure other startup screens are shown instead
                                    location.reload();
                                } else {
                                    // Display error message?
                                    $newPasswordErrorMessage.text(
                                        getMessage(
                                            'text_password_failed_validation'
                                        )
                                    );
                                }
                            },
                        },
                    ],
                    beforeClose: function () {
                        return passwordSet;
                    },
                });
            }

            $('#newPasswordDialog [name="password"]').on(
                'input keyUp change',
                validatePassword
            );

            // Due to a race condition, we need to wait a bit before showing the dialog
            setTimeout(showNewPasswordDialog, 250);
        });
    } else if (window.isMissingAccountAdmin) {
        window.isMissingAccountAdmin = false;
        const missingAccountAdminCallback = function () {
            if (window.isJustLoggedIn) {
                window.isJustLoggedIn = false;
            }
        };
        $(window).one('tlxLoadPageContentDone', function () {
            tlxAlert(
                getMessage(
                    'text_account_missing_account_admin',
                    window.loginEmployeeId
                ),
                missingAccountAdminCallback
            );
        });
    } else if (window.isJustLoggedIn) {
        window.isJustLoggedIn = false;
    }

    $(window).on('tlxLoadPageContentDone', function () {
        renderAccountantVideo('AccountantVideoContainer');
    });
})();
