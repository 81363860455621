import { getURL } from '@Page/LogisticsV2/utils/functions';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { dateUtil } from '../../../../../../../js/modules/date';
import { ApiURL } from '@Page/Goldshark/API/url';

export function getChangePriceProps(values: {
    [k: string]: FormDataEntryValue;
}) {
    const changePricePurchasePercentInput =
        values['changePricePurchasePercentInput'];
    if (
        changePricePurchasePercentInput &&
        changePricePurchasePercentInput.toString().length > 0
    ) {
        return {
            priceType: 'PURCHASE',
            type: 'PERCENTAGE',
            value: changePricePurchasePercentInput,
        };
    }
    const changePricePurchaseValueInput =
        values['changePricePurchaseValueInput'];
    if (
        changePricePurchaseValueInput &&
        changePricePurchaseValueInput.toString().length > 0
    ) {
        return {
            priceType: 'PURCHASE',
            type: 'FIXED',
            value: changePricePurchaseValueInput,
        };
    }
    const changePriceSalesPercentInput = values['changePriceSalesPercentInput'];
    if (
        changePriceSalesPercentInput &&
        changePriceSalesPercentInput.toString().length > 0
    ) {
        return {
            priceType: 'SELLING',
            type: 'PERCENTAGE',
            value: changePriceSalesPercentInput,
        };
    }
    const changePriceSalesValueInput = values['changePriceSalesValueInput'];
    if (
        changePriceSalesValueInput &&
        changePriceSalesValueInput.toString().length > 0
    ) {
        return {
            priceType: 'SELLING',
            type: 'FIXED',
            value: changePriceSalesValueInput,
        };
    }

    return {
        priceType: '',
        type: 'SUPPLIER',
        value: '',
    };
}

export function getChangePriceInput(
    input: HTMLInputElement | HTMLSelectElement
) {
    if (input.name === 'changePricePurchaseSupplierRadio') {
        return { type: 'mainSupplier', value: '' };
    }
    if (
        input.name === 'changePricePurchasePercentInput' ||
        input.name === 'changePriceSalesPercentInput'
    ) {
        return { type: 'percent', value: input.value };
    }
    if (
        input.name === 'changePricePurchaseValueInput' ||
        input.name === 'changePriceSalesValueInput'
    ) {
        return { type: 'value', value: input.value };
    }
    return null;
}

export function generateURL(field: string | undefined) {
    if ((moduleLogistics || moduleLogisticsLight) && !moduleRackbeat) {
        if (field === 'availableStock') {
            return getURL(
                ApiURL.PRODUCT_GET_BY_ID,
                mapObjectToURLSearchParams({
                    fields: 'id,availableStock',
                })
            );
        }

        if (field === 'stockOfGoods') {
            return getURL(
                ApiURL.PRODUCT_GET_BY_ID,
                mapObjectToURLSearchParams({
                    fields: 'id,stockOfGoods',
                })
            );
        }

        if (!isLocationEnabled && field === 'inventories') {
            return getURL(
                ApiUrl.INVENTORY_BY_PRODUCTS,
                mapObjectToURLSearchParams({
                    fields: 'product(id),stock(inventoryId,inventory,closingStock)',
                    dateFrom: dateUtil.formatDate(
                        dateUtil.addYears(new Date(), -7),
                        'yyyy-MM-dd'
                    ),
                    dateTo: dateUtil.formatDate(new Date(), 'yyyy-MM-dd'),
                })
            );
        }

        if (isLocationEnabled && field === 'inventoryLocations') {
            return getURL(
                `${ApiUrl.PRODUCT_INVENTORY_LOCATION}/getInventoryLocationsByProductIds`,
                mapObjectToURLSearchParams({
                    fields: '*,inventory(id,displayName),product(id),inventoryLocation(id,displayName)',
                })
            );
        }
    }
    return '';
}
