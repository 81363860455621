import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import {
    ArrowBackIcon,
    ArrowForwardIcon,
    AutomatedIcon,
    Button,
    KebabIcon,
    OpenNewIcon,
    PopupMenu,
    PopupMenuItemLink,
    Tooltip,
    TooltipContent,
    TooltipTrigger,
    usePopupMenu,
    WarningIcon,
} from '@tlx/atlas';
import { ReactNode } from 'react';
import { OneIcon, ThreeIcon, TwoIcon } from './icons';
import { UserFeedback } from '@Page/LogisticsV2/components/UserFeedback/UserFeedback';
import { stepTitles } from './utils';

export function ImportPageLayout({ children }: { children: ReactNode }) {
    return <div>{children}</div>;
}

export function ImportTitleSection({ step }: { step: string }) {
    const { openerButtonProps, popupMenuProps } = usePopupMenu();
    const buildUrl = addContextId('/execute/importProduct');
    return (
        <div className={'atl-flex atl-justify-between gs-header atl-mb-32'}>
            <div
                id="menuHeader"
                data-testid="header-title"
                className="useAtlasHeader"
            >
                <h1 className="clip">{getMessage('text_import')}</h1>
            </div>
            <div className={'atl-flex atl-gap-8'}>
                <UserFeedback pageTitle={stepTitles[step]} />
                <>
                    <Button
                        data-testid="product-overview-top-actions-button"
                        variant="icon"
                        {...openerButtonProps}
                    >
                        <KebabIcon />
                    </Button>
                    <PopupMenu {...popupMenuProps}>
                        <PopupMenuItemLink
                            onClick={() => (window.location.href = buildUrl)}
                        >
                            <OpenNewIcon /> {getMessage('text_old_page')}
                        </PopupMenuItemLink>
                    </PopupMenu>
                </>
            </div>
        </div>
    );
}

export function StepContainer({
    id,
    activeStep,
    children,
}: {
    id: string;
    activeStep: string;
    children: ReactNode;
}) {
    return <>{id === activeStep ? children : null}</>;
}

export function StepDescriptionContainer({
    children,
}: {
    children: ReactNode;
}) {
    return (
        <div className="atl-flex atl-flex-col atl-gap-24 atl-border atl-border-grey-20 atl-bg-white atl-p-16 atl-rounded">
            {children}
        </div>
    );
}

export function AutomationDescription() {
    return (
        <div className="atl-flex atl-bg-purple-5 atl-items-center atl-gap-8 atl-p-8">
            <AutomatedIcon className="atl-text-purple-100" />{' '}
            {getMessage('text_import_mapping_automation')}
        </div>
    );
}

export function StepOneDescription() {
    return (
        <div className="atl-flex atl-gap-12">
            <div>
                <OneIcon />
            </div>
            <div className="atl-flex atl-flex-col atl-justify-center">
                <div className="atl-text-lg atl-font-medium atl-text-grey-120">
                    {getMessage('text_content')}
                </div>
                <div>{getMessage('text_import_mapping')}</div>
            </div>
        </div>
    );
}

export function StepTwoDescription() {
    return (
        <div className="atl-flex atl-gap-12">
            <div>
                <TwoIcon />
            </div>
            <div className="atl-flex atl-flex-col atl-justify-center">
                <div className="atl-text-lg atl-font-medium atl-text-grey-120">
                    {getMessage('text_validation')}
                </div>
                <div>{getMessage('text_import_validate')}</div>
            </div>
        </div>
    );
}

export function StepThreeDescription() {
    return (
        <div className="atl-flex atl-gap-12">
            <div>
                <ThreeIcon />
            </div>
            <div className="atl-flex atl-flex-col atl-justify-center">
                <div className="atl-text-lg atl-font-medium atl-text-grey-120">
                    {getMessage('text_result')}
                </div>
                <div>{getMessage('text_import_result')}</div>
            </div>
        </div>
    );
}

export function ActionBar({
    onBack,
    onCancel,
    onNext,
    onSubmit,
}: {
    onBack?: () => void;
    onCancel?: () => void;
    onNext?: () => void;
    onSubmit?: () => void;
}) {
    return (
        <div className="atl-flex atl-justify-between">
            <div>
                {onBack && (
                    <Button
                        data-testid="import-back-button"
                        variant="secondary"
                        onClick={onBack}
                    >
                        <ArrowBackIcon />
                        {getMessage('button_back')}
                    </Button>
                )}
            </div>
            <div className="atl-flex atl-gap-8">
                {onCancel && (
                    <Button
                        data-testid="import-cancel-button"
                        variant="tertiary"
                        onClick={() => onCancel()}
                    >
                        {getMessage('button_cancel')}
                    </Button>
                )}
                {onNext && (
                    <Button
                        data-testid="import-next-button"
                        variant="primary"
                        onClick={() => onNext()}
                    >
                        {getMessage('text_next')}
                        <ArrowForwardIcon />
                    </Button>
                )}
                {onSubmit && (
                    <Button
                        data-testid="import-start-button"
                        variant="primary"
                        onClick={() => onSubmit()}
                    >
                        {getMessage('text_start_import')}
                    </Button>
                )}
            </div>
        </div>
    );
}

export function RowError({ errorMessage }: { errorMessage: string }) {
    return (
        <Tooltip>
            <TooltipTrigger>
                <Button
                    variant={'icon'}
                    data-testid="atl-tooltip-button"
                    className="atl-pl-0"
                    aria-label={'tooltip-button-row-error'}
                >
                    <WarningIcon className="atl-text-red-100" />
                </Button>
            </TooltipTrigger>
            <TooltipContent>{errorMessage}</TooltipContent>
        </Tooltip>
    );
}

export function DeleteFileWarning({
    onConfirm,
    onCancel,
}: {
    onConfirm: () => void;
    onCancel: () => void;
}) {
    return (
        <LogisticsModal
            title={getMessage('text_warning')}
            svgIcon="warning"
            onConfirm={onConfirm}
            onCancel={onCancel}
        >
            {getMessage('text_delete_import_warning')}
        </LogisticsModal>
    );
}
