import { useInvokeAPI } from '@Page/Goldshark/API/api';
import {
    LogisticsSettingsAPIModel,
    SupplierAPIModel,
} from '@Page/Goldshark/API/interface';
import { ApiURL } from '@Page/Goldshark/API/url';
import { DEFAULT_SUPPLIER_FIELDS } from '@Page/Goldshark/Utils/constant';
export function useSupplierAPIData(supplierId?: number, fields?: string) {
    const url = supplierId ? `${ApiURL.SUPPLIER}/${supplierId}` : null;
    return useInvokeAPI<SupplierAPIModel>(url, {
        fields: fields ?? DEFAULT_SUPPLIER_FIELDS,
    });
}
export function useSuggestedProductNumber(): string | undefined {
    const { data: logisticsSettings } = useInvokeAPI<LogisticsSettingsAPIModel>(
        ApiURL.LOGISTICS_SETTINGS
    );
    return logisticsSettings?.moduleSuggestedProductNumber
        ? logisticsSettings?.suggestedProductNumber
        : '';
}
