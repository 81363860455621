import React, { useEffect, useState } from 'react';
import { ResponseWrapper } from '../../../hooks/fetch/types';
import './SecureCompanyRepresentativeIdentification.css';
import {
    Alert,
    AlertContent,
    AlertDescription,
    AlertHeader,
    Button,
    ModalContent,
    ModalFooter,
    ModalTitle,
} from '@tlx/atlas';
import { useCompanyVerificationStatus } from '@Page/CompanyVerificationFlow/companyVerificationAPI';
import { KycFeedbackBox } from '@Page/CompanyVerificationFlow/KycFeedbackBox/KycFeedbackBox';
import { KycFeedbackBoxLoading } from '@Page/CompanyVerificationFlow/KycFeedbackBox/KycFeedbackBoxLoading';
import { createAPIRequest, createAPIResponse } from '@tlx/astro-shared';

type UpdateCurrentUserSignatureStatusReturn = {
    signatureStatus: boolean;
    companyStatus: boolean;
};

function useUpdateCurrentUserSignatureStatus() {
    const [data, setData] = useState<UpdateCurrentUserSignatureStatusReturn>({
        signatureStatus: false,
        companyStatus: false,
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<Error | undefined>(undefined);
    useEffect(() => {
        updateCurrentUserSignatureStatus()
            .then((result) => {
                setData(result);
            })
            .catch((e) => {
                setError(e);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return { data, isLoading, error };
}

async function updateCurrentUserSignatureStatus() {
    const url = new URL(
        `/v2/signatureCombination/:updateCurrentUserSignatureStatus`,
        window.location.origin
    );

    const request = createAPIRequest(url.href, {
        method: 'PUT',
    });
    const response = await window.fetch(request);
    const data = await createAPIResponse<
        ResponseWrapper<UpdateCurrentUserSignatureStatusReturn>
    >(request, response);
    return data?.value;
}

export function ConnectedSecureCompanyRepresentativeIdentification({
    closeModal,
}: {
    closeModal: () => void;
}) {
    const { data, isLoading, error } = useUpdateCurrentUserSignatureStatus();
    const { mutateCompanyVerificationStatus } = useCompanyVerificationStatus();

    return (
        <SecureCompanyRepresentativeIdentification
            status={data}
            isLoading={isLoading}
            error={error}
            onCompanyVerified={mutateCompanyVerificationStatus}
            closeModal={closeModal}
        />
    );
}

export function SecureCompanyRepresentativeIdentification({
    status,
    isLoading,
    onCompanyVerified,
    closeModal,
}: {
    status: UpdateCurrentUserSignatureStatusReturn;
    isLoading: boolean;
    onCompanyVerified?: () => void;
    error?: Error;
    closeModal: () => void;
}) {
    useEffect(() => {
        if (status?.companyStatus === true && onCompanyVerified) {
            onCompanyVerified?.();
        }
    }, [onCompanyVerified, status?.companyStatus]);

    if (isLoading || !status) {
        return <KycFeedbackBoxLoading />;
    } else {
        return (
            <>
                {status.signatureStatus ? (
                    <KycFeedbackBox
                        isCompanyVerified={status.companyStatus}
                        closeModal={closeModal}
                    />
                ) : (
                    // error case
                    <>
                        <ModalTitle>
                            {getMessage('text_verify_company')}
                        </ModalTitle>
                        <ModalContent className="atl-py-8">
                            <Alert variant="error">
                                <AlertContent>
                                    <AlertHeader as="h4">
                                        {getMessage(
                                            'text_kyc_missing_signing_rights'
                                        )}
                                    </AlertHeader>
                                    <AlertDescription>
                                        {getMessage(
                                            'text_kyc_user_cant_sign',
                                            window.segmentationData?.companyName
                                        )}
                                    </AlertDescription>
                                </AlertContent>
                            </Alert>
                        </ModalContent>
                        <ModalFooter className="atl-pb-32 atl-pt-0">
                            <Button
                                className="atl-bg-blue-10"
                                data-testid="cancel"
                                data-trackingid="text_close_modal"
                                variant="tertiary"
                                onClick={() => {
                                    closeModal();
                                }}
                            >
                                {getMessage('button_close')}
                            </Button>
                        </ModalFooter>
                    </>
                )}
            </>
        );
    }
}
