import { MutableRefObject, useEffect, useRef } from 'react';

export function useLoadMoreDataRef<T extends HTMLElement>(
    loadMore: () => void,
    { root, rootMargin, threshold }: IntersectionObserverInit = {}
): MutableRefObject<T | null> {
    const targetRef = useRef<T | null>(null);
    useEffect(() => {
        const target = targetRef.current;
        if (target === null) {
            return;
        }
        function onIntersection(entries: IntersectionObserverEntry[]) {
            if (entries[0].isIntersecting) {
                loadMore();
            }
        }
        const observer = new IntersectionObserver(onIntersection, {
            root,
            rootMargin,
            threshold,
        });
        observer.observe(target);
        return () => {
            observer.unobserve(target);
        };
    }, [root, rootMargin, threshold, loadMore]);
    return targetRef;
}
