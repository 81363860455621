import { invokeAPI } from '@Page/LogisticsV2/hooks/api';
import { ApiUrl, MethodType, SortingType } from '@Page/LogisticsV2/utils/enums';
import {
    ALL_LOCATIONS,
    ALL_PRODUCT_GROUPS,
    ProductLineDTO,
    ProductLineStatus,
    STOCKTAKING_FIELDS,
} from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { getIdFromUrl, getURL } from '@Page/LogisticsV2/utils/functions';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { StocktakingDTO } from '@Page/LogisticsV2/types/dtoTypes';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import { KeyedMutator } from 'swr/_internal';
import { ListResponse } from '@tlx/astro-shared';

export function doEmptyWarehouse(stocktakingId?: number) {
    if (!stocktakingId) {
        throw new Error('Stocktaking ID is required');
    }
    return invokeAPI(
        `${ApiUrl.INVENTORY_STOCKTAKING}/${stocktakingId}/:emptyWarehouse`,
        MethodType.PUT
    );
}

export function doDeleteStocktaking(stocktakingId?: number) {
    if (!stocktakingId) {
        throw new Error('Stocktaking ID is required');
    }
    return invokeAPI(
        ApiUrl.INVENTORY_STOCKTAKING + `/${stocktakingId}`,
        MethodType.DELETE
    );
}

export function doCompleteStocktaking(stocktakingId?: number) {
    if (!stocktakingId) {
        throw new Error('Stocktaking ID is required');
    }
    return invokeAPI(
        `${ApiUrl.INVENTORY_STOCKTAKING}/${stocktakingId}/:complete`,
        MethodType.PUT
    );
}

export function doChangeDate(date: string, stocktakingId?: number) {
    if (!stocktakingId) {
        throw new Error('Stocktaking ID is required');
    }
    return invokeAPI(
        `${ApiUrl.INVENTORY_STOCKTAKING}/${stocktakingId}/:changeDate`,
        MethodType.PUT,
        date
    );
}

export function doUpdateLine(
    productLineId: number,
    count?: string,
    comment?: string
) {
    return invokeAPI(
        `${ApiUrl.INVENTORY_STOCKTAKING_PRODUCTLINE}/${productLineId}`,
        MethodType.PUT,
        JSON.stringify({
            id: productLineId,
            count: count,
            comment: comment,
        })
    );
}

export function doCountLine(value: number) {
    return invokeAPI(
        `${ApiUrl.INVENTORY_STOCKTAKING_PRODUCTLINE}/${value}/:count`,
        MethodType.PUT
    );
}

export function doUnCountLine(value: number) {
    return invokeAPI(
        `${ApiUrl.INVENTORY_STOCKTAKING_PRODUCTLINE}/${value}/:uncount`,
        MethodType.PUT
    );
}

export function doChangeLocation(
    productLineId: number,
    warehouseLocationId: string
) {
    return invokeAPI(
        ApiUrl.INVENTORY_STOCKTAKING_PRODUCTLINE +
            `/${productLineId}/:changeLocation?warehouseLocationId=${warehouseLocationId}`,
        MethodType.PUT
    );
}

export async function doExport(
    stocktaking: StocktakingDTO,
    status: ProductLineStatus,
    sorting: SortingType,
    columns: string[],
    onChange: (value: boolean) => void
) {
    onChange(true);
    const locationIds = getIdFromUrl('location');
    const productGroupIds = getIdFromUrl('productGroup');
    const queryLine = getIdFromUrl('query');
    const { data, error } = await invokeAPI(
        addContextId(
            getURL(
                ApiUrl.INVENTORY_STOCKTAKING_PRODUCTLINE_EXPORT,
                mapObjectToURLSearchParams({
                    inventoryId: stocktaking.id,
                    locationIds:
                        locationIds !== '' && locationIds !== null
                            ? locationIds
                            : ALL_LOCATIONS,
                    productGroupIds:
                        productGroupIds !== '' && productGroupIds !== null
                            ? productGroupIds
                            : ALL_PRODUCT_GROUPS,
                    productLineStatus: status,
                    query: queryLine ?? '',
                    sorting: sorting,
                    columns: columns,
                })
            ) ?? ''
        ),
        MethodType.GET
    );
    if (data) {
        const blob = await data.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        // the filename you want
        a.download =
            getMessage('text_inventory') +
            '-' +
            stocktaking.inventory.name +
            '-' +
            stocktaking.date +
            '.xlsx';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        onChange(false);
    }
    if (error) {
        onChange(false);
        postValidationMessages(error);
    }
}

export function doCreateLines(newProductLines: ProductLineDTO[]) {
    return invokeAPI(
        `${ApiUrl.INVENTORY_STOCKTAKING_PRODUCTLINE_LIST}?fields=${STOCKTAKING_FIELDS}`,
        MethodType.POST,
        JSON.stringify(newProductLines)
    );
}

export function doScanLine(ean: string) {
    const stocktakingId = getIdFromUrl('inventoryId');
    return invokeAPI(
        getURL(
            `${ApiUrl.INVENTORY_STOCKTAKING_PRODUCTLINE}/:scan` ?? '',
            mapObjectToURLSearchParams({
                fields: '*,product(id,number,nrfNumber,elNumber,ean,name,costExcludingVatCurrency,stockOfGoods),location(*),counter(firstName,lastName)',
                stocktakingId: stocktakingId,
                ean: ean,
            })
        ) ?? '',
        MethodType.PUT
    );
}

export async function doAnimateRow(
    refresh: KeyedMutator<ListResponse<ProductLineDTO>[]>,
    productlineId?: number
) {
    if (!productlineId) {
        throw new Error('Productline ID is required');
    }
    const rowElement = document.querySelector(
        `.row-${productlineId}`
    ) as HTMLElement;
    if (rowElement) {
        rowElement.style.animation =
            'goldshark-stocktaking-details-row-animated 1s';
        rowElement.addEventListener(
            'animationend',
            async () => {
                await refresh();
                rowElement.style.animation = '';
            },
            { once: true }
        );
    }
}

export function getIsFiltered() {
    return (
        getIdFromUrl('query') !== null ||
        getIdFromUrl('productGroups') !== null ||
        getIdFromUrl('location') !== null
    );
}
