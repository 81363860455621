import { Form } from '@Page/LogisticsV2/components/Form/Form';
import { getSvgIcon } from '@Page/LogisticsV2/utils/svgIcons';
import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalTitle,
} from '@tlx/atlas';
import { useState } from 'react';
import { ModalBatchActionButtons, ModalWarning } from '../components';
import {
    ChangeProductDetailsContent,
    ChangeProductDetailsDropdowns,
    ChangeProductDetailsStandardSupplier,
    ChangeProductDetailsStatus,
    ChangeProductDetailsStockItem,
} from './ChangeProductDetailsContent';
import { PRODUCTS_WITHOUT_SUPPLIER } from '@Page/LogisticsV2/pages/Product/overview/utils';

export function ChangeProductDetailsModal({
    suppliers,
    totalLines,
    onClose,
    onChangeProductDetails,
}: {
    suppliers: string[];
    totalLines: number;
    onClose: () => void;
    onChangeProductDetails: (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => void;
}) {
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [showEditProductDetailsWarning, setShowEditProductDetailsWarning] =
        useState(false);

    const handleOnSubmit = (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        onChangeProductDetails(formElement, values);
        onClose();
    };

    const handleChangeProductDetails = (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        if (Object.values(values).every((value) => value === 'undefined')) {
            setIsNextDisabled(true);
        } else {
            setIsNextDisabled(false);
        }
    };

    const isSupplierProduct =
        !suppliers.includes('') &&
        !suppliers.includes(PRODUCTS_WITHOUT_SUPPLIER);

    return (
        <Modal
            open={true}
            onClose={() => {
                onClose();
                setShowEditProductDetailsWarning(false);
            }}
        >
            <ModalContent>
                {getSvgIcon('edit')}
                <ModalTitle>
                    {getMessage('text_product_change_info')}
                </ModalTitle>
                <ModalCloseButton />
                <Form
                    formId="change-product-details-form"
                    onSubmit={handleOnSubmit}
                    onChangeForm={handleChangeProductDetails}
                >
                    <ChangeProductDetailsContent>
                        <ChangeProductDetailsDropdowns
                            isSupplierProduct={isSupplierProduct}
                        />
                        <ChangeProductDetailsStatus />
                        {(moduleLogistics || moduleLogisticsLight) &&
                        !isSupplierProduct ? (
                            <ChangeProductDetailsStockItem />
                        ) : moduleLogistics &&
                          isSupplierProduct &&
                          suppliers.length === 1 ? (
                            <ChangeProductDetailsStandardSupplier />
                        ) : (
                            <></>
                        )}
                        {showEditProductDetailsWarning && (
                            <ModalWarning
                                text={getMessage(
                                    'text_change_product_information_warning',
                                    totalLines
                                )}
                            />
                        )}
                    </ChangeProductDetailsContent>
                </Form>
            </ModalContent>
            <ModalFooter>
                <ModalBatchActionButtons
                    formId="change-product-details-form"
                    isDisabled={isNextDisabled}
                    showButton={showEditProductDetailsWarning}
                    onNext={() => setShowEditProductDetailsWarning(true)}
                    onBack={() => setShowEditProductDetailsWarning(false)}
                    onCancel={() => {
                        onClose();
                        setShowEditProductDetailsWarning(false);
                    }}
                />
            </ModalFooter>
        </Modal>
    );
}
