import {
    AsyncDropdownOptions,
    DisplayOptions,
    DropdownFilter,
    FilterItem,
    RadioGroupFilter,
    SearchFilter,
    useSearchParam,
} from '@tlx/astro-shared';
import { ALL_PRODUCT_GROUPS } from '@Page/Goldshark/Utils/constant';
import { DropdownURL } from '@Page/Goldshark/API/url';
import React, { cloneElement, useEffect } from 'react';
import {
    CancelButton,
    ConfirmButton,
    CustomExclusiveDropdownOption,
    DivLayout,
    FormLayout,
    SubmitButton,
} from '@Page/Goldshark/Components/LayoutComponents';
import {
    Button,
    ModalFooter,
    ModalTitle,
    Row,
    SelectRowCell,
} from '@tlx/atlas';
import { useTableFilters } from '@Page/Goldshark/Hooks/useTableHooks';
import { rowCellRender } from '@Page/Goldshark/Utils/mapData';
import {
    ActionCellOpenProduct,
    TableEmptyState,
    TableLoadingRow,
    TableLoadMoreRow,
} from '@Page/Goldshark/Components/TableComponents';
import { removeUrlParams } from '@Page/Goldshark/Utils/function';
import useLocalStorage from '../../../../../util/storage';
import { useProductSelectorModalAPIData } from '@Page/Goldshark/Components/Modal/ProductSelectorModal/useProductSelectorModal';
import { UserFeedback2 } from '@Page/LogisticsV2/components/UserFeedback/UserFeedback';

export function ProductSelectorTableFilters({
    isWholesaler = false,
}: {
    isWholesaler?: boolean;
}) {
    useEffect(() => {
        const inputElement = document.getElementById('product-selector-search');
        if (inputElement) {
            inputElement.focus();
        }
    }, []);

    return (
        <FormLayout className={'atl-flex atl-gap-8 atl-mr-auto atl-flex-wrap'}>
            <SearchFilter
                data-testid={'product-selector-search'}
                id={'product-selector-search'}
                label={getMessage('text_search')}
                name={'query'}
                placeholder={getMessage('text_search')}
                className={'product-selector-search-filter'}
                quick
            />
            {moduleLogistics && !isWholesaler && (
                <DropdownFilter
                    name="productGroups"
                    label=""
                    quick
                    multiple
                    defaultValue={[ALL_PRODUCT_GROUPS]}
                    defaultDisplayName={getMessage('text_product_group_all')}
                    className={'product-selector-groups-filter'}
                >
                    <AsyncDropdownOptions url={DropdownURL.PRODUCT_GROUP}>
                        <CustomExclusiveDropdownOption
                            displayName={getMessage('text_product_group_all')}
                            value={ALL_PRODUCT_GROUPS}
                        />
                    </AsyncDropdownOptions>
                </DropdownFilter>
            )}
            {!isWholesaler && (
                <RadioGroupFilter
                    name="action"
                    label=""
                    quick
                    defaultValue={'select'}
                >
                    <FilterItem value="select">
                        {getMessage('text_products_select')}
                    </FilterItem>
                    <FilterItem value="create">
                        {getMessage('text_new_product')}
                    </FilterItem>
                </RadioGroupFilter>
            )}
        </FormLayout>
    );
}

type ProductSelectorFooterProps = {
    formId: string;
    isDisabled: boolean;
    onConfirm: () => void;
    onClose: () => void;
    isStocktaking?: boolean;
};
export function ProductSelectorFooter(props: ProductSelectorFooterProps) {
    const action = useSearchParam('action');
    const display = useLocalStorage(
        `astro-shared-settings-supplier-connection-selector-display-${window.loginEmployeeId}`,
        DisplayOptions.DETAILED
    )[0];
    return (
        <ModalFooter>
            <DivLayout className={'atl-flex atl-gap-8'}>
                {action === 'create' && <SubmitButton formId={props.formId} />}
                {action !== 'create' && (
                    <ConfirmButton
                        dataTestId={
                            'supplier-connection-selector-confirm-button'
                        }
                        onClick={props.onConfirm}
                        isDisabled={props.isDisabled}
                    />
                )}
                <CancelButton
                    dataTestId={'supplier-connection-selector-cancel-button'}
                    onClick={props.onClose}
                />
                <DivLayout className={'atl-ml-auto'}>
                    <UserFeedback2
                        pageTitle={
                            props.isStocktaking
                                ? 'product-selector-stocktaking'
                                : action === 'create'
                                ? 'product-selector-create'
                                : display === 'detailed'
                                ? 'product-selector-select-detailed'
                                : 'product-selector-select'
                        }
                    />
                </DivLayout>
            </DivLayout>
        </ModalFooter>
    );
}

type SupplierConnectionTableBodyProps = {
    apiResponse: ReturnType<typeof useProductSelectorModalAPIData>;
    columns: string[];
    getCount?: (rowId: string) => string;
    onChangeCount?: (value: string, rowId: string) => void;
};
export function ProductSelectorModalTableBody(
    props: SupplierConnectionTableBodyProps
) {
    const filters = useTableFilters();
    const isFiltered =
        filters.debouncedQuery !== '' || !filters.productGroups.includes('');

    return (
        <>
            {props.apiResponse.data.map((row, index) => (
                <Row
                    id={row.id.toString()}
                    key={`${row.id}-${index}`}
                    className={'product-selector-row'}
                >
                    <SelectRowCell />
                    {props.columns.map((column, index) =>
                        cloneElement(
                            rowCellRender[column]({
                                row,
                                count: props.getCount?.(row.id.toString()),
                                handleOnChangeCount: (value: string) =>
                                    props.onChangeCount?.(
                                        value,
                                        row.id.toString()
                                    ),
                            }),
                            { key: `${column}-${row.id}-${index}` }
                        )
                    )}
                    <ActionCellOpenProduct productId={row.id} />
                </Row>
            ))}
            {props.apiResponse.hasMore && (
                <TableLoadMoreRow
                    columns={props.columns.length + 2}
                    loadMoreRef={props.apiResponse.loadMoreRef}
                />
            )}
            {props.apiResponse.isLoading && (
                <TableLoadingRow columns={props.columns.length + 2} />
            )}
            {props.apiResponse.data?.length === 0 &&
                !props.apiResponse.isLoading && (
                    <TableEmptyState
                        icon={isFiltered ? 'search' : 'general'}
                        title={
                            isFiltered
                                ? getMessage('text_no_match')
                                : getMessage('text_no_products_found')
                        }
                        description={
                            isFiltered
                                ? getMessage('text_nothing_found_description')
                                : ''
                        }
                    >
                        {isFiltered && (
                            <Button
                                data-testid="resetFilter"
                                variant="secondary"
                                onClick={() =>
                                    removeUrlParams([
                                        'query',
                                        'action',
                                        'productGroups',
                                    ])
                                }
                            >
                                {getMessage('text_reset_filter')}
                            </Button>
                        )}
                    </TableEmptyState>
                )}
        </>
    );
}
export function ProductSelectorModalTitle({
    supplierName,
}: {
    supplierName?: string;
}) {
    return (
        <ModalTitle>
            {supplierName
                ? getMessage('text_products_for_supplier') + ' ' + supplierName
                : getMessage('text_products')}
        </ModalTitle>
    );
}
