import { postNotificationMessage } from '@General/LegacyNotification';
import {
    invokeAPI,
    useFetchAll,
    useInfiniteScrollData,
} from '@Page/LogisticsV2/hooks/api';
import useSessionStorage from '@Page/LogisticsV2/hooks/useSessionStorage';
import {
    InventoriesDTO,
    PRODUCT_OVERVIEW_NON_LOGISTICS_FIELDS,
    ProductDTO,
    ProductInventoryLocationDTO,
} from '@Page/LogisticsV2/pages/Product/overview/types';
import {
    getExportFields,
    mapInventoryDataToProductDTO,
    mapInventoryLocationsToProductDTO,
    PRODUCTS_WITHOUT_STANDARD_SUPPLIER,
    PRODUCTS_WITHOUT_SUPPLIER,
} from '@Page/LogisticsV2/pages/Product/overview/utils';
import { ApiUrl, MethodType, SortingType } from '@Page/LogisticsV2/utils/enums';
import {
    getIdFromUrl,
    getURL,
    getValidationMessagesFromResponse,
} from '@Page/LogisticsV2/utils/functions';
import { mapObjectToURLSearchParams } from '@Page/LogisticsV2/utils/mappers';
import { useTableColumnsSetting } from '@tlx/astro-shared';
import { useEffect, useState } from 'react';
import { getProductOverviewDisplayOptionsName } from '@Page/LogisticsV2/pages/Product/overview/DisplayOptions/ProductOverviewDisplayOptions';
import {
    defaultColumnsForProductOverviewWithSupplier,
    getDefaultColumnsInProductOverview,
} from '@Page/LogisticsV2/pages/Product/overview/DisplayOptions/utils';
import { generateURL, getChangePriceProps } from './functions';
import { useProductOverviewFilter } from './useProductOverviewFilter';
import { useDebouncedQuery } from '@Page/LogisticsV2/hooks/useDebouncedQuery';

export function useProductOverview() {
    const [sorting, setSorting] = useSessionStorage<SortingType>(
        'goldshark_productOverview_sorting',
        SortingType.NAME_ASCENDING
    );
    const [isExporting, setIsExporting] = useState(false);
    const enabledColumnsForProductOverview = useTableColumnsSetting(
        getProductOverviewDisplayOptionsName(),
        getDefaultColumnsInProductOverview()
    );

    const enabledColumnsForProductOverviewWithSupplier = useTableColumnsSetting(
        `columns-product-overview-2-with-supplier-${window.loginEmployeeId}`,
        defaultColumnsForProductOverviewWithSupplier
    );

    const filters = useProductOverviewFilter();

    const [selectedIds, setSelectedIds] = useState<Array<{ id: number }>>([]);
    const [notDeletableIds, setNotDeletableIds] = useState<
        Array<{ id: number }>
    >([]);

    const customerId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;
    const supplierFilter = getIdFromUrl('supplier');
    const inventoryFilter = getIdFromUrl('inventory');
    const withoutMainSupplier = getIdFromUrl('withoutMainSupplier');
    const statusFilter = getIdFromUrl('status');
    const query = getIdFromUrl('query');
    const productGroupsFilter = getIdFromUrl('productGroups');
    const incomeAccountFilter = getIdFromUrl('incomeAccount');
    const productUnitFilter = getIdFromUrl('productUnit');
    const vatTypeFilter = getIdFromUrl('vatType');
    const isStockItemFilter = getIdFromUrl('stockItem');
    const [costCurrencyList, setCostCurrency] = useState<
        { id: number; costExcludingVatCurrency: number }[]
    >([]);

    const supplierId =
        customerId !== null
            ? customerId
            : supplierFilter?.split(',').length === 0
            ? undefined
            : supplierFilter;

    const isSupplierProduct =
        (supplierFilter !== null &&
            supplierFilter !== '' &&
            supplierFilter !== PRODUCTS_WITHOUT_SUPPLIER) ||
        customerId !== null;

    const columns = isSupplierProduct
        ? enabledColumnsForProductOverviewWithSupplier
        : enabledColumnsForProductOverview;

    const isInactive =
        statusFilter === null
            ? false
            : statusFilter?.split(',').length === 2
            ? undefined
            : statusFilter?.split(',').includes('inactive');

    const hasSupplierProductConnected =
        withoutMainSupplier !== null ? withoutMainSupplier : undefined;

    const debouncedQuery = useDebouncedQuery(query ?? '', 600);
    const [stockOfGoods, setStockOfGoods] = useState<ProductDTO[]>([]);
    const [availableStock, setAvailableStock] = useState<ProductDTO[]>([]);
    const [inventories, setInventories] = useState<InventoriesDTO[]>([]);
    const [inventoryLocations, setInventoryLocations] = useState<
        ProductInventoryLocationDTO[]
    >([]);

    const {
        data,
        error,
        isLoading,
        hasMore,
        loadMore,
        refresh: onRefresh,
    } = useInfiniteScrollData<ProductDTO>(
        ApiUrl.PRODUCT_QUERY_OVERVIEW,
        {
            fields:
                `${PRODUCT_OVERVIEW_NON_LOGISTICS_FIELDS}, ` +
                columns
                    .filter(
                        (item) =>
                            item !== 'warehouse' &&
                            item !== 'stockOfGoods' &&
                            item !== 'costCurrency' &&
                            item !== 'availableStock'
                    )
                    .join(','),
            sorting: sorting,
            isSupplierProduct: isSupplierProduct,
            isInactive: isInactive,
            inventoryId: inventoryFilter,
            query: debouncedQuery,
            supplierId:
                withoutMainSupplier !== null
                    ? PRODUCTS_WITHOUT_STANDARD_SUPPLIER
                    : supplierId,
            productGroupId: moduleLogistics ? productGroupsFilter : undefined,
            hasSupplierProductConnected: hasSupplierProductConnected,
            accountId: !supplierId ? incomeAccountFilter : undefined,
            vatTypeId: !supplierId ? vatTypeFilter : undefined,
            productUnitId: productUnitFilter,
            isStockItem: !supplierId ? isStockItemFilter : undefined,
        },
        async (data) => {
            if (
                supplierId !== null &&
                supplierId !== PRODUCTS_WITHOUT_SUPPLIER
            ) {
                const url = getURL(
                    ApiUrl.SUPPLIER_CONNECTION,
                    mapObjectToURLSearchParams({
                        fields: 'id,costExcludingVatCurrency',
                        vendorId: customerId ?? supplierId,
                        isInactive: isInactive,
                        query: debouncedQuery,
                    })
                );
                if (url) {
                    const result = await invokeAPI(url, MethodType.GET);
                    if (result.data) {
                        const costCurrencyList = await result.data.json();
                        setCostCurrency(costCurrencyList.values);
                    }
                }
            }
            const productIds = data.map((product) => {
                return { id: product.id };
            });

            if (productIds.length === 0) {
                return;
            }

            const stockOfGoodsURL = generateURL('stockOfGoods');
            if (stockOfGoodsURL) {
                const { data } = await invokeAPI(
                    stockOfGoodsURL,
                    MethodType.POST,
                    JSON.stringify(productIds)
                );
                if (data) {
                    const jsonData = await data.json();
                    setStockOfGoods((prevStockOfGoods) => [
                        ...prevStockOfGoods,
                        ...jsonData.values,
                    ]);
                }
            }

            const availableStockURL = generateURL('availableStock');
            if (availableStockURL) {
                const { data } = await invokeAPI(
                    availableStockURL,
                    MethodType.POST,
                    JSON.stringify(productIds)
                );
                if (data) {
                    const jsonData = await data.json();
                    setAvailableStock((prevAvailableStock) => [
                        ...prevAvailableStock,
                        ...jsonData.values,
                    ]);
                }
            }

            const inventoriesURL = generateURL('inventories');
            if (inventoriesURL) {
                const { data } = await invokeAPI(
                    inventoriesURL,
                    MethodType.POST,
                    JSON.stringify(productIds)
                );
                if (data) {
                    const jsonData = await data.json();
                    setInventories((prevInventories) => [
                        ...prevInventories,
                        ...jsonData.values,
                    ]);
                }
            }

            const inventoryLocationsURL = generateURL('inventoryLocations');
            if (inventoryLocationsURL) {
                const { data } = await invokeAPI(
                    inventoryLocationsURL,
                    MethodType.POST,
                    JSON.stringify(productIds)
                );
                if (data) {
                    const jsonData = await data.json();
                    setInventoryLocations((prevInventoryLocations) => [
                        ...prevInventoryLocations,
                        ...jsonData.values,
                    ]);
                }
            }
        },
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            revalidateAll: false,
            shouldRetryOnError: false,
            revalidateFirstPage: false,
        }
    );

    const {
        data: totalLines,
        isLoading: isTotalLinesLoading,
        mutate: onRefreshTotalLines,
    } = useFetchAll<ProductDTO[]>(
        ApiUrl.PRODUCT_QUERY_OVERVIEW,
        mapObjectToURLSearchParams({
            fields: 'id',
            sorting: sorting,
            isSupplierProduct: isSupplierProduct,
            isInactive: isInactive,
            inventoryId: inventoryFilter,
            query: debouncedQuery,
            supplierId:
                withoutMainSupplier !== null
                    ? PRODUCTS_WITHOUT_STANDARD_SUPPLIER
                    : supplierId,
            count: 10000,
            productGroupId: moduleLogistics ? productGroupsFilter : undefined,
            hasSupplierProductConnected: hasSupplierProductConnected,
            accountId: !supplierId ? incomeAccountFilter : undefined,
            vatTypeId: !supplierId ? vatTypeFilter : undefined,
            productUnitId: productUnitFilter,
            isStockItem: !supplierId ? isStockItemFilter : undefined,
        }),
        {
            revalidateIfStale: false,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
        }
    );

    const onChange = async () => {
        await onRefresh();
        await onRefreshTotalLines();
        setSelectedIds([]);
    };

    const handleSelectAllProducts = () => {
        if (totalLines) {
            setSelectedIds(totalLines?.map((product) => ({ id: product.id })));
        } else {
            setSelectedIds([]);
        }
    };

    const products = isLocationEnabled
        ? data?.map((product) =>
              mapInventoryLocationsToProductDTO(product, inventoryLocations)
          )
        : data?.map((product) =>
              mapInventoryDataToProductDTO(product, inventories)
          );

    async function handleExport() {
        const columnsForExport = columns
            .map((col) => (col === 'currency(code)' ? 'currency' : col))
            .map((col) => (col === 'account(displayName)' ? 'account' : col))
            .map((col) =>
                col === 'vatType(displayName)'
                    ? 'vatType, vatTypeDescription'
                    : col
            )
            .join(',');
        setIsExporting(true);

        const { data } = await invokeAPI(
            getURL(
                ApiUrl.PRODUCT_EXPORT,
                mapObjectToURLSearchParams({
                    fields: getExportFields(columns),
                    isSupplierProduct: isSupplierProduct,
                    query: debouncedQuery,
                    sorting: sorting,
                    isInactive: isInactive,
                    inventoryId: inventoryFilter,
                    supplierId:
                        withoutMainSupplier !== null
                            ? PRODUCTS_WITHOUT_STANDARD_SUPPLIER
                            : supplierId,
                    hasSupplierProductConnected: hasSupplierProductConnected,
                    columns: columnsForExport,
                    productGroupId: productGroupsFilter,
                    accountId: !supplierId ? incomeAccountFilter : undefined,
                    vatTypeId: !supplierId ? vatTypeFilter : undefined,
                    productUnitId: productUnitFilter,
                    isStockItem: !supplierId ? isStockItemFilter : undefined,
                })
            ) ?? '',
            MethodType.GET
        );
        if (data) {
            const currentUrl = window.location.href;
            const blob = await data.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = getMessage('text_products') + '.xlsx';
            document.body.appendChild(a);
            a.click();
            window.history.replaceState(null, '', currentUrl);
            setIsExporting(false);
        }
    }

    const exportInterceptor = async (event: TlxExportMenuExportEvent) => {
        if (event.detail.type === 'xls') {
            event.stopPropagation();
            await handleExport();
        }
    };

    useEffect(() => {
        window.addEventListener('tlx:export-menu:export', exportInterceptor, {
            capture: true,
        });
        return () => {
            window.removeEventListener(
                'tlx:export-menu:export',
                exportInterceptor,
                {
                    capture: true,
                }
            );
        };
    }, [exportInterceptor]);

    const handleDeleteProduct = async (id: number) => {
        const { error } = await invokeAPI(
            `${ApiUrl.PRODUCT}/${id}`,
            MethodType.DELETE
        );
        if (error === undefined) {
            postNotificationMessage(
                getMessage('text_product'),
                [
                    `${getMessage('text_action_completed')} ${getMessage(
                        'text_delete'
                    )}`,
                ],
                new Date()
            );
            await onRefresh();
        } else {
            getValidationMessagesFromResponse(error);
        }
    };

    const getNotDeletableProducts = async () => {
        const { data, error } = await invokeAPI(
            getURL(
                ApiUrl.PRODUCT_QUERY_NOT_DELETABLE_PRODUCTS,
                mapObjectToURLSearchParams({ fields: 'id' })
            ) ?? '',
            MethodType.POST,
            JSON.stringify(selectedIds)
        );
        if (data && !error) {
            const jsonData = await data.json();
            setNotDeletableIds(jsonData.values);
        }
    };

    const getProductsWithMainSupplier = async () => {
        const { data: productsWithMainSupplier } = await invokeAPI(
            '/v2/product/queryProductsWithPriceFromMainSupplier?fields=id',
            MethodType.POST,
            JSON.stringify(selectedIds)
        );

        const dataJson = await productsWithMainSupplier?.json();
        return dataJson?.values.map((item: { id: number }) => item.id);
    };

    const getProductsWithoutMainSupplier = async () => {
        const { data: productsWithMainSupplier } = await invokeAPI(
            '/v2/product/queryProductsWithoutMainSupplier?fields=id',
            MethodType.POST,
            JSON.stringify(selectedIds)
        );

        const dataJson = await productsWithMainSupplier?.json();
        return dataJson?.values.map((item: { id: number }) => item.id);
    };

    const handleOnSubmitPriceChange = async (
        formElement: HTMLFormElement,
        values: { [k: string]: FormDataEntryValue }
    ) => {
        const priceProps = getChangePriceProps(values);
        if (priceProps === null) {
            return;
        }
        let filteredIds = selectedIds;
        if (priceProps.priceType !== 'SELLING') {
            const productToCheck: number[] =
                priceProps.type === 'SUPPLIER'
                    ? await getProductsWithoutMainSupplier()
                    : await getProductsWithMainSupplier();
            filteredIds = selectedIds.filter(
                (item) => !productToCheck?.includes(item.id)
            );
        }
        await invokeAPI(
            priceProps.type === 'SUPPLIER'
                ? '/v2/product/:updatePriceFromMainSupplier'
                : getURL(
                      '/v2/product/productPrice/changePrices',
                      mapObjectToURLSearchParams({
                          priceType: priceProps.priceType,
                          type: priceProps.type,
                          value: priceProps.value,
                      })
                  ) ?? '',
            MethodType.PUT,
            JSON.stringify(filteredIds)
        );
        await onRefresh();
    };
    const handleOnNextPriceChange = async (
        type: string,
        isPurchasePrice: boolean
    ) => {
        if (!isPurchasePrice) {
            return selectedIds.length;
        }
        const productToCheck: number[] =
            type === 'mainSupplier'
                ? await getProductsWithoutMainSupplier()
                : await getProductsWithMainSupplier();
        const filteredIds = selectedIds.filter(
            (item) => !productToCheck?.includes(item.id)
        );
        return filteredIds.length;
    };
    window.app.runCES('logistics_product_overview');

    const productsWithStockOfGoods =
        products.map((product) => {
            const stockItem = stockOfGoods?.find(
                (item) => item.id === product.id
            );
            const availableStockItem = availableStock?.find(
                (item) => item.id === product.id
            );
            return {
                ...product,
                stockOfGoods: stockItem
                    ? stockItem.stockOfGoods
                    : product.stockOfGoods, // Fallback to existing value if not found
                availableStock: availableStockItem
                    ? availableStockItem.availableStock
                    : product.availableStock, // Fallback to existing value if not found
            };
        }) ?? [];

    const productsWithCostCurrency =
        products.map((product) => {
            const costCurrency = costCurrencyList.find(
                (item) => item.id === product.id
            );
            return {
                ...product,
                costCurrency: costCurrency?.costExcludingVatCurrency ?? 0,
            };
        }) ?? [];

    return {
        columns: columns,
        data:
            supplierId !== null && supplierId !== PRODUCTS_WITHOUT_SUPPLIER
                ? productsWithCostCurrency
                : productsWithStockOfGoods,
        error,
        filters,
        hasMore,
        isExporting,
        isLoading,
        isTotalLinesLoading,
        loadMore,
        notDeletableIds,
        selectedIds,
        sorting,
        totalLines: totalLines ?? [],
        getNotDeletableProducts,
        onChange,
        onChangePrice: handleOnSubmitPriceChange,
        onChangeSelectedIds: setSelectedIds,
        onChangeSorting: setSorting,
        onDelete: handleDeleteProduct,
        onExport: handleExport,
        onNext: handleOnNextPriceChange,
        onSelectAll: handleSelectAllProducts,
    };
}
