import React, { useEffect, useState } from 'react';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import {
    Alert,
    AlertContent,
    Dropdown,
    DropdownDrawer,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Input,
    Label,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalIllustration,
    ModalLayout,
    ModalSubtitle,
    ModalTitle,
    RadioGroup,
} from '@tlx/atlas';
import { ProductDiscountModalActions } from '@Page/LogisticsV2/pages/Product discount/modals/ProductDiscountModalsComponents';
import {
    doCopyDiscountToCustomers,
    checkCustomerHasDiscounts,
} from '@Page/LogisticsV2/pages/Product discount/overview/ProductDiscountAction';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import { getSvgIcon } from '@Page/LogisticsV2/utils/svgIcons';
import { CustomAsyncDropdownOptions } from '@Page/LogisticsV2/pages/Product discount/modals/CustomAsyncDropdownOptions';
import {
    DivLayout,
    FormLayout,
    ModalLoadingSpinner,
} from '@Page/Goldshark/Components/LayoutComponents';
import { postNotificationMessage } from '@General/LegacyNotification';

export function CopyDiscountModal({ onClose }: { onClose: () => void }) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
    const [keepExistingDiscount, setKeepExistingDiscount] = useState(true);
    const [noDiscountAvailable, setNoDiscountAvailable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const customerId = document.querySelector<HTMLInputElement>(
        '#customerIdForProductOverview'
    )?.value;

    useEffect(() => {
        if (customerId) {
            (async () => {
                const hasDiscounts = await checkCustomerHasDiscounts(
                    customerId
                );
                setNoDiscountAvailable(!hasDiscounts);
            })();
        }
    }, [customerId]);

    const handleOnSubmit = async () => {
        setIsLoading(true);
        try {
            await doCopyDiscountToCustomers(
                customerId,
                selectedCustomers,
                keepExistingDiscount
            );
            const castValue = window.location.hash.substring(1);
            if (castValue === 'discounts') {
                const alertElement = document.getElementById('discount-alert');
                if (alertElement) {
                    // Remove the hidden class and add visible class for fade-in effect
                    alertElement.classList.remove('atl-hidden');
                    alertElement.classList.add('custom-alert', 'visible');
                    const alertContent = alertElement.querySelector(
                        '.atl-alert__content'
                    );
                    if (alertContent) {
                        const numberOfCustomers = selectedCustomers.length;
                        const text = getMessage(
                            'text_discount_was_successfully_copied',
                            numberOfCustomers
                        );
                        alertContent.innerHTML = `<span> ${text} </span>`;
                    }
                    setTimeout(() => {
                        alertElement.classList.remove('visible'); // Fade out
                        setTimeout(() => {
                            alertElement.classList.add('atl-hidden');
                        }, 500);
                    }, 5000);
                }
            } else {
                postNotificationMessage('', [
                    getMessage(
                        'text_discount_was_successfully_copied',
                        selectedCustomers.length
                    ),
                ]);
            }
        } catch (error) {
            postValidationMessages(getMessage('heading_unexpected_error'));
        } finally {
            setIsLoading(false);
            onClose();
        }
    };

    useEffect(() => {
        setIsButtonDisabled(
            selectedCustomers.length === 0 || selectedCustomers.length > 20
        );
    }, [selectedCustomers]);

    return (
        <Modal
            open={true}
            onClose={onClose}
            className={'goldshark-discount-modal'}
        >
            <ModalLayout>
                <ModalCloseButton />
                <ModalIllustration>
                    {getSvgIcon('editDiscount')}
                </ModalIllustration>
                <ModalTitle>{getMessage('text_copy_discount')}</ModalTitle>
                <ModalSubtitle className="atl-mt-16">
                    {getMessage('text_copy_discounts_to_customer')}
                </ModalSubtitle>

                <ModalContent>
                    {noDiscountAvailable ? (
                        <Alert variant="info">
                            <AlertContent>
                                {getMessage('text_no_discounts_applicable')}
                            </AlertContent>
                        </Alert>
                    ) : (
                        <FormLayout
                            id={'copyDiscountDiscountForm'}
                            onSubmit={handleOnSubmit}
                        >
                            {selectedCustomers.length > 20 && (
                                <Alert variant="info">
                                    <AlertContent>
                                        {getMessage(
                                            'text_max_selected_customers_exceeded',
                                            20
                                        )}
                                    </AlertContent>
                                </Alert>
                            )}
                            <DivLayout className="atl-flex atl-flex-col atl-mb-24 atl-gap-8 atl-mt-16">
                                <DivLayout>
                                    {getMessage('text_copy_to_customer')}
                                </DivLayout>
                                <SelectCustomerDropdown
                                    customerId={customerId}
                                    onChange={setSelectedCustomers}
                                />
                            </DivLayout>
                            <DivLayout className="atl-flex atl-flex-col atl-gap-8">
                                <DivLayout>
                                    {getMessage(
                                        'text_products_already_have_discount'
                                    )}
                                </DivLayout>
                                <DivLayout className="atl-flex atl-gap-16">
                                    <RadioGroup className="atl-flex-1">
                                        <Input
                                            type="radio"
                                            data-testid={
                                                'keep-existing-discount-button'
                                            }
                                            id={'keep-existing-discount-button'}
                                            checked={keepExistingDiscount}
                                            name={'keepExistingDiscount'}
                                            onChange={() =>
                                                setKeepExistingDiscount(true)
                                            }
                                        />
                                        <Label
                                            htmlFor={
                                                'keep-existing-discount-button'
                                            }
                                        >
                                            {getMessage(
                                                'text_keep_existing_discount'
                                            )}
                                        </Label>
                                    </RadioGroup>
                                    <RadioGroup className="atl-flex-1">
                                        <Input
                                            type="radio"
                                            data-testid={
                                                'apply-new-discount-button'
                                            }
                                            id={'apply-new-discount-button'}
                                            name={'applyNewDiscount'}
                                            checked={!keepExistingDiscount}
                                            onChange={() =>
                                                setKeepExistingDiscount(false)
                                            }
                                        />
                                        <Label
                                            htmlFor={
                                                'apply-new-discount-button'
                                            }
                                        >
                                            {getMessage(
                                                'text_apply_new_discount'
                                            )}
                                        </Label>
                                    </RadioGroup>
                                </DivLayout>
                            </DivLayout>
                        </FormLayout>
                    )}
                </ModalContent>
                <ModalFooter>
                    <ProductDiscountModalActions
                        formId={'copyDiscountDiscountForm'}
                        onCancel={onClose}
                        isEditMode={true}
                        isButtonDisabled={isButtonDisabled}
                    />
                </ModalFooter>
            </ModalLayout>
            {isLoading && (
                <ModalLoadingSpinner
                    text={getMessage('bodyTop_jsp_please_wait')}
                    top={'40%'}
                />
            )}
        </Modal>
    );
}

function SelectCustomerDropdown({
    customerId,
    onChange,
}: {
    customerId?: string;
    onChange: (value: string[]) => void;
}) {
    return (
        <Dropdown
            name={'discountProduct'}
            data-testid="create-discount-product-dropdown"
            defaultDisplayName={getMessage('text_select_customer')}
            multiple={true}
            onChange={(value) => {
                const ids = Array.from(value.target.selectedOptions).map(
                    (option) => option.value
                );
                onChange(ids);
            }}
        >
            <DropdownOpener className="atl-w-full" />
            <DropdownDrawer>
                <DropdownSearch />
                <DropdownScrollContainer>
                    <CustomAsyncDropdownOptions
                        excludedOptions={customerId ? [customerId] : []}
                        url={`${ApiUrl.CUSTOMER_QUERY}?sorting=displayName`}
                    ></CustomAsyncDropdownOptions>
                </DropdownScrollContainer>
            </DropdownDrawer>
        </Dropdown>
    );
}
