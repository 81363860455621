import { invokeAPI, MethodType } from '@Page/Goldshark/API/api';
import { ApiURL } from '@Page/Goldshark/API/url';
import { getProcessedPayload } from '@Page/Goldshark/API/function';
import { TableColumns } from '@Page/Goldshark/Utils/constant';
import { DisplayOptions } from '@tlx/astro-shared';

export function doCreateResaleProduct(
    values: Record<string, FormDataEntryValue | {}>
) {
    return invokeAPI(
        ApiURL.PRODUCT_RESALE,
        MethodType.POST,
        getProcessedPayload(values)
    );
}
export function getResaleProductPayload(
    values: Record<string, FormDataEntryValue>,
    isStockItem?: boolean
) {
    return {
        name: values['name'],
        number: values['number'],
        costExcludingVatCurrency: values['costExcludingVatCurrency'],
        vatType: { id: values['vatTypeId'] },
        isStockItem: isStockItem ? true : values['isStockItem'] !== undefined,
        priceIncludingVatCurrency: values['priceIncludingVatCurrency'],
        priceExcludingVatCurrency: values['priceExcludingVatCurrency'],
    };
}
export function doCreateResaleProductGroupRelation(values: Record<string, {}>) {
    return invokeAPI(
        ApiURL.PRODUCT_GROUP_RELATIONS,
        MethodType.POST,
        getProcessedPayload(values)
    );
}
export function doCreateSupplierConnection(
    values: Record<string, FormDataEntryValue | {}>
) {
    return invokeAPI(
        ApiURL.PRODUCT_SUPPLIER,
        MethodType.POST,
        getProcessedPayload(values)
    );
}

export function getSupplierConnectionPayload(
    values: Record<string, FormDataEntryValue>,
    resaleProductId: number,
    supplierId: number
) {
    return {
        name: values['name'],
        number: values['number'],
        costExcludingVatCurrency: values['costExcludingVatCurrency'],
        resaleProduct: { id: resaleProductId },
        supplier: { id: supplierId },
    };
}

export function getProductSelectorModalTableName(
    display: DisplayOptions,
    supplierId?: number,
    isWholesaler?: boolean,
    hideCount?: boolean
) {
    return supplierId !== undefined
        ? isWholesaler
            ? TableColumns.SUPPLIER_CONNECTION_TABLE_EXTERNAL
            : display === DisplayOptions.DETAILED
            ? TableColumns.SUPPLIER_CONNECTION_TABLE_DETAILED
            : TableColumns.SUPPLIER_CONNECTION_TABLE
        : hideCount
        ? 'stocktakingProductTable'
        : TableColumns.RESALE_PRODUCT_TABLE;
}
