import React from 'react';
import { Group, ModalContent, ModalSubtitle, ModalTitle } from '@tlx/atlas';
import { APIError, HTTPError } from '@tlx/astro-shared';

export type SendSigningRequestErrorProps = {
    error: APIError | HTTPError;
};

export function SendSigningRequestError({
    error,
}: SendSigningRequestErrorProps) {
    if (error instanceof APIError && error.details.status === 422) {
        const validationMessage = error.details.validationMessages?.[0];

        if (validationMessage?.field === 'REQUIRE_MANUAL') {
            return <BrregRequiresManualHandlingError />;
        } else if (validationMessage?.field === 'NOT_FOUND') {
            return <BrregNotFoundError message={validationMessage.message} />;
        }
    }

    return <GenericError />;
}

function GenericError() {
    return (
        <>
            <ModalTitle>{getMessage('text_service_unavailable')}</ModalTitle>
            <ModalContent>
                <Group
                    className={'atl-flex atl-flex-col atl-pb-16 atl-text-base'}
                >
                    <div>{getMessage('text_brreg_issue')}</div>
                </Group>
            </ModalContent>
        </>
    );
}

function BrregNotFoundError({ message }: { message: string }) {
    return (
        <>
            <ModalTitle>{getMessage('text_brreg_issue_not_found')}</ModalTitle>
            <ModalSubtitle>{message}</ModalSubtitle>
            <ModalContent>
                <Group
                    className={'atl-flex atl-flex-col atl-pb-16 atl-text-base'}
                >
                    <div>{getMessage('text_brreg_issue_explanation')}</div>
                    <ul>
                        <li>{getMessage('text_brreg_issue_reason_3')}</li>
                        <li>{getMessage('text_brreg_issue_reason_1')}</li>
                        <li>{getMessage('text_brreg_issue_reason_2')}</li>
                    </ul>
                </Group>
            </ModalContent>
        </>
    );
}

function BrregRequiresManualHandlingError() {
    return (
        <>
            <ModalTitle>
                {getMessage('text_unverifiable_company_brreg_short')}
            </ModalTitle>
            <ModalSubtitle>
                {getMessage('text_unverifiable_company_brreg_long')}
            </ModalSubtitle>
            <ModalContent>
                <Group className={'atl-flex atl-flex-col atl-pb-8'}>
                    <div>
                        {getMessage('text_suggested_solution_contact_support')}
                    </div>
                </Group>
                <div className="atl-flex atl-justify-center atl-mt-8">
                    <a
                        //TODO: replace with resource instead of hardcoding?
                        href="https://hjelp.tripletex.no/hc/no/p/kontakt_oss"
                        className="atl-button atl-button--primary"
                    >
                        {getMessage('text_shortcut_to_tripletex_support')}
                    </a>
                </div>
            </ModalContent>
        </>
    );
}
