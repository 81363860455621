import * as React from 'react';

import {
    Button,
    Fieldset,
    Group,
    Input,
    Label,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalLayout,
    ModalTitle,
    Option,
    RadioGroup,
    Select,
    Textarea,
} from '@tlx/atlas';

import {
    downloadPageAsPDF,
    getDefaultPDFConfiguration,
    PDFExportConfiguration,
} from '../ExportMenu.util';

import './ExportModal.css';

export interface ExportPdfProps {
    readonly onClose: () => void;
}

/**
 * ExportPdf
 */
export const ExportPdf: React.FC<ExportPdfProps> = ({ onClose }) => {
    const defaults = React.useMemo(getDefaultPDFConfiguration, []);

    const [pageFormat, setPageFormat] = React.useState<
        PDFExportConfiguration['pageFormat']
    >(defaults.pageFormat);
    const onChangePageFormat: React.ChangeEventHandler<HTMLSelectElement> =
        React.useCallback(
            (event) =>
                setPageFormat(
                    event.target.value as PDFExportConfiguration['pageFormat']
                ),
            [setPageFormat]
        );

    const [pageOrientation, setPageOrientation] = React.useState<
        PDFExportConfiguration['pageOrientation']
    >(defaults.pageOrientation);
    const onChangePageOrientation: React.ChangeEventHandler<HTMLSelectElement> =
        React.useCallback(
            (event) =>
                setPageOrientation(
                    event.target
                        .value as PDFExportConfiguration['pageOrientation']
                ),
            [setPageOrientation]
        );

    const [pdfAction, setPdfAction] = React.useState<
        PDFExportConfiguration['pdfAction']
    >(defaults.pdfAction);
    const isPdfOpen = pdfAction === 'pdfOpen';
    const isPdfEmail = pdfAction === 'pdfEmail';
    const onChangePdfActionOpen = React.useCallback(
        () => setPdfAction('pdfOpen'),
        [setPdfAction]
    );
    const onChangePdfActionEmail = React.useCallback(
        () => setPdfAction('pdfEmail'),
        [setPdfAction]
    );

    const [emailAddress, setEmailAddress] = React.useState<
        PDFExportConfiguration['emailAddress']
    >(defaults.emailAddress);
    const onChangeEmailAddress: React.ChangeEventHandler<HTMLInputElement> =
        React.useCallback(
            (event) => setEmailAddress(event.target.value),
            [setEmailAddress]
        );

    const [emailBody, setEmailBody] = React.useState<
        PDFExportConfiguration['emailBody']
    >(defaults.emailBody);
    const onChangeEmailBody: React.ChangeEventHandler<HTMLTextAreaElement> =
        React.useCallback(
            (event) => setEmailBody(event.target.value),
            [setEmailBody]
        );

    const onAccept = React.useCallback(() => {
        downloadPageAsPDF({
            pageFormat,
            pageOrientation,
            pdfAction,
            emailAddress,
            emailBody,
        });
        onClose();
    }, [
        emailAddress,
        emailBody,
        onClose,
        pageFormat,
        pageOrientation,
        pdfAction,
    ]);

    return (
        <Modal open onClose={onClose}>
            <ModalLayout>
                <ModalCloseButton />
                <ModalTitle>{getMessage('text_pdf_export')}</ModalTitle>
                <ModalContent className="tlx-export-modal">
                    <div className="atl-flex atl-flex-col atl-gap-24">
                        <Fieldset className="atl-flex atl-flex-row atl-gap-16 atl-flex-wrap atl-justify-between">
                            <Group className="atl-flex-1">
                                <Label htmlFor="pageFormat">
                                    {getMessage('text_paper_size')}
                                </Label>
                                <Select
                                    id="pageFormat"
                                    data-testid="pdf-export-modal__page-format"
                                    value={pageFormat}
                                    onChange={onChangePageFormat}
                                >
                                    <Option value="A5">A5</Option>
                                    <Option value="A4">A4</Option>
                                    <Option value="A3">A3</Option>
                                    <Option value="B5">B5</Option>
                                    <Option value="B4">B4</Option>
                                    <Option value="letter">Letter</Option>
                                    <Option value="legal">Legal</Option>
                                    <Option value="ledger">Ledger</Option>
                                </Select>
                            </Group>
                            <Group className="atl-flex-1">
                                <Label htmlFor="pageOrientation">
                                    {getMessage('text_page_orientation')}
                                </Label>
                                <Select
                                    id="pageOrientation"
                                    data-testid="pdf-export-modal__page-orientation"
                                    value={pageOrientation}
                                    onChange={onChangePageOrientation}
                                >
                                    <Option value="portrait">
                                        {getMessage(
                                            'text_page_orientation_portrait'
                                        )}
                                    </Option>
                                    <Option value="landscape">
                                        {getMessage(
                                            'text_page_orientation_landscape'
                                        )}
                                    </Option>
                                </Select>
                            </Group>
                        </Fieldset>
                        <Fieldset className="atl-flex atl-flex-col atl-gap-16">
                            <Label>{getMessage('text_select_action')}</Label>
                            <RadioGroup>
                                <Input
                                    id="pdfOpen"
                                    name="pdfAction"
                                    type="radio"
                                    data-testid="pdf-export-modal__pdf-action-open"
                                    checked={isPdfOpen}
                                    onChange={onChangePdfActionOpen}
                                />
                                <Label htmlFor="pdfOpen">
                                    {getMessage('text_pdf_get')}
                                </Label>
                            </RadioGroup>
                            <RadioGroup>
                                <Input
                                    id="pdfEmail"
                                    name="pdfAction"
                                    type="radio"
                                    data-testid="pdf-export-modal__pdf-action-email"
                                    checked={isPdfEmail}
                                    onChange={onChangePdfActionEmail}
                                />
                                <Label htmlFor="pdfEmail">
                                    {getMessage('text_send_as_attachment')}
                                </Label>
                            </RadioGroup>
                        </Fieldset>
                        {isPdfEmail ? (
                            <Fieldset className="atl-flex atl-flex-col atl-gap-16 atl-items-stretch">
                                <Group>
                                    <Label htmlFor="emailAddress">
                                        {getMessage('text_receiver')}
                                    </Label>
                                    <Input
                                        id="emailAddress"
                                        type="email"
                                        data-testid="pdf-export-modal__email-address"
                                        placeholder={getMessage('text_email')}
                                        required
                                        multiple
                                        value={emailAddress}
                                        onChange={onChangeEmailAddress}
                                        className="atl-w-full"
                                    />
                                </Group>
                                <Group>
                                    <Label htmlFor="emailBody">
                                        {getMessage('text_message')}
                                    </Label>
                                    <Textarea
                                        id="emailBody"
                                        rows={6}
                                        data-testid="pdf-export-modal__email-body"
                                        value={emailBody}
                                        onChange={onChangeEmailBody}
                                        className="atl-w-full"
                                    />
                                </Group>
                            </Fieldset>
                        ) : null}
                    </div>
                </ModalContent>
                <ModalFooter>
                    <div className="atl-flex atl-gap-8 atl-items-left">
                        <Button
                            data-testid="pdf-export-modal__download-button"
                            variant="primary"
                            onClick={onAccept}
                        >
                            {getMessage(
                                isPdfEmail
                                    ? 'text_send_pdf_via_email'
                                    : 'text_download_pdf'
                            )}
                        </Button>
                        <Button
                            data-testid="pdf-export-modal__cancel-button"
                            variant="secondary"
                            onClick={onClose}
                        >
                            {getMessage('button_cancel')}
                        </Button>
                    </div>
                </ModalFooter>
            </ModalLayout>
        </Modal>
    );
};
