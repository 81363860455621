import {
    DivLayout,
    FormDropdownInput,
    FormLayout,
    FormNumberInput,
    FormTextInput,
    FormToggleInput,
} from '@Page/Goldshark/Components/LayoutComponents';
import { ApiURL, DropdownURL } from '@Page/Goldshark/API/url';
import React, { useState } from 'react';
import { useCreateProductSellingPrice } from '@Page/Goldshark/Hooks/useCreateProductSellingPrice';
import { useListSearchParam } from '@tlx/astro-shared';
import { invokeAPI } from '@Page/Goldshark/API/api';
import { ProductAPIModel } from '@Page/Goldshark/API/interface';
import useSessionStorage from '@Page/Goldshark/Hooks/useSessionStorage';
import {
    doCreateResaleProduct,
    doCreateResaleProductGroupRelation,
    doCreateSupplierConnection,
    getResaleProductPayload,
    getSupplierConnectionPayload,
} from '@Page/Goldshark/Components/Modal/ProductSelectorModal/ProductSelectorModalActions';
import { getProcessedErrors } from '@Page/Goldshark/API/function';
import { Input, Label, ToggleGroup } from '@tlx/atlas';
import { FORM } from '@Page/Goldshark/Utils/constant';
import { useSuggestedProductNumber } from '@Page/LogisticsV2/components/Modals/ProductSelector/useProducts';

export function ProductSelectorModalCreateRow({
    supplierId,
    isStockItem,
    onCreate,
}: {
    supplierId?: number;
    isStockItem?: boolean;
    onCreate: (product: ProductAPIModel) => void;
}) {
    const [hasResaleProduct, setHasResaleProduct] = useSessionStorage(
        'supplier-connection-create-form-has-resale-product',
        false
    );
    const [errors, setErrors] = useState<Record<string, string>>({});
    function handleFieldError(field: string, message: string) {
        setErrors({
            [field]: getMessage(message),
        });
    }
    function isFieldEmpty(fieldValue: FormDataEntryValue, fieldName: string) {
        if (fieldValue === undefined || fieldValue === '') {
            handleFieldError(fieldName, `validation_missing`);
            return true;
        }
        return false;
    }
    const handleSubmitForm = async (
        values: Record<string, FormDataEntryValue>
    ) => {
        setErrors({});
        try {
            if (
                hasResaleProduct &&
                isFieldEmpty(values['resaleProductId'], 'resaleProductId')
            ) {
                return;
            }
            if (isFieldEmpty(values['name'], 'name')) {
                return;
            }
            let productId = values['resaleProductId'];
            let newProduct: ProductAPIModel = { id: 0 };
            if (!hasResaleProduct) {
                const createResaleResponse = await doCreateResaleProduct(
                    getResaleProductPayload(values, isStockItem)
                );
                newProduct = createResaleResponse.data;
                productId = createResaleResponse.data.id;
                if (values['productGroupId']) {
                    await doCreateResaleProductGroupRelation({
                        product: { id: productId },
                        productGroup: { id: values['productGroupId'] },
                    });
                }
            }
            if (supplierId) {
                const supplierConnectionResponse =
                    await doCreateSupplierConnection(
                        getSupplierConnectionPayload(
                            values,
                            Number(productId),
                            supplierId
                        )
                    );
                newProduct = supplierConnectionResponse.data;
            }
            onCreate(newProduct);
        } catch (error) {
            setErrors(getProcessedErrors(error));
        }
    };

    return (
        <DivLayout
            className={`atl-flex atl-flex-col atl-p-16 atl-gap-16`}
            style={{ height: '39vh' }}
        >
            {supplierId && (
                <ToggleGroup>
                    <Input
                        id="create-supplier-product-has-resale-toggle-switch"
                        type="toggle"
                        onChange={() => {
                            setErrors({});
                            setHasResaleProduct(!hasResaleProduct);
                        }}
                        data-testid={
                            'create-supplier-product-has-resale-toggle-switch'
                        }
                        checked={hasResaleProduct}
                    />
                    <Label htmlFor="create-supplier-product-has-resale-toggle-switch">
                        {getMessage('text_resale_product_toggle')}
                    </Label>
                </ToggleGroup>
            )}
            <FormLayout
                onSubmit={handleSubmitForm}
                className={'atl-flex'}
                id={FORM.PRODUCT_SELECTOR_CREATE_PRODUCT}
            >
                {hasResaleProduct && supplierId !== undefined ? (
                    <NewProductWithResale
                        errors={errors}
                        onChange={() => setErrors({})}
                    />
                ) : (
                    <NewProductWithoutResale
                        errors={errors}
                        isStockItem={isStockItem}
                    />
                )}
            </FormLayout>
        </DivLayout>
    );
}
export function NewProductWithoutResale({
    errors,
    isStockItem,
}: {
    errors: Record<string, string>;
    isStockItem?: boolean;
}) {
    const sellingPrice = useCreateProductSellingPrice();
    const productGroups = useListSearchParam('productGroups');
    const suggestedProductNumber = useSuggestedProductNumber();
    return (
        <DivLayout className={'supplier-connection-new-product-without-resale'}>
            <FormTextInput
                name={'name'}
                label={`${getMessage('text_name')}*`}
                error={errors['name']}
            />
            <FormTextInput
                name={'number'}
                label={getMessage('text_number')}
                error={errors['number']}
                defaultValue={suggestedProductNumber}
            />
            {moduleLogistics && (
                <FormDropdownInput
                    url={DropdownURL.PRODUCT_GROUP}
                    name={'productGroupId'}
                    label={getMessage('text_product_group')}
                    defaultValue={productGroups[0]}
                    customOption={{
                        displayName: getMessage('option_not_chosen'),
                        value: '',
                    }}
                />
            )}
            <FormNumberInput
                name={'costExcludingVatCurrency'}
                label={getMessage('text_price_purchase_excl_vat')}
            />
            <FormDropdownInput
                url={DropdownURL.VAT_TYPE}
                name={'vatTypeId'}
                label={getMessage('text_vat_type')}
                defaultValue={'3'}
                onChange={sellingPrice.onChangeVatType}
            />
            <FormNumberInput
                defaultValue={sellingPrice.sellingPriceExclVat}
                name={'priceExcludingVatCurrency'}
                label={getMessage('text_sales_price_excl_vat')}
                onChange={sellingPrice.onChangePriceExclVat}
            />
            <FormNumberInput
                defaultValue={sellingPrice.sellingPriceInclVat}
                name={'priceIncludingVatCurrency'}
                label={getMessage('text_sales_price_incl_vat')}
                onChange={sellingPrice.onChangePriceInclVat}
            />
            {!isStockItem && (
                <FormToggleInput
                    name={'isStockItem'}
                    label={getMessage('text_warehouse_product')}
                    className={'atl-mt-auto atl-mb-12'}
                    defaultValue={true}
                />
            )}
        </DivLayout>
    );
}
export function NewProductWithResale({
    errors,
    onChange,
}: {
    errors: Record<string, string>;
    onChange: () => void;
}) {
    const [values, setValues] = useState({
        name: '',
        number: '',
        costExcludingVatCurrency: '',
        currencyId: '1',
    });
    const handleChangeResaleProduct = async (resaleId: string) => {
        const { data } = await invokeAPI(
            `${ApiURL.PRODUCT_RESALE}/${resaleId}`,
            'GET'
        );
        if (data) {
            setValues({
                name: data.name,
                number: data.number,
                costExcludingVatCurrency: data.costExcludingVatCurrency,
                currencyId: data.currencyId,
            });
            onChange();
        }
    };

    return (
        <DivLayout className={'supplier-connection-new-product-with-resale'}>
            <FormDropdownInput
                url={DropdownURL.PRODUCT_QUERY}
                name={'resaleProductId'}
                label={`${getMessage('text_product_for_resale')}*`}
                error={errors['resaleProductId']}
                onChange={handleChangeResaleProduct}
            />
            <FormTextInput
                name={'name'}
                label={`${getMessage('text_name')}*`}
                error={errors['name']}
                defaultValue={values.name}
            />
            <FormTextInput
                name={'number'}
                label={getMessage('text_number')}
                defaultValue={values.number}
                error={errors['number']}
            />
            <FormNumberInput
                name={'costExcludingVatCurrency'}
                label={getMessage('text_price_purchase_excl_vat')}
                defaultValue={values.costExcludingVatCurrency}
            />
            <FormDropdownInput
                url={DropdownURL.CURRENCY}
                name={'currencyId'}
                label={getMessage('text_currency')}
                defaultValue={values.currencyId}
            />
        </DivLayout>
    );
}
