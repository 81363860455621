import * as React from 'react';

import { PageLoader } from '@Component/PageLoader';
import { renderComponent } from '@General/renderComponent';

import type { KillSwitchRootProps } from './component';

export const KillSwitchPage: React.FC<KillSwitchRootProps> = (props) => (
    <PageLoader<KillSwitchRootProps>
        loader={async () => import('./KillSwitch.module').then((m) => m.page)}
        adjustWrapperDiv
        props={props}
    />
);

export function renderKillSwitchPage(rootId: string) {
    renderComponent(KillSwitchPage, rootId);
}
