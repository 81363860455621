import { ProductDiscountPageLayout } from '@Page/LogisticsV2/pages/Product discount/overview/ProductDiscountComponents';
import { ProductDiscountTableWithFilters } from '@Page/LogisticsV2/pages/Product discount/overview/table/ProductDiscountTableWithFilters';
import './ProductDiscount.css';
import { Alert, AlertContent } from '@tlx/atlas';

export function ProductDiscountPage() {
    return (
        <ProductDiscountPageLayout>
            <Alert
                variant="success"
                id="discount-alert"
                className={'atl-hidden'}
            >
                <AlertContent></AlertContent>
            </Alert>
            <ProductDiscountTableWithFilters />
        </ProductDiscountPageLayout>
    );
}
