export enum Team {
    ANGRY_NERDS = 'ANGRY_NERDS',
    ANTS = 'ANTS',
    ASTRO = 'ASTRO',
    ATEAM = 'ATEAM',
    BEEHIVE = 'BEEHIVE',
    BOND = 'BOND',
    DATA_STREAM = 'DATA_STREAM',
    FINX = 'FINX',
    GOLDSHARK = 'GOLDSHARK',
    KILLBILL = 'KILLBILL',
    KRR = 'KRR',
    OPTIMUS = 'OPTIMUS',
    PBC = 'PBC',
    PHOENIX = 'PHOENIX',
    SECURITY = 'SECURITY',
    SHEET_STORM = 'SHEET_STORM',
    TOOLKITTENS = 'TOOLKITTENS',
    TSK = 'TSK',
    UNKNOWN = 'UNKNOWN',
}
