import { useCallback, useState } from 'react';
import { useInvokeAPI } from '@Page/Goldshark/API/api';
import { DropdownURL } from '@Page/Goldshark/API/url';
import { VatTypeAPIModel } from '@Page/Goldshark/API/interface';
export function useCreateProductSellingPrice() {
    const { data: vatTypes } = useInvokeAPI<VatTypeAPIModel[]>(
        DropdownURL.VAT_TYPE
    );
    const [percent, setPercent] = useState<number>(25);
    const [sellingPriceInclVat, setSellingPriceInclVat] = useState('');
    const [sellingPriceExclVat, setSellingPriceExclVat] = useState('');

    const onChangeVatType = async (value: string) => {
        const percentage = vatTypes?.find(
            (vatType) => vatType.id.toString() === value
        )?.percentage;
        if (percentage) {
            setPercent(percentage);
            const calculatedPrice = calculatePriceInclVat(
                Number(sellingPriceExclVat),
                percentage
            );
            setSellingPriceInclVat(calculatedPrice.toFixed(2));
        }
    };

    const onChangePriceExclVat = useCallback((price: string) => {
        setSellingPriceExclVat(price);
        if (!isNaN(Number(price))) {
            const calculatedPrice = calculatePriceInclVat(
                Number(price),
                percent
            );
            setSellingPriceInclVat(calculatedPrice.toFixed(2));
        }
    }, []);

    const onChangePriceInclVat = useCallback((price: string) => {
        setSellingPriceInclVat(price);
        if (!isNaN(Number(price))) {
            const calculatedPrice = calculatePriceExclVat(
                Number(price),
                percent
            );
            setSellingPriceExclVat(calculatedPrice.toFixed(2));
        }
    }, []);

    return {
        sellingPriceExclVat,
        sellingPriceInclVat,
        onChangeVatType,
        onChangePriceExclVat,
        onChangePriceInclVat,
    };
}
function calculatePriceInclVat(
    sellingPriceExclVat: number,
    vatPercentage: number
): number {
    return sellingPriceExclVat * (1 + vatPercentage / 100);
}

function calculatePriceExclVat(
    sellingPriceInclVat: number,
    vatPercentage: number
): number {
    return sellingPriceInclVat / (1 + vatPercentage / 100);
}
