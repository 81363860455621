import {
    Button,
    DateField,
    DropdownDrawer,
    DropdownField,
    DropdownOpener,
    DropdownScrollContainer,
    DropdownSearch,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalLayout,
    ModalTitle,
    SearchIcon,
    SkeletonText,
} from '@tlx/atlas';
import React, { useState } from 'react';
import { AsyncDropdownOptions } from '@tlx/astro-shared';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { invokeAPI, MethodType, useInvokeAPI } from '@Page/Goldshark/API/api';
import '../RackbeatProductNews.css';
import { getLocale } from '@tlx/intl';

export function InventoryValueModal({
    modalProps,
}: {
    modalProps: { open: boolean; onClose: () => void };
}) {
    const today = new Date().toISOString().split('T')[0];
    const [selectedDate, setSelectedDate] = useState<string>(today);
    const [error, setError] = useState('');
    const [showVoucherModal, setVoucherModal] = useState(false);
    const handleClose = () => {
        setVoucherModal(false);
        setError('');
        setSelectedDate(today);
        modalProps.onClose();
    };

    return (
        <Modal
            open={modalProps.open}
            onClose={handleClose}
            className={'goldshark-inventory-modal'}
        >
            <ModalLayout>
                <ModalCloseButton />
                <ModalTitle>
                    {getMessage('text_get_inventory_value')}
                </ModalTitle>

                <ModalContent className="rackbeat-stock-modal-content">
                    {!showVoucherModal ? (
                        <DateField
                            className="atl-w-full"
                            data-testid={'rackbeat-inventory-date'}
                            label={getMessage('text_date_through')}
                            onChange={(date) => setSelectedDate(date)}
                            value={selectedDate}
                            validate={(value) => {
                                if (
                                    (value &&
                                        /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(
                                            value
                                        )) ||
                                    value === ''
                                ) {
                                    return true;
                                }
                                return getMessage('validation_invalid_date');
                            }}
                            errorMessage={error}
                        />
                    ) : (
                        <VoucherContent
                            onChange={() => setVoucherModal(false)}
                            selectedDate={selectedDate}
                            onError={(error) => setError(error)}
                            onClose={handleClose}
                        />
                    )}
                </ModalContent>

                <ModalFooter>
                    <div className="atl-flex atl-gap-8">
                        <Button
                            className={!showVoucherModal ? 'atl-hidden' : ''}
                            data-testid={'create-voucher-rackbeat-button'}
                            type={'submit'}
                            form={'create-voucher-rackbeat'}
                        >
                            {getMessage('text_create_voucher')}
                        </Button>

                        <Button
                            className={showVoucherModal ? 'atl-hidden' : ''}
                            data-testid={'rackbeat-get-inventory-value'}
                            onClick={() => setVoucherModal(true)}
                            variant="primary"
                            tabIndex={9999}
                        >
                            <SearchIcon />
                            {getMessage('text_get_value')}
                        </Button>

                        <Button
                            data-testid={'rackbeat-inventory-cancel-button'}
                            variant="secondary"
                            onClick={handleClose}
                            tabIndex={9999}
                        >
                            {getMessage('button_cancel')}
                        </Button>
                    </div>
                </ModalFooter>
            </ModalLayout>
        </Modal>
    );
}

export function VoucherContent(props: {
    selectedDate: string;
    onChange: () => void;
    onError: (error: string) => void;
    onClose: () => void;
}) {
    const defaultFormatOptions: Intl.NumberFormatOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    function formatNumber(
        value: number | undefined,
        formatOptions = defaultFormatOptions
    ) {
        if (value !== undefined && isFinite(value)) {
            return Intl.NumberFormat(getLocale(), formatOptions).format(value);
        }
        return '';
    }

    const INCOME_CREDIT_ACCOUNT_NUMBERS = [4090, 4190, 4290, 4390, 4590].join(
        ','
    );
    const OUTGOING_DEBIT_ACCOUNT_NUMBERS = [1409, 1429, 1449, 1469].join(',');
    const { data, error, isLoading } = useInvokeAPI<{
        inventoryTotalValue: string;
    }>(`${ApiUrl.RACKBEAT_INVENTORY_VALUE}?dateTo=${props.selectedDate}`);
    if (error) {
        props.onError(error.validationMessages[0].message);
        props.onChange();
    }
    const [voucherData, setVoucherData] = useState({
        voucherDate: '',
        accountCredit: '',
        accountDebit: '',
        inventoryTotalValue: data?.inventoryTotalValue,
    });
    const handleCreateVoucher = async () => {
        try {
            await invokeAPI(
                ApiUrl.RACKBEAT_VOUCHER_VALUE,
                MethodType.POST,
                JSON.stringify(voucherData)
            );
            props.onClose();
        } catch (error) {
            console.error('Failed to create voucher:', error);
        }
    };

    if (data === undefined) {
        return null;
    }

    return (
        <form
            id={'create-voucher-rackbeat'}
            onSubmit={async (event) => {
                event.preventDefault();
                event.stopPropagation();
                await handleCreateVoucher();
            }}
        >
            {isLoading && <SkeletonText className="atl-w-full" />}
            <div className="atl-flex atl-flex-col atl-rounded atl-bg-bg atl-gap-8 atl-p-24">
                <div className="atl-text-2xl">
                    {formatNumber(Number(data.inventoryTotalValue))} NOK
                </div>
                <div>{props.selectedDate}</div>
                <div>
                    <Button
                        data-testid={'rackbeat-selected-to-date'}
                        variant="secondary"
                        onClick={props.onChange}
                    >
                        {getMessage('text_change_date_dialog')}
                    </Button>
                </div>
            </div>

            <div className="atl-font-medium atl-mt-24 atl-mb-24">
                {getMessage('text_voucher_details')}
            </div>

            <div className="atl-flex atl-flex-col atl-gap-16">
                <DateField
                    className="atl-w-full"
                    data-testid={'rackbeat-inventory-date'}
                    label={getMessage('text_voucher_date')}
                    onChange={(date) =>
                        setVoucherData((prevState) => ({
                            ...prevState,
                            voucherDate: date,
                        }))
                    }
                    value={''}
                />

                <DropdownField
                    className="atl-w-full"
                    data-testid="rackbeat-inventory-balance-account"
                    label={getMessage('text_account_debit')}
                    defaultSelectedKey={''}
                    onSelectionChange={(value) =>
                        setVoucherData((prevState) => ({
                            ...prevState,
                            accountDebit: Object.keys(value)[0],
                        }))
                    }
                >
                    <DropdownOpener />
                    <DropdownDrawer>
                        <DropdownSearch />
                        <DropdownScrollContainer>
                            <AsyncDropdownOptions
                                url={`${ApiUrl.INCOME_ACCOUNT_QUERY}?number=${OUTGOING_DEBIT_ACCOUNT_NUMBERS}`}
                            />
                        </DropdownScrollContainer>
                    </DropdownDrawer>
                </DropdownField>

                <DropdownField
                    className="atl-w-full"
                    data-testid="rackbeat-inventory-income-account-credit"
                    label={getMessage('text_account_credit')}
                    defaultSelectedKey={''}
                    onSelectionChange={(value) =>
                        setVoucherData((prevState) => ({
                            ...prevState,
                            accountCredit: Object.keys(value)[0],
                        }))
                    }
                >
                    <DropdownOpener />
                    <DropdownDrawer>
                        <DropdownSearch />
                        <DropdownScrollContainer>
                            <AsyncDropdownOptions
                                url={`${ApiUrl.INCOME_ACCOUNT_QUERY}?number=${INCOME_CREDIT_ACCOUNT_NUMBERS}`}
                            />
                        </DropdownScrollContainer>
                    </DropdownDrawer>
                </DropdownField>
            </div>
        </form>
    );
}
