import { PageData, PageLoader } from '@Component/PageLoader';
import { RouteData } from '@General/Router';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import { Team } from '@General/Team';
import { ImportPage } from '@Page/LogisticsV2/pages/Import v2/ImportPage';

const loader: React.FC = () => (
    <PageLoader<{}> loader={async () => page} adjustWrapperDiv />
);
export const route: RouteData = {
    component: loader,
    path: PageUrl.PRODUCT_IMPORT,
    documentationComponent: 172,
    team: Team.GOLDSHARK,
    auth: () => hasProductAuthAdminRole && !moduleRackbeat,
};
const PAGE_ID = 'ImportPage';
const page: PageData<any> = {
    page: ImportPage,
    modules: [
        {
            id: PAGE_ID,
        },
    ],
};
