import { RefObject } from 'react';
export function removeUrlParams(paramsToRemove: string[]): void {
    const currentUrl = new URL(window.location.href);
    paramsToRemove.forEach((param) => {
        currentUrl.searchParams.delete(param);
    });
    window.history.pushState({}, '', currentUrl);
}
export function getFormValues(
    formRef: RefObject<HTMLFormElement>,
    callback: (values: Record<string, FormDataEntryValue>) => void
) {
    const formElement = formRef.current;
    if (formElement) {
        const formData = new FormData(formElement);
        const values = Object.fromEntries(formData.entries());
        callback(values);
    }
}
