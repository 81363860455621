import {
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalLayout,
    Table,
    TableTopBar,
} from '@tlx/atlas';
import {
    DisplayOptions,
    Settings,
    TableDisplaySetting,
    useSearchParam,
} from '@tlx/astro-shared';
import './ProductSelectorModalStyle.css';
import {
    getProductSelectorModalURL,
    useProductSelectorModalAPIData,
    useProductSelectorModalQueryParams,
    useProductSelectorModalTableData,
} from '@Page/Goldshark/Components/Modal/ProductSelectorModal/useProductSelectorModal';
import {
    ProductSelectorFooter,
    ProductSelectorModalTableBody,
    ProductSelectorModalTitle,
    ProductSelectorTableFilters,
} from '@Page/Goldshark/Components/Modal/ProductSelectorModal/ProductSelectorModalComponents';
import {
    SelectedRowsStatus,
    TableHeader,
    TableTopBarColumnsSettings,
} from '@Page/Goldshark/Components/TableComponents';
import { removeUrlParams } from '@Page/Goldshark/Utils/function';
import { FORM } from '@Page/Goldshark/Utils/constant';
import React, { useState } from 'react';
import { useSupplierAPIData } from '@Page/Goldshark/API/useAPIData';
import { ProductAPIModel } from '@Page/Goldshark/API/interface';
import { getProductSelectorModalTableName } from '@Page/Goldshark/Components/Modal/ProductSelectorModal/ProductSelectorModalActions';
import { ProductSelectorModalCreateRow } from '@Page/Goldshark/Components/Modal/ProductSelectorModal/ProductSelectorModalCreate';
import { ModalLoadingSpinner } from '@Page/Goldshark/Components/LayoutComponents';
import { useDeviceType } from '@Page/LogisticsV2/hooks/useDeviceType';
import '../../style.css';

type ProductSelectorModalProps = {
    modalProps: { open: boolean; onClose: () => void };
    supplierId?: number;
    isDirect?: boolean;
    className?: string;
    hideCount?: boolean;
    onConfirm?: (products: Record<string, string>) => Promise<void>;
};
export function ProductSelectorModal(props: ProductSelectorModalProps) {
    const supplierAPI = useSupplierAPIData(props.supplierId);
    const table = useProductSelectorModalTableData(
        props.supplierId,
        supplierAPI.data?.isWholesaler,
        props.hideCount
    );
    const [isLoading, setIsLoading] = useState(false);
    const productSelectorModalAPI = useProductSelectorModalAPIData(
        props.supplierId,
        supplierAPI.data?.isWholesaler,
        supplierAPI.data?.organizationNumber,
        props.isDirect,
        table.sort
    );
    const action = useSearchParam('action');
    function handleCloseModal() {
        props.modalProps.onClose();
        removeUrlParams(['action', 'query', 'productGroups']);
        table.onClearSelection();
    }
    async function handleConfirmSelection() {
        setIsLoading(true); // Show spinner
        const products = { ...table.selectedRowsWithCount };
        try {
            await props.onConfirm?.(products);
        } finally {
            setIsLoading(false); // Hide spinner after confirmation is done
            handleCloseModal(); // Close the modal
        }
    }
    function handleCreateProduct(product: ProductAPIModel) {
        productSelectorModalAPI.refresh().then(() => {
            removeUrlParams(['action']);
            table.setSelectedRows((prev) => [
                ...(prev ?? []),
                product.id.toString(),
            ]);
        });
    }
    const queryParams = useProductSelectorModalQueryParams(
        props.supplierId,
        supplierAPI.data?.organizationNumber,
        props.isDirect
    );
    const deviceType = useDeviceType();
    if (productSelectorModalAPI.data === undefined) {
        return null;
    }
    return (
        <Modal
            open={props.modalProps.open}
            onClose={handleCloseModal}
            className={`product-selector-modal-container ${
                isLoading ? 'modal-loading' : ''
            }`}
        >
            <ModalLayout>
                <ModalCloseButton />
                <ProductSelectorModalTitle
                    supplierName={supplierAPI.data?.displayName}
                />
                <ModalContent
                    className={`product-selector-modal ${
                        props.className ?? ''
                    } ${
                        table.selectedRows && table.selectedRows?.length > 0
                            ? 'product-selector-selected'
                            : ''
                    }`}
                >
                    <SelectedRowsStatus
                        url={getProductSelectorModalURL(
                            props.supplierId,
                            supplierAPI.data?.isWholesaler
                        )}
                        queryParams={queryParams}
                        selectedRows={table.selectedRows?.length ?? 0}
                        className={'atl-mb-8'}
                        onChangeSelectedRows={table.setSelectedRows}
                    />
                    <TableTopBar>
                        <ProductSelectorTableFilters
                            isWholesaler={supplierAPI.data?.isWholesaler}
                        />
                        {deviceType === 'desktop' && (
                            <Settings>
                                {props.supplierId &&
                                    !supplierAPI.data?.isWholesaler && (
                                        <TableDisplaySetting
                                            name={`supplier-connection-selector-display-${window.loginEmployeeId}`}
                                            label={getMessage(
                                                'text_view_options'
                                            )}
                                            defaultValue={
                                                DisplayOptions.DETAILED
                                            }
                                            options={[
                                                DisplayOptions.DETAILED,
                                                DisplayOptions.DEFAULT,
                                            ]}
                                            data-testid={
                                                'product-selector-settings-display'
                                            }
                                        />
                                    )}
                                <TableTopBarColumnsSettings
                                    table={getProductSelectorModalTableName(
                                        table.display,
                                        props.supplierId,
                                        supplierAPI.data?.isWholesaler ?? false,
                                        props.hideCount
                                    )}
                                />
                            </Settings>
                        )}
                    </TableTopBar>
                    <Table
                        sort={table.sort}
                        selectedRows={table.selectedRows}
                        onSelectedRowsChange={table.setSelectedRows}
                        onSortChange={table.setSort}
                        isCardLayout={deviceType === 'mobile'}
                        className={`product-selector-table`}
                    >
                        {action === 'create' ? (
                            <ProductSelectorModalCreateRow
                                supplierId={props.supplierId}
                                isStockItem={props.hideCount}
                                onCreate={handleCreateProduct}
                            />
                        ) : (
                            <>
                                <TableHeader columns={table.columns} />
                                <ProductSelectorModalTableBody
                                    apiResponse={productSelectorModalAPI}
                                    columns={table.columns}
                                    getCount={table.getCount}
                                    onChangeCount={table.onChangeRowCount}
                                />
                            </>
                        )}
                    </Table>
                </ModalContent>
                <ProductSelectorFooter
                    formId={FORM.PRODUCT_SELECTOR_CREATE_PRODUCT}
                    isDisabled={
                        table.selectedRows === undefined ||
                        table.selectedRows?.length === 0
                    }
                    onConfirm={handleConfirmSelection}
                    onClose={handleCloseModal}
                    isStocktaking={props.hideCount}
                />
            </ModalLayout>
            {isLoading && (
                <ModalLoadingSpinner
                    text={getMessage('text_loading_products')}
                    left={'40%'}
                />
            )}
        </Modal>
    );
}
