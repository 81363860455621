import React, { ReactElement } from 'react';
import {
    DropdownEmpty,
    Option,
    SkeletonOption,
    useDropdownLoadMoreTarget,
    useDropdownOptions,
    useDropdownSearchQuery,
} from '@tlx/atlas';
import useSWRInfinite, { SWRInfiniteKeyLoader } from 'swr/infinite';
import { getOptionsUrl } from '@Component/LoadableDropdown/LoadableDropdown';
import {
    APIError,
    fetcher,
    ListResponse,
    useFetchPaginatedState,
} from '@tlx/astro-shared';

/**
 * This is a custom implementation of AsyncDropdownOptions that is used in Product Discount page.
 * It was needed in order to refresh the dropdown data, instead of retrieving it from the cache.
 * Implementation of AsyncDropdownOptionsView is copied from https://github.com/Tripletex-AS/Order/blob/master/src/components/AsyncDropdownUtils/AsyncDropdownUtils.tsx.
 */

export const CustomAsyncDropdownOptions = ({
    url,
    shouldRefreshData,
    children,
    excludedOptions,
}: {
    url: string;
    shouldRefreshData?: boolean;
    children?: React.ReactNode;
    excludedOptions?: string[];
}): ReactElement => {
    const state = usePaginatedOptions(url);
    if (shouldRefreshData) {
        state.refresh();
    }
    if (excludedOptions && excludedOptions.length > 0) {
        state.data = state.data.filter(
            (item) => !excludedOptions.includes(item.id.toString())
        );
    }

    return (
        <AsyncDropdownOptionsView {...state} data={state.data}>
            {children}
        </AsyncDropdownOptionsView>
    );
};

const AsyncDropdownOptionsView = ({
    data,
    isFiltering,
    isEmpty,
    isLoading,
    hasMore,
    loadMore,
    children,
}: {
    data: { id: number; displayName: string }[];
    isFiltering: boolean;
    isEmpty: boolean;
    isLoading: boolean;
    hasMore: boolean;
    loadMore: () => void;
    children?: React.ReactNode;
}): ReactElement => {
    return (
        <>
            {/* If we are filtering, we don't want to show any default options */}
            {isFiltering ? null : children}

            {data.map((item) => (
                <Option key={item.id} value={item.id}>
                    {item.displayName}
                </Option>
            ))}

            {isEmpty && <AsyncDropdownOptionsEmpty />}
            {isLoading && <AsyncDropdownOptionsSkeleton />}
            {hasMore && (
                <AsyncDropdownOptionsLoadMoreTarget loadMore={loadMore} />
            )}
        </>
    );
};

const AsyncDropdownOptionsEmpty = (): ReactElement => {
    return <DropdownEmpty>{getMessage('text_no_match')}</DropdownEmpty>;
};

const AsyncDropdownOptionsSkeleton = (): ReactElement => {
    return (
        <>
            <SkeletonOption />
            <SkeletonOption />
            <SkeletonOption />
        </>
    );
};

const AsyncDropdownOptionsLoadMoreTarget = ({
    loadMore,
}: {
    loadMore: () => void;
}): ReactElement => {
    const loadMoreRef = useDropdownLoadMoreTarget<HTMLDivElement>(loadMore);

    return <div ref={loadMoreRef} />;
};

export interface UseAsyncDropdownOptionsReturn<T> {
    data: T[];
    isFiltering: boolean;
    isEmpty: boolean;
    isLoading: boolean;
    hasMore: boolean;
    loadMore: () => void;
    refresh: () => Promise<void>;
}
export function usePaginatedOptions<
    T extends { id: number; displayName: string }
>(baseUrl: string): UseAsyncDropdownOptionsReturn<T> {
    const query = useDropdownSearchQuery();
    const options = useDropdownOptions();
    const getKey: SWRInfiniteKeyLoader = (pageIndex) =>
        getOptionsUrl(baseUrl, query, pageIndex);

    const response = useSWRInfinite<ListResponse<T>, APIError>(
        getKey,
        fetcher,
        {
            fallbackData: [],
            initialSize: 0,
            revalidateFirstPage: false,
            revalidateOnFocus: false,
            keepPreviousData: true,
        }
    );

    const state = useFetchPaginatedState(response);

    const isFiltering = query.length > 0;

    /**
     * Because we are using an IntersectionObserver to trigger loading of
     * options, the drawer would be empty immediately after opening
     * unless we assume that we are loading when size is zero.
     */
    const isLoading = state.isLoading || response.size === 0;

    /**
     * If static options are shown, we don't want to show our empty state.
     */
    const isEmpty = state.isEmpty && options.length === 0;

    const refresh = async () => {
        await response.mutate();
    };

    return {
        ...state,
        isFiltering,
        isLoading,
        isEmpty,
        refresh,
    };
}
