import React, { useState } from 'react';
import { Button, DeleteIcon, EditIcon, Input } from '@tlx/atlas';
import { LogisticsModal } from '@Page/LogisticsV2/components/Modals/Modal';
import { DiscountPolicyApiModel } from '@Page/LogisticsV2/pages/Product discount/overview/types';
import { NumberFormat } from '@Page/LogisticsV2/components/NumberFormat/NumberFormat';
import { doDeleteDiscount } from '@Page/LogisticsV2/pages/Product discount/overview/ProductDiscountAction';
import { PageUrl } from '@Page/LogisticsV2/utils/enums';
import { EditProductDiscountModal } from '@Page/LogisticsV2/pages/Product discount/modals/EditDiscountModal';
import { postValidationMessages } from '@General/LegacyValidationPopup';
import { DivLayout } from '@Page/Goldshark/Components/LayoutComponents';

export function ProductDiscountTableRow({
    columns,
    discountPolicy,
    onChange,
    children,
}: {
    columns: string[];
    discountPolicy: DiscountPolicyApiModel;
    onChange: () => void;
    children?: React.ReactNode;
}) {
    return (
        <tr className="atl-tr">
            {children}
            <NumberRow number={discountPolicy.product?.number} />
            <NameRow
                productId={discountPolicy.product?.id}
                name={discountPolicy.product?.name}
            />
            {columns.map((column) => (
                <ProductDiscountTableRowCell
                    key={column}
                    column={column}
                    discountPolicy={discountPolicy}
                />
            ))}
            <ActionRow discountPolicy={discountPolicy} onChange={onChange} />
        </tr>
    );
}

function ProductDiscountTableRowCell({
    column,
    discountPolicy,
}: {
    column: string;
    discountPolicy: DiscountPolicyApiModel;
}): JSX.Element {
    switch (column) {
        case 'priceExcludingVatCurrency':
            return (
                <PriceExcludingVatCurrencyRow
                    price={discountPolicy.product?.priceExcludingVatCurrency}
                />
            );
        case 'discount':
            return discountPolicy.isPercentage ? (
                <PercentageDiscountRow discount={discountPolicy.percentage} />
            ) : (
                <AmountFixedDiscountRow discount={discountPolicy.fixedAmount} />
            );
        case 'priceWithDiscount':
            return (
                <PriceWithDiscountRow
                    priceWithDiscount={discountPolicy.salesPriceWithDiscount}
                />
            );
        case 'currency':
            return (
                <CurrencyRow currency={discountPolicy.product?.currency.code} />
            );
        default:
            throw new Error(`Unknown column: ${column}`);
    }
}

export function CheckboxRow({
    checked,
    onChange,
}: {
    checked: boolean;
    onChange: () => void;
}) {
    return (
        <td className="atl-td atl-td--checkbox">
            <Input
                type={'checkbox'}
                data-testid={'select-all-product-discounts'}
                checked={checked}
                onChange={onChange}
            />
        </td>
    );
}

function NumberRow({ number }: { number: number }) {
    return <td className="atl-td">{number}</td>;
}

function NameRow({ productId, name }: { productId: number; name: string }) {
    return (
        <td className="atl-td">
            <a
                href={addContextId(
                    `${PageUrl.PRODUCT_MENU}?productId=${productId}`
                )}
            >
                {name}
            </a>
        </td>
    );
}

function PercentageDiscountRow({ discount }: { discount: number }) {
    return (
        <td className="atl-td atl-text-right">
            <DivLayout className={'atl-flex atl-gap-2 atl-justify-end'}>
                <NumberFormat
                    value={discount}
                    options={{
                        maximumFractionDigits: 2,
                    }}
                />
                %
            </DivLayout>
        </td>
    );
}

function AmountFixedDiscountRow({ discount }: { discount: number }) {
    return (
        <td className="atl-td atl-text-right">
            <NumberFormat
                value={discount}
                options={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }}
            />
        </td>
    );
}

function PriceExcludingVatCurrencyRow({ price }: { price: number }) {
    return (
        <td className="atl-td atl-text-right">
            <NumberFormat
                value={price}
                options={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }}
            />
        </td>
    );
}

function PriceWithDiscountRow({
    priceWithDiscount,
}: {
    priceWithDiscount: number;
}) {
    return (
        <td className="atl-td atl-text-right">
            <NumberFormat
                value={priceWithDiscount}
                options={{
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                }}
            />
        </td>
    );
}

function CurrencyRow({ currency }: { currency: string }) {
    return <td className="atl-td">{currency}</td>;
}

function ActionRow({
    discountPolicy,
    onChange,
}: {
    discountPolicy: DiscountPolicyApiModel;
    onChange: () => void;
}) {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const discount = discountPolicy.isPercentage
        ? `${discountPolicy.percentage}%`
        : discountPolicy.fixedAmount.toFixed(2);

    async function handleDelete() {
        const { error } = await doDeleteDiscount(discountPolicy.id);
        if (error) {
            postValidationMessages(error);
        } else {
            onChange();
        }
    }

    return (
        <td className="atl-td atl-td--button atl-flex atl-justify-end atl-mr-16 atl-gap-8">
            <Button
                data-testid={'edit-product-discount'}
                aria-label={'Edit product discount'}
                variant="icon"
                onClick={() => setShowEditModal(true)}
            >
                <EditIcon />
            </Button>
            <Button
                data-testid={'delete-product-discount'}
                aria-label={'Delete product discount'}
                variant="icon"
                onClick={() => {
                    setShowDeleteModal(true);
                }}
            >
                <DeleteIcon />
            </Button>
            {showEditModal && (
                <EditProductDiscountModal
                    onClose={() => setShowEditModal(false)}
                    discountPolicy={discountPolicy}
                    onChange={onChange}
                />
            )}
            {showDeleteModal && (
                <LogisticsModal
                    title={getMessage('text_delete_discount')}
                    svgIcon="delete"
                    onConfirm={async () => {
                        setShowDeleteModal(false);
                        await handleDelete();
                    }}
                    onCancel={() => setShowDeleteModal(false)}
                >
                    {getMessage(
                        'text_delete_discount_warning',
                        discount,
                        discountPolicy.product?.name
                    )}
                </LogisticsModal>
            )}
        </td>
    );
}
