export const ApiURL = {
    LOGISTICS_SETTINGS: '/v2/product/logisticsSettings',
    PRODUCT_EXTERNAL_QUERY: '/v2/product/external/query',
    PRODUCT_GET_BY_ID: '/v2/product/getProductsByIds',
    PRODUCT_SUPPLIER_GET_BY_ID:
        '/v2/product/supplierProduct/getSupplierProductsByIds',
    PRODUCT_GROUP_RELATIONS: '/v2/product/groupRelation',
    PRODUCT_RESALE: '/v2/product',
    PRODUCT_SUPPLIER: '/v2/product/supplierProduct',
    PRODUCT_QUERY: '/v2/product/query',
    PRODUCT_QUERY_OVERVIEW: '/v2/product/queryProductOverview',
    SUPPLIER: '/v2/supplier',
};

export const DropdownURL = {
    CURRENCY: '/v2/currency/query',
    PRODUCT_GROUP: '/v2/product/group/query',
    VAT_TYPE: '/v2/ledger/vatType/query?typeOfVat=OUTGOING',
    PRODUCT_QUERY: '/v2/product/query?isSupplierProduct=false&isInactive=false',
};
