import {
    Alert,
    AlertContent,
    AlertDescription,
    AlertHeader,
    ArrowBackIcon,
    ArrowForwardIcon,
    Button,
    KebabIcon,
    PopupMenu,
    PopupMenuItemButton,
    usePopupMenu,
} from '@tlx/atlas';
import newProductOverviewAtlasLogistics from '@Page/LogisticsV2/pages/Product/overview/TourMe/GOLDSHARK_AtlasProductOverviewLogistics_TOUR.json';
import newProductOverviewAtlas from '@Page/LogisticsV2/pages/Product/overview/TourMe/GOLDSHARK_AtlasProductOverview_TOUR.json';
import { runTour } from '../../../../../../../js/modules/tourme';
import { Tour } from '@tlx/tourme-core';
import ProductOverviewNewFeaturesLogistics from '@Page/LogisticsV2/pages/Product/overview/TourMe/GOLDSHARK_ProductOverview_NewFeatures_Logistics_TOUR.json';
import ProductOverviewNewFeaturesNonLogistics from '@Page/LogisticsV2/pages/Product/overview/TourMe/GOLDSHARK_ProductOverview_NewFeatures_NonLogistics_TOUR.json';
import * as React from 'react';

export function ProductOverviewHeader({
    isBannerClosed,
}: {
    isBannerClosed: boolean;
}) {
    const { openerButtonProps, popupMenuProps } = usePopupMenu();
    const supplierId =
        document.querySelector<HTMLInputElement>(
            '#customerIdForProductOverview'
        )?.value ?? null;

    return (
        <>
            {supplierId === null && (
                <div
                    className={
                        'atl-flex atl-justify-between gs-header atl-items-center'
                    }
                >
                    <div
                        id="menuHeader"
                        data-testid="header-title"
                        className="useAtlasHeader"
                    >
                        <h1 className="clip atl-mb-4">
                            {getMessage('heading_product_overview')}
                        </h1>
                    </div>
                    {!moduleRackbeat && (
                        <div className="atl-flex atl-items-center atl-gap-8 gs-product-overview-buttons">
                            <a
                                href={addContextId(
                                    '/execute/productMenu?productId=0'
                                )}
                                className={'atl-h-full'}
                            >
                                <Button
                                    data-testid="new-product-button"
                                    type="button"
                                    className={'atl-mb-4'}
                                >
                                    {getMessage('text_new_product')}
                                </Button>
                            </a>
                            {isBannerClosed && (
                                <>
                                    <Button
                                        data-testid="product-overview-top-actions-button"
                                        variant="icon"
                                        {...openerButtonProps}
                                    >
                                        <KebabIcon />
                                    </Button>
                                    <PopupMenu {...popupMenuProps}>
                                        {isBannerClosed && (
                                            <>
                                                <PopupMenuItemButton
                                                    data-testid={
                                                        'product-overview-tutorial'
                                                    }
                                                    onClick={() => {
                                                        const tour =
                                                            moduleLogistics
                                                                ? newProductOverviewAtlasLogistics
                                                                : newProductOverviewAtlas;
                                                        runTour(tour as Tour);
                                                    }}
                                                >
                                                    {getMessage(
                                                        'automation_starting_guide'
                                                    )}
                                                </PopupMenuItemButton>
                                                <PopupMenuItemButton
                                                    data-testid="product-overview-banner-what-is-new-button"
                                                    onClick={() =>
                                                        moduleLogistics
                                                            ? runTour(
                                                                  ProductOverviewNewFeaturesLogistics as Tour
                                                              )
                                                            : runTour(
                                                                  ProductOverviewNewFeaturesNonLogistics as Tour
                                                              )
                                                    }
                                                >
                                                    {getMessage(
                                                        'text_what_is_new'
                                                    )}
                                                </PopupMenuItemButton>
                                            </>
                                        )}
                                    </PopupMenu>
                                </>
                            )}
                        </div>
                    )}
                </div>
            )}
        </>
    );
}

export function ChangePriceModalActions({
    isDisable,
    showPriceWarning,
    onBack,
    onCancel,
    onNext,
}: {
    isDisable: boolean;
    showPriceWarning: boolean;
    onBack: () => void;
    onCancel: () => void;
    onNext: () => void;
}) {
    return (
        <div className="atl-flex atl-gap-8 atl-justify-between atl-mt-24 atl-mx-32">
            <div>
                {showPriceWarning && (
                    <Button
                        variant="secondary"
                        data-testid="product-overview-bulk-change-price-back-button"
                        onClick={onBack}
                    >
                        <ArrowBackIcon />
                        {getMessage('button_back')}{' '}
                    </Button>
                )}
            </div>
            <div className="atl-flex atl-gap-8">
                <Button
                    variant="tertiary"
                    data-testid="product-overview-bulk-change-price-cancel-button"
                    onClick={onCancel}
                >
                    {getMessage('button_cancel')}{' '}
                </Button>
                {!showPriceWarning && (
                    <Button
                        variant="primary"
                        data-testid="product-overview-bulk-change-price-next-button"
                        onClick={onNext}
                        disabled={isDisable}
                    >
                        {getMessage('text_next')}
                        <ArrowForwardIcon />
                    </Button>
                )}
                {showPriceWarning && (
                    <Button
                        variant="primary"
                        data-testid="product-overview-bulk-change-price-confirm-button"
                        type="submit"
                        form="change-price-form"
                    >
                        {getMessage('text_confirm')}
                    </Button>
                )}
            </div>
        </div>
    );
}

export function ModalBatchActionButtons({
    formId,
    isDisabled,
    showButton,
    onBack,
    onCancel,
    onNext,
}: {
    formId: string;
    isDisabled?: boolean;
    showButton: boolean;
    onBack?: () => void;
    onNext?: () => void;
    onCancel?: () => void;
}) {
    return (
        <div className="atl-flex atl-gap-8 atl-justify-between atl-mt-24 atl-mx-32">
            <div>
                {showButton && (
                    <Button
                        variant="secondary"
                        data-testid="bulk-edit-product-back-button"
                        onClick={onBack}
                    >
                        <ArrowBackIcon />
                        {getMessage('button_back')}
                    </Button>
                )}
            </div>
            <div className="atl-flex atl-gap-8">
                <Button
                    variant="tertiary"
                    data-testid="bulk-edit-product-cancel-button"
                    onClick={onCancel}
                >
                    {getMessage('button_cancel')}
                </Button>
                {!showButton && (
                    <Button
                        variant="primary"
                        data-testid="bulk-edit-product-next-button"
                        onClick={onNext}
                        disabled={isDisabled}
                    >
                        {getMessage('text_next')}
                        <ArrowForwardIcon />
                    </Button>
                )}
                {showButton && (
                    <Button
                        variant="primary"
                        data-testid="bulk-edit-product-confirm-button"
                        type="submit"
                        form={formId}
                    >
                        {getMessage('text_confirm')}
                    </Button>
                )}
            </div>
        </div>
    );
}

export function ModalWarning({ text }: { text: string }) {
    return (
        <Alert variant="warning">
            <AlertContent>
                <AlertHeader as={'h3'}>
                    {getMessage(
                        'text_purchase_order_change_status_warning_title'
                    )}
                </AlertHeader>
                <AlertDescription>{text}</AlertDescription>
            </AlertContent>
        </Alert>
    );
}
