import { ListResponse } from '@tlx/astro-shared';
import {
    ApiErrorModel,
    ValidationMessageModel,
} from '@Page/Goldshark/API/interface';
import { FETCH_PAGINATED_COUNT } from '@Page/Goldshark/API/api';

const CSRF_HEADER_NAME = 'x-tlx-csrf-token';
const CSRF_COOKIE_NAME = 'CSRFTokenWriteOnly';
const CONTEXT_ID_PARAM_NAME = 'contextId';
export type HeadersType = { [name: string]: string };
export function getApiFlattenData<T = unknown>(
    pages: (ListResponse<T> | undefined)[] | undefined
): T[] {
    if (pages === undefined) {
        return [];
    }
    return pages.flatMap((page) => (page !== undefined ? page.values : []));
}

export function getApiHeaders(
    additionalHeaders: HeadersType = {
        'Content-Type': 'application/json; charset=utf-8',
    }
) {
    return new Headers({
        accept: 'application/json; charset=utf-8',
        'x-tlx-context-id': getContextId(),
        [CSRF_HEADER_NAME]: getCSRFToken(),
        ...additionalHeaders,
    });
}
export async function getApiParsedResponse(response: Response) {
    const contentType = response.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
        return await response.json();
    } else if (contentType && contentType.indexOf('text/plain') !== -1) {
        return await response.text();
    } else {
        return response;
    }
}

export function getApiURL(url: string | null, queryParams?: URLSearchParams) {
    if (url == null) {
        return null;
    }
    if (queryParams === undefined || !queryParams.toString()) {
        return url;
    }
    return `${url}?${queryParams}`;
}

export function getProcessedPayload(payload: {
    [p: string]: FormDataEntryValue | null | {} | undefined | string | number;
}) {
    return JSON.stringify(
        Object.fromEntries(Object.entries(payload).filter(([, v]) => v != null))
    );
}
export function getProcessedErrors(
    error: ApiErrorModel
): Record<string, string> {
    const newErrors: Record<string, string> = {};
    error.validationMessages?.forEach(
        (fieldError: ValidationMessageModel) =>
            (newErrors[fieldError.field] = fieldError.message)
    );
    return newErrors;
}

export function getApiURLSearchParams(
    object?: Record<string, string | number | boolean | undefined | null>
) {
    const urlSearchParams = new URLSearchParams();
    if (object) {
        for (const property in object) {
            const value = object[property];
            if (value != null) {
                urlSearchParams.append(property, value.toString());
            }
        }
    }
    return urlSearchParams;
}

export function getCSRFToken() {
    const token = new URLSearchParams(
        document.cookie.replace(/;\s*/g, '&')
    ).get(CSRF_COOKIE_NAME);

    if (token === null) {
        return '';
    }

    return token;
}

export function getContextId() {
    const contextId = new URLSearchParams(window.location.search).get(
        CONTEXT_ID_PARAM_NAME
    );

    if (contextId === null) {
        return '';
    }

    return contextId;
}

export function getHasMoreApiPages(
    pages: ListResponse<any>[] | undefined
): boolean {
    const lastPage = pages?.[pages?.length - 1];
    return lastPage ? lastPage.values.length === FETCH_PAGINATED_COUNT : false;
}
