import {
    ActionsCell,
    Cell,
    CellLabel,
    Chip,
    Column,
    Header,
    MoneyInput,
    NumberCell,
    OpenNewIcon,
    PrimaryCell,
    Row,
    SelectAllColumn,
    SkeletonText,
    TableActionButton,
    TextCell,
} from '@tlx/atlas';
import React, { cloneElement, MutableRefObject, useRef } from 'react';
import { nav } from '../../../../../js/modules/navigation';
import { DivLayout } from '@Page/Goldshark/Components/LayoutComponents';
import { getCurrentLocale } from '../../../../../js/modules/getMessage';
import { TableColumnSetting, TableColumnsSetting } from '@tlx/astro-shared';
import { useTotalLines } from '@Page/Goldshark/Hooks/useTableHooks';
import {
    GeneralEmptyState,
    SearchIcon,
} from '@Page/LogisticsV2/utils/svgIcons';
import { ProductAPIModel } from '@Page/Goldshark/API/interface';
import { headerCellRender, tableColumns } from '@Page/Goldshark/Utils/mapData';
import { IntlProvider } from 'react-intl';
import enMessages from '../../../../../../../app/src/main/resources/ApplicationResources_en_GB.properties';
import noMessages from '../../../../../../../app/src/main/resources/ApplicationResources.properties';

export function TableTopBarColumnsSettings({ table }: { table: string }) {
    const columns = tableColumns[table];
    return (
        <TableColumnsSetting
            defaultColumns={columns.default}
            name={columns.name}
            aria-label={`${table}-columns`}
            label={getMessage('text_columns')}
        >
            {columns.available.map((column) => (
                <TableColumnSetting key={column} value={column}>
                    {getMessage(label[column])}
                </TableColumnSetting>
            ))}
        </TableColumnsSetting>
    );
}
export function TableHeader({ columns }: { columns: string[] }) {
    const locale = getCurrentLocale();

    const messages = {
        en: enMessages,
        no: noMessages,
    };
    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <Header>
                <Row>
                    <SelectAllColumn />
                    {columns.map((column, index) =>
                        cloneElement(headerCellRender[column], {
                            key: `${column}-${index}`,
                        })
                    )}
                    <Column width={'0.26fr'} id={'column-action'} />
                </Row>
            </Header>
        </IntlProvider>
    );
}

type TableEmptyStateProps = {
    icon: string;
    title: string;
    children?: React.ReactNode;
    description?: string;
    style?: React.CSSProperties;
};
export function TableEmptyState(props: TableEmptyStateProps) {
    return (
        <Row>
            <Cell column="1/-1" className={'atl-justify-center'}>
                <DivLayout
                    className="atl-bg-white atl-flex atl-gap-16 atl-items-center"
                    style={props.style}
                >
                    {props.icon === 'search' ? (
                        <SearchIcon />
                    ) : (
                        <GeneralEmptyState />
                    )}
                    <DivLayout className="atl-flex atl-flex-col atl-gap-16">
                        <DivLayout className={'atl-text-lg atl-font-bold'}>
                            {props.title}
                        </DivLayout>
                        {props.description && (
                            <DivLayout className="atl-font-normal atl-text-grey-80">
                                {props.description}
                            </DivLayout>
                        )}
                        <DivLayout>{props.children}</DivLayout>
                    </DivLayout>
                </DivLayout>
            </Cell>
        </Row>
    );
}
export function TableLoadingRow({ columns }: { columns: number }) {
    return (
        <Row>
            {Array.from({ length: columns }, (_, columnIndex) => (
                <Cell key={columnIndex}>
                    <SkeletonText className={'atl-w-full'} />
                </Cell>
            ))}
        </Row>
    );
}
export function TableLoadMoreRow({
    columns,
    loadMoreRef,
}: {
    columns: number;
    loadMoreRef: MutableRefObject<HTMLDivElement | null>;
}) {
    let count = 1;
    return (
        <Row>
            <Cell column="1/-1">
                <div
                    ref={loadMoreRef}
                    className={'atl-w-full atl-flex atl-gap-8'}
                    key={'load-more-row'}
                >
                    {Array.from({ length: columns }, () => {
                        const index = 'loading-skeleton-' + columns + count;
                        count++;
                        return <SkeletonText key={index} width={'190px'} />;
                    })}
                </div>
            </Cell>
        </Row>
    );
}

export function ActionCellOpenProduct({ productId }: { productId: number }) {
    const mobileText = `${
        getMessage('text_open') + ' ' + getMessage('text_product')
    }`;
    return (
        <ActionsCell>
            <TableActionButton
                icon={<OpenNewIcon />}
                aria-label="open product in new tab"
                data-testid={'product-selector-launch-icon'}
                onClick={() =>
                    nav.popup(
                        `/execute/productMenu?productId=${productId}&contextId=${window.contextId}`,
                        '_blank'
                    )
                }
            >
                {mobileText}
            </TableActionButton>
        </ActionsCell>
    );
}
export function NumberCellAdvanced({
    number,
    resaleNumber,
}: {
    number: string | undefined;
    resaleNumber: string | undefined;
}) {
    return (
        <TextCell className={'atl-flex-col atl-justify-center atl-items-start'}>
            <DivLayout>{number && number !== '' ? number : '-'}</DivLayout>
            <DivLayout className={'atl-text-grey-60'}>
                {resaleNumber && resaleNumber !== '' ? resaleNumber : '-'}
            </DivLayout>
        </TextCell>
    );
}
export function NameCellAdvanced({
    name,
    resaleName,
}: {
    name: string | undefined;
    resaleName: string | undefined;
}) {
    return (
        <PrimaryCell>
            <DivLayout className={'atl-flex-col'}>
                <DivLayout>{name && name !== '' ? name : '-'}</DivLayout>
                <DivLayout className={'atl-text-grey-60'}>
                    {resaleName && resaleName !== '' ? resaleName : '-'}
                </DivLayout>
            </DivLayout>
        </PrimaryCell>
    );
}

export function CountCell({
    value,
    className,
    onChange,
}: {
    value: string;
    className?: string;
    onChange?: (count: string) => void;
}) {
    const locale = getCurrentLocale();
    const internalValue = useRef<HTMLInputElement>(null);
    return (
        <Cell align={'right'}>
            <CellLabel>{getMessage('text_count')}</CellLabel>
            <div
                onChange={() =>
                    onChange?.(internalValue?.current?.value ?? '0')
                }
            >
                <MoneyInput
                    id="count-input-row"
                    locale={locale}
                    value={value}
                    data-testid={'count-input-row'}
                    className={className ?? ''}
                    ref={internalValue}
                />
            </div>
        </Cell>
    );
}
export function StockOfGoodsCell({
    stockOfGoods,
    isStockItem,
}: {
    stockOfGoods: string;
    isStockItem: boolean | undefined;
}) {
    const locale = getCurrentLocale();
    return isStockItem ? (
        <Cell align={'right'}>
            <CellLabel>{getMessage('text_stock_of_goods')}</CellLabel>
            <NumberCell locale={locale}>{Number(stockOfGoods)}</NumberCell>
        </Cell>
    ) : (
        <Cell align={'right'}>
            <CellLabel>{getMessage('text_stock_of_goods')}</CellLabel>
            <TextCell align={'right'}>-</TextCell>
        </Cell>
    );
}

type SelectedRowsStatusProps = {
    url: string | null;
    queryParams: {};
    selectedRows: number;
    onChangeSelectedRows: (values: string[]) => void;
    className?: string;
};
export function SelectedRowsStatus(props: SelectedRowsStatusProps) {
    const { data } = useTotalLines<ProductAPIModel>(
        props.url,
        props.queryParams
    );
    return (
        <DivLayout className={`atl-flex atl-gap-8 ${props.className ?? ''}`}>
            {props.selectedRows > 0 && (
                <>
                    <Chip
                        className={'atl-pl-12'}
                        onRemove={() => props.onChangeSelectedRows([])}
                    >
                        {props.selectedRows} {getMessage('text_selected')}
                    </Chip>
                    {data.length > 0 && props.selectedRows < data.length && (
                        <DivLayout
                            className="atl-text-base atl-text-blue-100 atl-underline atl-flex atl-items-center"
                            onClick={() =>
                                props.onChangeSelectedRows(
                                    data.map((d) => d.id.toString())
                                )
                            }
                            style={{
                                cursor: 'pointer',
                                height: '32px',
                            }}
                        >
                            {getMessage('text_select_all')} {data.length}
                        </DivLayout>
                    )}
                </>
            )}
        </DivLayout>
    );
}

const label: { [key: string]: string } = {
    cost: 'text_price_purchase',
    costExternal: 'text_price_purchase',
    count: 'text_count',
    name: 'text_name',
    nameSortable: 'text_name',
    nameSortableDetailed: 'text_name',
    number: 'text_number',
    numberSortable: 'text_number',
    numberSortableDetailed: 'text_number',
    numberSortableExternal: 'text_number',
    stockOfGoods: 'text_stock_of_goods',
};
