import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';

type SetValue<T> = Dispatch<SetStateAction<T>>;
function useSessionStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
    const readValue = useCallback((): T => {
        if (typeof window === 'undefined') {
            return initialValue;
        }
        try {
            const item = window.sessionStorage.getItem(key);
            return item ? (JSON.parse(item) as T) : initialValue;
        } catch (error) {
            throw new Error(
                `Error reading sessionStorage key “${key}”: ${error}`
            );
        }
    }, [initialValue, key]);
    const [storedValue, setStoredValue] = useState<T>(readValue);
    const setValue: SetValue<T> = (value) => {
        if (typeof window == 'undefined') {
            console.warn(
                `Tried setting sessionStorage key “${key}” even though environment is not a client`
            );
        }
        try {
            const newValue =
                value instanceof Function ? value(storedValue) : value;
            if (
                typeof newValue === 'object' &&
                newValue !== null &&
                !Array.isArray(newValue)
            ) {
                console.warn(
                    `The "initialValue" must be serializable to JSON.`
                );
            }
            window.sessionStorage.setItem(key, JSON.stringify(newValue));
            setStoredValue(newValue);
            window.dispatchEvent(new Event('session-storage'));
        } catch (error) {
            throw new Error(
                `Error setting sessionStorage key “${key}”: ${error}`
            );
        }
    };
    useEffect(() => {
        setStoredValue(readValue());
        const handleStorageChange = (event: StorageEvent | CustomEvent) => {
            if (
                (event as StorageEvent)?.key &&
                (event as StorageEvent).key !== key
            ) {
                return;
            }
            setStoredValue(readValue());
        };
        window.addEventListener('storage', handleStorageChange);
        window.addEventListener('session-storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('session-storage', handleStorageChange);
        };
    }, [key, readValue]);

    return [storedValue, setValue];
}

export default useSessionStorage;
