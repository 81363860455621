import {
    AsyncDropdownOptions,
    DropdownFilter,
    Filters,
    SearchFilter,
} from '@tlx/astro-shared';
import React from 'react';
import { ApiUrl } from '@Page/LogisticsV2/utils/enums';
import { Option } from '@tlx/atlas';
import {
    ALL_LOCATIONS,
    ALL_PRODUCT_GROUPS,
    EMPTY_LOCATION,
    PRODUCTS_WITHOUT_PRODUCT_GROUPS,
} from '@Page/LogisticsV2/pages/Stocktaking/utils';
import { useExclusiveOption } from '@Page/LogisticsV2/hooks/useExclusiveOption';

export function StocktakingDetailsTableFilters({
    warehouseId,
}: {
    warehouseId: number;
}) {
    return (
        <form autoComplete={'off'} className={'atl-flex atl-gap-16'}>
            <SearchFilter
                id={'stocktaking-details-search'}
                data-testid={'stocktaking-details-search'}
                label={getMessage('text_search')}
                name={'queryProducts'}
                placeholder={getMessage('text_search')}
                quick
            />
            {moduleLogistics && (
                <DropdownFilter
                    className={'gs-product-group-filter'}
                    name={'productGroups'}
                    label={getMessage('text_product_group')}
                    defaultDisplayName={getMessage('text_product_group_all')}
                    quick
                    multiple
                >
                    <AsyncDropdownOptions url={ApiUrl.PRODUCT_GROUP_QUERY}>
                        <AllProductGroupsOption />
                        <Option
                            value={PRODUCTS_WITHOUT_PRODUCT_GROUPS}
                            data-testid="stocktaking-product-without-group"
                        >
                            {getMessage('text_product_without_group')}
                        </Option>
                    </AsyncDropdownOptions>
                </DropdownFilter>
            )}
            {isLocationEnabled && (
                <DropdownFilter
                    name={'location'}
                    label={getMessage('location')}
                    defaultDisplayName={getMessage('text_all_locations')}
                    quick
                    multiple
                >
                    <AsyncDropdownOptions
                        url={`${ApiUrl.INVENTORY_LOCATION_INTERNAL_SEARCH}?warehouseId=${warehouseId}`}
                    >
                        <AllLocationsOption />
                        <Option
                            value={EMPTY_LOCATION}
                            data-testid="stocktaking-empty-location"
                        >
                            {getMessage('text_empty_location')}
                        </Option>
                    </AsyncDropdownOptions>
                </DropdownFilter>
            )}
        </form>
    );
}

export function StocktakingDetailsMobileTableFilters({
    warehouseId,
}: {
    warehouseId: number;
}) {
    return (
        <Filters>
            <SearchFilter
                data-testid={'stocktaking-details-search'}
                label={getMessage('text_search')}
                name={'queryProducts'}
                placeholder={getMessage('text_search')}
                quick
            />
            {moduleLogistics && (
                <DropdownFilter
                    name={'productGroups'}
                    label={getMessage('text_product_group')}
                    defaultDisplayName={getMessage('text_product_group_all')}
                    quick
                    multiple
                >
                    <AsyncDropdownOptions url={ApiUrl.PRODUCT_GROUP_QUERY}>
                        <AllProductGroupsOption />
                        <Option
                            value={PRODUCTS_WITHOUT_PRODUCT_GROUPS}
                            data-testid="stocktaking-product-without-group"
                        >
                            {getMessage('text_product_without_group')}
                        </Option>
                    </AsyncDropdownOptions>
                </DropdownFilter>
            )}
            {isLocationEnabled && (
                <DropdownFilter
                    name={'location'}
                    label={getMessage('location')}
                    defaultDisplayName={getMessage('text_all_locations')}
                    quick
                    multiple
                >
                    <AsyncDropdownOptions
                        url={`${ApiUrl.INVENTORY_LOCATION_INTERNAL_SEARCH}?warehouseId=${warehouseId}`}
                    >
                        <AllLocationsOption />
                        <Option
                            value={EMPTY_LOCATION}
                            data-testid="stocktaking-empty-location"
                        >
                            {getMessage('text_empty_location')}
                        </Option>
                    </AsyncDropdownOptions>
                </DropdownFilter>
            )}
        </Filters>
    );
}

function AllLocationsOption() {
    useExclusiveOption(ALL_LOCATIONS);
    return (
        <>
            <Option
                value={ALL_LOCATIONS}
                data-testid="stocktaking-all-product-locations"
            >
                {getMessage('text_all_locations')}
            </Option>
        </>
    );
}

function AllProductGroupsOption() {
    useExclusiveOption(ALL_PRODUCT_GROUPS);
    return (
        <>
            <Option
                value={ALL_PRODUCT_GROUPS}
                data-testid="stocktaking-all-product-group"
            >
                {getMessage('text_product_group_all')}
            </Option>
        </>
    );
}
