import { ModelAPIV1 } from '@General/types/Api';
import { Option } from '../../types';
import { notUndefined } from '@General/Helpers';
import { JSPDropdownFetcher } from '../JSPDropdownFetcher';
import { ApiResultMapper } from '@General/Provider';

export interface TaxTransactionTypeEntry extends ModelAPIV1 {
    readonly displayName: string;
}

const TaxTransactionTypeMapper: ApiResultMapper<TaxTransactionTypeEntry> = (
    item
) => ({
    value: item.id + '',
    displayName: item.displayName,
    item,
});

export class TaxTransactionTypeFetcher extends JSPDropdownFetcher<TaxTransactionTypeEntry> {
    asyncJsonrpcGetter: Function;

    constructor(
        scope: HTMLDivElement,
        queryAttributes: Record<string, any>,
        mapper: ApiResultMapper<any>
    ) {
        super(scope, queryAttributes, mapper ?? TaxTransactionTypeMapper);
        this.asyncJsonrpcGetter = asyncrpc.Account.getTaxTransactionType;
    }

    getParams(): ObjectIndex {
        const dateQueryAttributeAsDefault = this.getQueryAttribute('date', '');
        return {
            accountId: this.getQueryAttribute('accountElementId', -1),
            dateStr: this.getQueryAttribute(
                'dateElementId',
                dateQueryAttributeAsDefault
            ),
        };
    }

    async fetchList(
        params: ObjectIndex
    ): Promise<Option<TaxTransactionTypeEntry>[]> {
        return new Promise((resolve, reject) => {
            try {
                return jsonrpc.Account.searchTaxTransactionType(
                    (result: TaxTransactionTypeEntry[] | null) => {
                        const mapped = (result ?? [])
                            .filter(notUndefined)
                            .map(this.getMapper(params))
                            .filter(notUndefined);
                        resolve(mapped);
                    },
                    this.getMarshallSpec(),
                    window.loginCompanyId,
                    params.accountId,
                    params.name || '',
                    params.dateStr
                );
            } catch (e) {
                defaultJsonRpcExceptionHandler(e);
                reject(e);
            }
        });
    }

    /**
     * In all other models, id must be greater than 0, but not for this one
     */
    validateId(id: string | number): boolean {
        return parseInt(id, 10) >= 0;
    }
}

export const exports = {
    fetcher: TaxTransactionTypeFetcher,
    type: 'TaxTransactionType',
};
