import {
    TravelsAndExpensesRejectedCommentDialog,
    TravelsAndExpensesRejectedCommentProps,
} from '@Page/TravelsAndExpenses/component/TravelsAndExpensesRejectCommentDialog';
import { renderComponent } from '@General/renderComponent';

export function renderRejectCommentDialog(
    domId: string,
    props: TravelsAndExpensesRejectedCommentProps
) {
    renderComponent<TravelsAndExpensesRejectedCommentProps>(
        TravelsAndExpensesRejectedCommentDialog,
        domId,
        props
    );
}
