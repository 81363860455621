export const ALL_PRODUCT_GROUPS = '-1';
export const FORM = {
    PRODUCT_SELECTOR_CREATE_PRODUCT: 'product-selector-create-product-form',
};
export const SortingType = {
    NAME_ASCENDING: 'name',
    NAME_DESCENDING: '-name',
    NO_SORTING: '',
    NUMBER_ASCENDING: 'number',
    NUMBER_DESCENDING: '-number',
} as const;
export const TableColumns = {
    RESALE_PRODUCT_TABLE: 'resaleProductTable',
    SUPPLIER_CONNECTION_TABLE: 'supplierConnectionTable',
    SUPPLIER_CONNECTION_TABLE_DETAILED: 'supplierConnectionTableDetailed',
    SUPPLIER_CONNECTION_TABLE_EXTERNAL: 'supplierConnectionTableExternal',
} as const;
export const DEFAULT_SUPPLIER_FIELDS =
    'id,name,displayName,organizationNumber,isWholesaler';
export const DefaultColumns = {
    RESALE_PRODUCT: ['numberSortable', 'nameSortable', 'count'],
    SUPPLIER_CONNECTION: [
        'numberSortable',
        'nameSortable',
        'count',
        'stockOfGoods',
        'cost',
    ],
    SUPPLIER_CONNECTION_DETAILED: [
        'numberSortableDetailed',
        'nameSortableDetailed',
        'count',
        'stockOfGoods',
        'cost',
    ],
    SUPPLIER_CONNECTION_EXTERNAL: [
        'numberSortableExternal',
        'nameSortable',
        'count',
        'costExternal',
    ],
};

export const AvailableColumns = {
    RESALE_PRODUCT: ['numberSortable', 'nameSortable', 'count'],
    SUPPLIER_CONNECTION: [
        'numberSortable',
        'nameSortable',
        'count',
        'stockOfGoods',
        'cost',
    ],
    SUPPLIER_CONNECTION_DETAILED: [
        'numberSortableDetailed',
        'nameSortableDetailed',
        'count',
        'stockOfGoods',
        'cost',
    ],
    SUPPLIER_CONNECTION_EXTERNAL: [
        'numberSortableExternal',
        'nameSortable',
        'count',
        'costExternal',
    ],
};
