import React, { FC } from 'react';
import { getLocale, formatNumber } from '@tlx/intl';

type NumberFormatProps = {
    value: number;
    options: Intl.NumberFormatOptions;
};
export const NumberFormat: FC<NumberFormatProps> = ({ value, options }) => (
    <>{formatNumber(value, getLocale(), options)}</>
);
